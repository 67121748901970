import React from 'react';
import { observer } from 'mobx-react-lite';
import { Switch } from 'react-router';
import { PrivateRoute } from 'components/PrivateRoute';
import { dashboardRoutes } from 'routing';
import { GameDashboardPage } from './GameDashboardPage';
import { ShopDashboardPage } from './ShopDashboardPage';
import { useAppStore } from 'store/app-store.hook';
import { ItemType } from 'types/user-types';

export const DashboardRoutes: React.FC = observer(() => {
  const { selectedItem } = useAppStore().uiStore;
  return (
    <Switch>
      {selectedItem.itemType === ItemType.Game ? (
        <PrivateRoute exact path={dashboardRoutes.HOME} component={GameDashboardPage} />
      ) : (
        <PrivateRoute exact path={dashboardRoutes.HOME} component={ShopDashboardPage} />
      )}
    </Switch>
  );
});
