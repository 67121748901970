import { PropsWithChildren } from 'react';
import { Table } from 'reactstrap';
import { TableInstance } from 'react-table';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Box } from '@mui/material';

interface Props<T extends Object> {
  table: TableInstance<T>;
}

export const ScutiTable = <T,>({ table }: PropsWithChildren<Props<T>>) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = table;
  return (
    <Table responsive striped hover {...getTableProps()}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => {
              return (
                <th
                  {...column.getHeaderProps(
                    column.getSortByToggleProps({
                      style: {
                        width: column.width,
                      },
                    }),
                  )}
                >
                  <Box display="flex" alignItems="center" style={{ opacity: column.disableSortBy ? 0.6 : 1 }}>
                    {column.render('Header')}
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <ArrowDropUpIcon style={{ width: 18, height: 18 }} />
                      ) : (
                        <ArrowDropDownIcon style={{ width: 18, height: 18 }} />
                      )
                    ) : (
                      ''
                    )}
                  </Box>
                </th>
              );
            })}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map(row => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => (
                <td
                  {...cell.getCellProps({
                    style: {
                      width: cell.column.width,
                    },
                  })}
                >
                  {cell.render('Cell')}
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};
