import * as Types from '../../../../../types/__generated__/types';

import { ApolloError } from 'apollo-client';
import { useQuery, UseQueryOptions, useMutation, UseMutationOptions } from 'react-query';
import { fetcher } from 'api';
export type ListingOptionsQueryVariables = Types.Exact<{
  filters?: Types.Maybe<Array<Types.FilterInput> | Types.FilterInput>;
  paging?: Types.Maybe<Types.PagingInput>;
  id: Types.Scalars['ID'];
}>;

export type ListingOptionsQuery = {
  shopCampaigns: {
    nodes: Array<{
      id: string;
      name: string;
      type: Types.CampaignType;
      product?: Types.Maybe<{ product: { id: string; name: string } }>;
    }>;
  };
};

export type TodayDealsQueryVariables = Types.Exact<{
  shopId: Types.Scalars['String'];
}>;

export type TodayDealsQuery = {
  todayDeals: Array<{ id: string; name: string; product?: Types.Maybe<{ product: { id: string; name: string } }> }>;
};

export type SetTodayDealsMutationVariables = Types.Exact<{
  ids: Array<Types.Scalars['String']> | Types.Scalars['String'];
  shopId: Types.Scalars['String'];
}>;

export type SetTodayDealsMutation = { setTodayDeals: Array<{ id: string }> };

export const ListingOptionsDocument = `
    query ListingOptions($filters: [FilterInput!], $paging: PagingInput, $id: ID!) {
  shopCampaigns(filters: $filters, paging: $paging, id: $id) {
    nodes {
      id
      name
      product {
        product {
          id
          name
        }
      }
      type
    }
  }
}
    `;
export const useListingOptionsQuery = <TData = ListingOptionsQuery, TError = ApolloError>(
  variables: ListingOptionsQueryVariables,
  options?: UseQueryOptions<ListingOptionsQuery, TError, TData>,
) =>
  useQuery<ListingOptionsQuery, TError, TData>(
    ['ListingOptions', variables],
    fetcher<ListingOptionsQuery, ListingOptionsQueryVariables>(ListingOptionsDocument, variables),
    options,
  );
export const TodayDealsDocument = `
    query TodayDeals($shopId: String!) {
  todayDeals(shopId: $shopId) {
    id
    name
    product {
      product {
        id
        name
      }
    }
  }
}
    `;
export const useTodayDealsQuery = <TData = TodayDealsQuery, TError = ApolloError>(
  variables: TodayDealsQueryVariables,
  options?: UseQueryOptions<TodayDealsQuery, TError, TData>,
) =>
  useQuery<TodayDealsQuery, TError, TData>(
    ['TodayDeals', variables],
    fetcher<TodayDealsQuery, TodayDealsQueryVariables>(TodayDealsDocument, variables),
    options,
  );
export const SetTodayDealsDocument = `
    mutation SetTodayDeals($ids: [String!]!, $shopId: String!) {
  setTodayDeals(ids: $ids, shopId: $shopId) {
    id
  }
}
    `;
export const useSetTodayDealsMutation = <TError = ApolloError, TContext = unknown>(
  options?: UseMutationOptions<SetTodayDealsMutation, TError, SetTodayDealsMutationVariables, TContext>,
) =>
  useMutation<SetTodayDealsMutation, TError, SetTodayDealsMutationVariables, TContext>(
    (variables?: SetTodayDealsMutationVariables) =>
      fetcher<SetTodayDealsMutation, SetTodayDealsMutationVariables>(SetTodayDealsDocument, variables)(),
    options,
  );
