import * as Yup from 'yup';
import { FormValidationSchema } from 'utils/form-utils';
import { DeepPartialBy } from 'types/util-types';
import { ShopFullFragment } from '../__generated__/shop-edit-page.hooks';
import { Role } from 'types/__generated__/types';

export type ShopForm = DeepPartialBy<ShopFullFragment, 'id'>;

export const shopFormDefaults = (): ShopForm => ({
  name: '',
  description: undefined,
  shopifyName: undefined,
  flatShippingRate: 0,
  freeShippingThreshold: undefined,
  returnPolicy: '',
  admins: [],
});

export const validationSchema = (): FormValidationSchema<ShopForm> =>
  Yup.object().shape({
    name: Yup.string()
      .min(2, 'Description is too short. Min size is 2 characters.')
      .max(100, 'Description is too long. Max size is 100 characters.'),
    description: Yup.string()
      .min(12, 'Description is too short. Min size is 12 characters.')
      .max(10000, 'Description is too long. Max size is 10000 characters.')
      .nullable(),
    shopifyName: Yup.string()
      .max(2, 'Shopify name is too short. Min size is 2 characters.')
      .max(30, 'Shopify name is too long. Max size is 30 characters.')
      .nullable(),
    flatShippingRate: Yup.number()
      .transform(value => (isNaN(value) ? undefined : value))
      .min(0, 'Flat Rate can not be negative')
      .required('Flat Rate is required'),
    freeShippingThreshold: Yup.number()
      .transform(value => (isNaN(value) ? undefined : value))
      .min(0, 'Threshold can not be negative')
      .when('flatShippingRate', {
        is: val => val > 0,
        then: Yup.number()
          .transform(value => (isNaN(value) ? undefined : value))
          .min(0, 'Threshold can not be negative')
          .required('Threshold is required'),
      })
      .nullable(),
    returnPolicy: Yup.string().required(),
    admins: Yup.array().of(
      Yup.object({
        email: Yup.string().email(),
        roles: Yup.array().of(Yup.mixed().oneOf(Object.values(Role))),
      }),
    ),
  });
