import React from 'react';
import { FormGroup } from 'reactstrap';
import TagsInput from 'react-tagsinput';
import { observer } from 'mobx-react-lite';
import { GAME_CATEGORIES_SELECT_OPTIONS } from 'types/select-types';
import { Box, Card, CardContent, CardHeader, Typography } from '@material-ui/core';
import { ScutiInput } from 'components/material/ScutiInput';
import { ScutiSelect } from 'components/material/ScutiSelect';
import { FormikProps } from 'formik';
import { GameForm } from './GameEditForm.utils';
import { ScutiImgInput } from 'components/material/ScutiImgInput';
import ImageIcon from '@mui/icons-material/Image';

export const GameEditDetails: React.FC<FormikProps<GameForm>> = observer(props => {
  const { values, touched, errors, handleChange, handleBlur, setFieldValue } = props;

  return (
    <Card>
      <CardHeader title="Details" />
      <CardContent>
        <Box>
          <Box display="flex" justifyContent="between">
            <Box flex="1 0 auto">
              <label>Game Thumbnail</label>
              <Box color="text.secondary">
                <Typography variant="body2">The thumbnail image for your game.</Typography>
                <Typography variant="body2">Upload a 100 x 100px or 1:1 aspect ratio image.</Typography>
              </Box>
            </Box>
            <Box ml={1} flex="0 0 auto" width="4rem" height="4rem">
              <ScutiImgInput
                image={values.thumbnail}
                onChange={file => setFieldValue('thumbnail', file, false)}
                error={!!errors.thumbnailFile}
              >
                <ImageIcon color="secondary" style={{ width: '100%', height: '100%' }} />
              </ScutiImgInput>
            </Box>
          </Box>
          <Box textAlign="right">
            <Typography color="error" variant="caption">
              {errors.thumbnail}
            </Typography>
          </Box>
        </Box>
        <Box mt={2}>
          <ScutiInput
            title="Name *"
            type="text"
            name={`name`}
            placeholder="What is the name of the game"
            value={values.name}
            error={touched.name && errors[`name`]}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Box>
        <Box mt={2} mb={2}>
          <ScutiSelect
            title="Category *"
            type="text"
            name={`category`}
            placeholder="Select category"
            value={values.category}
            error={touched.name && errors[`category`]}
            options={GAME_CATEGORIES_SELECT_OPTIONS}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Box>
        <FormGroup>
          <Box className="tags-control">
            <label>Attributes</label>
            <TagsInput
              value={values.tags || []}
              addKeys={[',', ' ']}
              onChange={React.useCallback((tags: string[]) => setFieldValue('tags', tags, true), [setFieldValue])}
            />
          </Box>
        </FormGroup>
        <FormGroup>
          <Box width="100%" height="4rem">
            <ScutiImgInput
              image={values.banner}
              error={!!errors.banner}
              onChange={file => setFieldValue('banner', file, true)}
            >
              <Box
                height="100%"
                pl="2rem"
                pr="2rem"
                display="flex"
                flexDirection="column"
                color="text.secondary"
                justifyContent="center"
                alignItems="center"
              >
                <Typography variant="body2">
                  Upload 728x90px or aspect ratio of 8.09:1 image for your free banner promotion.
                </Typography>
              </Box>
            </ScutiImgInput>
            <Typography color="error" variant="caption">
              {errors.banner}
            </Typography>
          </Box>
        </FormGroup>
      </CardContent>
    </Card>
  );
});
