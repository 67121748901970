import React from 'react';
import { FormGroup, CustomInput } from 'reactstrap';
import { COUNTRIES_SELECT_OPTIONS, GENDER_SELECT_OPTIONS, USA_STATES_SELECT_OPTIONS } from 'types/select-types';
import InputRange, { Range } from 'react-input-range';
import { FormikProps } from 'formik';
import { isPaused, ProductListingForm } from './ProductListingEditForm.utils';
import { Box, Card, CardContent, CardHeader } from '@material-ui/core';
import { ScutiSelect } from 'components/material/ScutiSelect';
import { ScutiMultipleSelect } from 'components/material/ScutiMultipleSelect';

export const AudienceDetails: React.FC<FormikProps<ProductListingForm>> = props => {
  const { values, touched, errors } = props;
  const [genderSpecific, setGenderSpecific] = React.useState(!!values.demographic.gender);
  const [countrySpecific, setCountrySpecific] = React.useState(!!values.location?.country);
  const [stateSpecific, setStateSpecific] = React.useState(!!values.location?.states);
  const [ageRangeSpecific, setAgeRangeSpecific] = React.useState(
    values.demographic.minAge !== 0 || values.demographic.maxAge !== 100,
  );
  const toggleGender = () => {
    if (!genderSpecific) props.setFieldValue('demographic.gender', GENDER_SELECT_OPTIONS[1].value, true);
    else props.setFieldValue('demographic.gender', null, true);
    setGenderSpecific(!genderSpecific);
  };

  const toggleCountry = () => {
    if (countrySpecific) props.setFieldValue('location', {}, true);
    setCountrySpecific(!countrySpecific);
  };

  const toggleState = () => {
    if (stateSpecific) props.setFieldValue('location.states', null, true);
    setStateSpecific(!stateSpecific);
  };

  const toggleAgeRange = () => {
    props.setFieldValue(`demographic`, { ...values.demographic, minAge: 0, maxAge: 100 }, true);
    setAgeRangeSpecific(!ageRangeSpecific);
  };

  const onRangeChange = (v: Range | number) => {
    if (typeof v === 'number') return;
    props.setFieldValue(
      `demographic`,
      { ...values.demographic, minAge: Math.max(0, v.min), maxAge: Math.min(v.max, 100) },
      true,
    );
  };

  const isUS = values.location?.country === 'US';

  return (
    <Card>
      <CardHeader title="Audience" />
      <CardContent>
        <FormGroup>
          <label htmlFor={`demographic.gender`}>
            <CustomInput
              id="gender-box"
              type="checkbox"
              label="Gender specific"
              onChange={toggleGender}
              checked={genderSpecific}
              disabled={!isPaused(values)}
            />
          </label>
          <ScutiSelect
            placeholder="Select gender"
            name="demographic.gender"
            value={values.demographic.gender || ''}
            options={GENDER_SELECT_OPTIONS.slice(1)}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            disabled={!genderSpecific || !isPaused(values)}
          />
        </FormGroup>
        <FormGroup>
          <label htmlFor={`demographic.gender`}>
            <CustomInput
              id="age-rage-box"
              type="checkbox"
              label="Age Range"
              onChange={toggleAgeRange}
              checked={ageRangeSpecific}
              disabled={!isPaused(values)}
            />
          </label>
          <Box pl={2} pr={2}>
            <InputRange
              maxValue={100}
              minValue={0}
              value={{ min: values.demographic.minAge, max: values.demographic.maxAge }}
              onChange={onRangeChange}
              disabled={!ageRangeSpecific || !isPaused(values)}
            />
          </Box>
        </FormGroup>
        <Box mt={2}>
          <FormGroup>
            <label htmlFor={`location.country`}>
              <CustomInput
                id="country-box"
                type="checkbox"
                label="Country specific"
                onChange={toggleCountry}
                checked={countrySpecific}
                disabled={!isPaused(values)}
              />
            </label>
            <ScutiSelect
              name="location.country"
              placeholder="Country"
              value={values?.location?.country || ''}
              options={COUNTRIES_SELECT_OPTIONS}
              onChange={e => {
                props.setFieldValue('location.country', e.target.value, true);
                props.setFieldValue('location.state', null, true);
              }}
              onBlur={props.handleBlur}
              // @ts-ignore
              error={touched?.location?.country && errors['location.country']}
              disabled={!countrySpecific || !isPaused(values)}
            />
          </FormGroup>
        </Box>
        <Box mt={2}>
          <FormGroup>
            <label htmlFor={`demographic.gender`}>
              <CustomInput
                id="state-box"
                type="checkbox"
                label="State specific"
                onChange={toggleState}
                checked={stateSpecific}
                disabled={!isUS || !isPaused(values)}
              />
            </label>
            <ScutiMultipleSelect
              name="location.states"
              placeholder="States"
              value={(values?.location?.states || [])[0] || ''}
              values={values?.location?.states || []}
              options={USA_STATES_SELECT_OPTIONS}
              onChange={({ target }) => {
                props.setFieldValue('location.states', [...(values?.location?.states || []), target.value], true);
              }}
              onBlur={props.handleBlur}
              disabled={!isUS || !stateSpecific || !isPaused(values)}
              // @ts-ignore
              error={touched?.location?.states && errors['location.states']}
              onRemove={value => {
                props.setFieldValue(
                  'location.states',
                  [...(values?.location?.states || [])].filter(s => s !== value),
                  true,
                );
              }}
            />
          </FormGroup>
        </Box>
      </CardContent>
    </Card>
  );
};
