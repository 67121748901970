import React from 'react';
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete';
import { Skeleton } from '@mui/material';
import { FormControl, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { scutiColors } from 'material-theme/variables';

const useStyles = makeStyles({
  rootRoot: {
    '&.MuiAutocomplete-root .MuiAutocomplete-inputRoot': {
      padding: 4,
    },
    '&.MuiAutocomplete-root .MuiAutocomplete-inputRoot .MuiSvgIcon-root': {
      color: scutiColors.borderMain,
    },
  },
});

export function ScutiAutocomplete<
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
>({
  title,
  error,
  isLoading,
  placeholder,
  hideHelper,
  ...props
}: Omit<AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>, 'renderInput'> & {
  title?: string;
  error?: React.ReactNode;
  isLoading?: boolean;
  hideHelper?: boolean;
  placeholder?: string;
}): JSX.Element {
  const classes = useStyles();
  return isLoading ? (
    <Skeleton height={58} />
  ) : (
    <FormControl fullWidth error={!!error}>
      {title && <label>{title}</label>}
      <Autocomplete
        {...props}
        className={classes.rootRoot}
        size="small"
        renderInput={params => (
          <TextField
            size="small"
            variant="outlined"
            {...params}
            placeholder={placeholder}
            error={!!error}
            helperText={hideHelper ? undefined : error}
          />
        )}
      />
    </FormControl>
  );
}
