import axios from 'axios';
import { API_URI } from '../config';
import { ApolloError } from 'apollo-client';
import { tokenStorage } from 'utils/token-storage.utils';

const API = axios.create({
  baseURL: API_URI,
  headers: {
    'Content-Type': 'application/json',
  },
});

export function fetcher<TData, TVariables>(query: string, variables?: TVariables) {
  return async (): Promise<TData> => {
    const token = await tokenStorage.getToken();
    const { data } = await API.post(
      `${API_URI}/graphql`,
      { query, variables },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    if (data.errors) {
      throw new ApolloError({ graphQLErrors: data.errors });
    }

    return data.data;
  };
}
