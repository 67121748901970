import { NString } from './util-types';
import { CampaignType } from './__generated__/types';

export interface AppPreloadedState {
  API_URL: NString;
}

export enum Sorting {
  ASC = 'ASC',
  DESC = 'DESC',
}

export const defaultSorting = [{ name: 'updatedAt', dir: 'ASC' }];

export type TableHeader = {
  label: string;
  key: string;
  sorting?: string;
}[];

export type Period = { startDate: Date | null; endDate: Date | null };
export type AnalyticsPeriod = 'week' | 'month' | 'year' | 'day' | 'hour';
export type AnalyticsRange = {
  from: moment.Moment;
  to: moment.Moment;
};

export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
}

export enum SHOP_PAYMENT_PLAN {
  STANDART = 'STANDART',
  PREMIUM = 'PREMIUM',
  ENTERPRICE = 'ENTERPRICE',
}

export const CURRENCIES: { [key: string]: string } = {
  USD: '$',
};

export enum PromotionType {
  HOT_PRICE = 'HOT_PRICE',
  SPECIAL_OFFER = 'SPECIAL_OFFER',
}

export const Offers = [
  'Free shipping',
  'Buy One Get One Free',
  'Gift With Purchase',
  'Free Samples',
  'Limited time offer, or until sold out',
  'TripWire (below cost)',
];

export const GAME_CATEGORIES = [
  'Action',
  'Adventure',
  'Arcade',
  'Board',
  'Card',
  'Casino',
  'Casual',
  'Family',
  'Indie',
  'Kids',
  'Music',
  'Puzzle',
  'Racing',
  'Role Playing',
  'Simulation',
  'Sports',
  'Strategy',
  'Trivia',
  'Word',
];

export const contentCategoryName: { [key: string]: string } = {
  ADULTS_ONLY: 'Adults (A)',
  EARLY_CHILDHOOD: 'Early Childhood (EC)',
  EVERYONE: 'Everyone (E)',
  EVERYONE_10_PLUS: 'Everyone Ten Plus (E10+)',
  MATURE: 'Mature (M)',
  RATING_PENDING: 'Rating Pending (RP)',
  TEEN: 'Teen (T)',
};

export const campaignColors: Record<CampaignType, { name: string; color: string }> = {
  [CampaignType.Banner]: {
    name: 'Banner Campaign',
    color: '#FFC107',
  },
  [CampaignType.Video]: {
    name: 'Video Campaign',
    color: '#9DA5B1',
  },
  [CampaignType.Product]: {
    name: 'Product Campaign',
    color: '#F86C6B',
  },
  [CampaignType.ProductListing]: {
    name: 'Product Listing',
    color: '#0059FF',
  },
  [CampaignType.Takeover]: {
    name: '',
    color: '',
  },
  [CampaignType.InternalUrl]: {
    name: '',
    color: '',
  },
  [CampaignType.AppDownload]: {
    name: '',
    color: '',
  },
};
