import React from 'react';
import { Route, RouteProps, Redirect } from 'react-router-dom';
import { useAppStore } from '../../store/app-store.hook';
import { observer } from 'mobx-react-lite';
import { appRoutes } from 'routing';

export const PrivateRoute: React.FC<RouteProps> = observer(props => {
  const { userInfo } = useAppStore().authStore;

  if (!userInfo.email) {
    return <Redirect to={appRoutes.LOGIN} />;
  }

  if (!userInfo.emailVerified) {
    return <Redirect to={appRoutes.CHECK_EMAIL} />;
  }

  if (!userInfo.phone && userInfo.twoFactorEnabled) {
    return <Redirect to={appRoutes.REGISTER_PHONE} />;
  }

  if (userInfo.phone && !userInfo.phoneVerified) {
    return <Redirect to={appRoutes.VERIFY_PHONE} />;
  }

  if (userInfo.phone && !userInfo.phoneVerified) {
    return <Redirect to={appRoutes.VERIFY_PHONE} />;
  }

  return <Route {...props} />;
});
