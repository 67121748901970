import { Role } from 'types/__generated__/types';
import { FormValidationSchema } from 'utils/form-utils';
import * as Yup from 'yup';

export interface ShopInvitesForm {
  invites: {
    email: string;
    roles: Role[];
    accepted: boolean;
  }[];
}

export const inviteDefaults = (): ShopInvitesForm['invites'][0] => ({
  email: '',
  roles: [Role.ShopAdmin],
  accepted: false,
});

export const validationSchema = (): FormValidationSchema<ShopInvitesForm> => {
  return Yup.object({
    invites: Yup.array().of(
      Yup.object({
        email: Yup.string().email('Email is not valid'),
        roles: Yup.array().of(Yup.mixed().oneOf(Object.values(Role))),
        accepted: Yup.boolean().required(),
      }),
    ),
  });
};
