import { FulfillmentType, WeightUnit } from 'types/__generated__/types';
import { FormValidationSchema, transformedNumber } from 'utils/form-utils';
import { getImgAspectRatio, uploadImage } from 'utils/upload-image.utils';
import * as Yup from 'yup';
import { FullProductFragment } from '../__generated__/product-edit-page.hooks';

export type FormFulfillmentMethod = FullProductFragment['variants'][0]['fulfillmentMethod'];

interface Variant extends Omit<FullProductFragment['variants'][0], 'id'> {
  id?: string;
}

export interface ProductForm extends Omit<FullProductFragment, 'id' | 'variants' | 'shop'> {
  id?: string;
  variants: Variant[];
  defaultImage?: FullProductFragment['defaultImage'];
  shop?: FullProductFragment['shop'];
}

export const variantDefaults = (fulfillmentMethod: FormFulfillmentMethod): Variant => ({
  image: null,
  price: {
    amount: 0,
    currency: 'USD',
  },
  sku: '',
  inStock: 0,
  fulfillmentMethod,
});

export const productFormDefaults = (fulfillmentMethod: FormFulfillmentMethod): ProductForm => ({
  name: '',
  description: '',
  reviewUrl: null,
  chargeTaxes: false,
  category: '',
  defaultImage: '',
  tags: [],
  images: [],
  variants: [variantDefaults(fulfillmentMethod)],
});

export const mapVariantInput = async ({ fulfillmentMethod, image, ...variant }: Variant) => {
  // Make sure that we are returning the correct value to the back-end to not trigger an incorrect validation
  if (variant.compareAt?.amount === 0) {
    variant.compareAt = null;
  }
  return {
    ...variant,
    barcode: variant.barcode || null,
    inStock: Number(variant.inStock),
    weight: variant.weight || null,
    fulfillmentMethodId: fulfillmentMethod.id,
    image: image && typeof image === 'object' ? (await uploadImage(image, { width: 520, height: 520 })).url : image,
  };
};

export const mapProductFormToInput = async ({ shop, ...product }: ProductForm) => {
  let images = (product.images || []).map(async image => {
    if (typeof image === 'string') return image;
    return (await uploadImage(image, { width: 520, height: 520 })).url;
  });

  // Check to see if the default image is something that hasn't been uploaded yet
  let defaultImage = product.defaultImage;
  if (defaultImage && typeof defaultImage !== 'string') {
    defaultImage = (await uploadImage(defaultImage, { width: 520, height: 520 })).url;
  }

  return {
    ...product,
    category: product.category || '',
    defaultImage: defaultImage,
    images: await Promise.all(images),
    variants: await Promise.all(product.variants.map(mapVariantInput)),
  };
};

const FILE_SIZE = 3 * 1024 * 1024;
const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'];

export const validationSchema: FormValidationSchema<ProductForm> = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Name should be at least 3 characters.')
    .max(100, 'Name can be 100 characters or less.')
    .required('Name is required!'),
  description: Yup.string()
    .min(3, 'Description should be at least 3 characters.')
    .max(5000, 'Description can be 5000 characters or less.')
    .required('Description is required!'),
  defaultValue: Yup.string().nullable(),
  category: Yup.string().required('Category is required!'),
  chargeTaxes: Yup.boolean(),
  option1: Yup.string()
    .test('option1', 'Please specify option name', function(this, item) {
      const hasOption = this.parent.variants.some(({ option1 }: Variant) => !!option1);
      return hasOption ? !!item : true;
    })
    .nullable(),
  option2: Yup.string()
    .test('option2', 'Please specify option name', function(this, item) {
      const hasOption = this.parent.variants.some(({ option2 }: Variant) => !!option2);
      return hasOption ? !!item : true;
    })
    .nullable(),
  option3: Yup.string()
    .test('option3', 'Please specify option name', function(this, item) {
      const hasOption = this.parent.variants.some(({ option3 }: Variant) => !!option3);
      return hasOption ? !!item : true;
    })
    .nullable(),
  reviewUrl: Yup.string()
    .matches(
      new RegExp(
        '^(https?:\\/\\/)?' + // protocol
        '((w{3})?\\.?amazon\\.[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~=+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
          '(\\#[-a-z\\d_]*)?$',
        'i',
      ),
      'Link is invalid',
    )
    .nullable(),
  tags: Yup.array<string>(Yup.string()).nullable(),
  images: Yup.mixed()
    .test('fileSize', 'File too large', value => {
      if (!value) return true;
      return value.every((v: string | File) => typeof v === 'string' || v.size <= FILE_SIZE);
    })
    .test('fileFormat', 'Unsupported Format', value => {
      if (!value) return true;
      return value.every((v: string | File) => typeof v === 'string' || SUPPORTED_FORMATS.includes(v.type));
    })
    .nullable()
    .test('aspectRatio', 'Wrong aspect ratio', async value => {
      if (!value) return true;
      const result = await Promise.all(
        value.map(async (v: string | File) => {
          if (typeof v === 'string') return true;
          const [width, height] = await getImgAspectRatio(v);
          return width === height;
        }),
      );
      return result.every(v => !!v);
    }),
  variants: Yup.array<Variant>()
    .of(
      Yup.object().shape({
        image: Yup.string().nullable(),
        imageFile: Yup.mixed()
          .nullable()
          .test('fileSize', 'File too large', value => (value ? value.size <= FILE_SIZE : true))
          .test('fileFormat', 'Unsupported Format', value => (value ? SUPPORTED_FORMATS.includes(value.type) : true))
          .test('aspectRatio', 'Wrong aspect ratio', async value => {
            if (value) {
              const [width, height] = await getImgAspectRatio(value);
              return width === height;
            }
            return true;
          }),
        price: Yup.object().shape({
          amount: transformedNumber.moreThan(0, 'Price can not be 0').required('Price should be specified'),
          currency: Yup.string()
            .min(3, 'Currency name is to short')
            .required('Currency is required!'),
        }),
        compareAt: Yup.object()
          .shape({
            amount: transformedNumber,
            currency: Yup.string().min(3, 'Currency name is to short'),
          })
          .nullable()
          .notRequired(),
        sku: Yup.string()
          .min(3, 'SKU is invalid')
          .max(100, 'SKU is invalid')
          .required('SKU is required'),
        barcode: Yup.string()
          .min(5, 'Barcode is invalid')
          .max(15, 'Barcode is invalid')
          .nullable()
          .notRequired(),
        weight: Yup.object()
          .shape({
            amount: transformedNumber.min(0),
            unit: Yup.mixed<WeightUnit>().oneOf(Object.values(WeightUnit)),
          })
          .nullable()
          .notRequired(),
        inStock: transformedNumber.min(0, 'Can not be less than 0').required('In Stock is Required'),
        fulfillmentMethod: Yup.object().shape({
          id: Yup.string().required('id is required'),
          name: Yup.string().required('name is required'),
          type: Yup.mixed<FulfillmentType>()
            .oneOf([FulfillmentType.Shipping, FulfillmentType.Digital, FulfillmentType.Pickup])
            .required('Type is required'),
        }),
      }),
    )
    .min(1, 'Please specify at least 1 variant'),
});
