import * as Types from '../../../../types/__generated__/types';

import { ApolloError } from 'apollo-client';
import { useQuery, UseQueryOptions, useMutation, UseMutationOptions } from 'react-query';
import { fetcher } from 'api';
export type ProductListingItemFragment = {
  id: string;
  name: string;
  category?: Types.Maybe<string>;
  type: Types.CampaignType;
  status: Types.CampaignStatus;
  shop?: Types.Maybe<{ id: string }>;
  product?: Types.Maybe<{ product: { id: string; name: string; images?: Types.Maybe<Array<string>> } }>;
  duration: {
    start?: Types.Maybe<any>;
    end?: Types.Maybe<any>;
    inventory?: Types.Maybe<number>;
    runUntilStockZero?: Types.Maybe<boolean>;
  };
  budget?: Types.Maybe<{ maxSpend: number; maxDailySpend: number; limitOfImpressions: number }>;
  statistics?: Types.Maybe<{ totalSpend: number; totalRevenue: number; roi: number }>;
};

export type ProductListingsQueryVariables = Types.Exact<{
  paging?: Types.Maybe<Types.PagingInput>;
  sorting?: Types.Maybe<Array<Types.SortFieldInput> | Types.SortFieldInput>;
  filters?: Types.Maybe<Array<Types.FilterInput> | Types.FilterInput>;
  id: Types.Scalars['ID'];
}>;

export type ProductListingsQuery = {
  shopCampaigns: {
    sorting?: Types.Maybe<Array<{ name: string; dir: string }>>;
    paging: { offset: number; limit: number; totalCount: number };
    nodes: Array<ProductListingItemFragment>;
  };
};

export type DeleteProductListingMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;

export type DeleteProductListingMutation = { deleteCampaign?: Types.Maybe<string> };

export const ProductListingItemFragmentDoc = `
    fragment ProductListingItem on Campaign {
  id
  name
  category
  type
  status
  shop {
    id
  }
  product {
    product {
      id
      name
      images
    }
  }
  duration {
    start
    end
    inventory
    runUntilStockZero
  }
  budget {
    maxSpend
    maxDailySpend
    limitOfImpressions
  }
  statistics {
    totalSpend
    totalRevenue
    roi
  }
}
    `;
export const ProductListingsDocument = `
    query ProductListings($paging: PagingInput, $sorting: [SortFieldInput!], $filters: [FilterInput!], $id: ID!) {
  shopCampaigns(id: $id, paging: $paging, sorting: $sorting, filters: $filters) {
    sorting {
      name
      dir
    }
    paging {
      offset
      limit
      totalCount
    }
    nodes {
      ...ProductListingItem
    }
  }
}
    ${ProductListingItemFragmentDoc}`;
export const useProductListingsQuery = <TData = ProductListingsQuery, TError = ApolloError>(
  variables: ProductListingsQueryVariables,
  options?: UseQueryOptions<ProductListingsQuery, TError, TData>,
) =>
  useQuery<ProductListingsQuery, TError, TData>(
    ['ProductListings', variables],
    fetcher<ProductListingsQuery, ProductListingsQueryVariables>(ProductListingsDocument, variables),
    options,
  );
export const DeleteProductListingDocument = `
    mutation deleteProductListing($id: String!) {
  deleteCampaign(id: $id)
}
    `;
export const useDeleteProductListingMutation = <TError = ApolloError, TContext = unknown>(
  options?: UseMutationOptions<DeleteProductListingMutation, TError, DeleteProductListingMutationVariables, TContext>,
) =>
  useMutation<DeleteProductListingMutation, TError, DeleteProductListingMutationVariables, TContext>(
    (variables?: DeleteProductListingMutationVariables) =>
      fetcher<DeleteProductListingMutation, DeleteProductListingMutationVariables>(
        DeleteProductListingDocument,
        variables,
      )(),
    options,
  );
