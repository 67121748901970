import React from 'react';
import classNames from 'classnames';
import { Button, Card, CardBody, Form, FormFeedback, FormGroup, Input } from 'reactstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { PhoneVerifyRequest } from '../../../types/auth-types';

import { CodeIcon } from 'components/svg-icons/Code';
import { validators, validate } from 'utils/form-utils';

interface FormValues {
  error?: string;
  code: string;
  dontAsk: string[];
}

const validationSchema = () =>
  Yup.object().shape({
    code: validators.code,
    dontAsk: Yup.array<string>(),
  });

const initialValues = {
  code: '',
  dontAsk: [],
};

interface TwoStepVerificationPhoneFormProps {
  error?: string;
  onVerify: (params: PhoneVerifyRequest) => void;
  sendSms: () => void;
}

export const TwoStepVerificationPhoneForm: React.FC<TwoStepVerificationPhoneFormProps> = ({
  error,
  onVerify,
  sendSms,
}) => {
  const formik = useFormik<FormValues>({
    enableReinitialize: true,
    initialValues: {
      ...initialValues,
    },
    onSubmit: onVerify,
    isInitialValid: false,
    validate: validate(validationSchema),
  });
  const { values, errors, touched, handleChange, handleBlur, handleSubmit, isValid } = formik;

  return (
    <Card className="w-23">
      <CardBody>
        <div className="card-headline">
          <h1>2-Step Verification</h1>
          <p>Please enter the code that we texted to you.</p>
        </div>
        <Form onSubmit={handleSubmit} noValidate name="simpleForm">
          <FormGroup
            className={classNames(
              'form-group-addon',
              { 'input-active': values.code },
              { 'input-invalid': touched.code && !!errors.code },
              { 'input-touched': touched.code },
              { 'input-valid': touched.code && !errors.code },
            )}
          >
            <label htmlFor="code" className="ico-label">
              <CodeIcon />
            </label>
            <Input
              type="text"
              name="code"
              id="code"
              placeholder="Enter the code"
              valid={touched.code && !errors.code}
              invalid={touched.code && !!errors.code}
              required
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.code}
            />
            <FormFeedback>{errors.code}</FormFeedback>
          </FormGroup>
          {/* <FormGroup className="pt-6">
            <CustomInput
              type="checkbox"
              id="accept"
              label="Don’t ask me for the code again for 30 days when I use this device."
              required
              valid={touched.dontAsk && !errors.dontAsk}
              invalid={touched.dontAsk && !!errors.dontAsk}
              onChange={handleChange}
              onBlur={handleBlur}
            >
              <FormFeedback>{errors.dontAsk}</FormFeedback>
            </CustomInput>
          </FormGroup> */}
          {error && <div className="text-center text-danger mb-1">{error}</div>}
          <FormGroup className="actions">
            <Button type="submit" color="primary" className="btn-block" disabled={!isValid}>
              Validate
            </Button>
          </FormGroup>
          <FormGroup>
            <Button type="button" color="primary" className="btn-block" onClick={sendSms}>
              Resend
            </Button>
          </FormGroup>
        </Form>
      </CardBody>
    </Card>
  );
};
