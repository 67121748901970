export enum AccountType {
  SCUTI = 'SCUTI',
  SHOP = 'SHOP',
}

export enum Permission {
  ADMIN = 'ADMIN',
  OWNER = 'OWNER',
  OPTS = 'OPTS',
  FINANCE = 'FINANCE',
}

export enum ItemType {
  Game,
  Shop,
}

export interface UserItem {
  id: string;
  name: string;
  color: string;
  shopifyName?: string | null;
  itemType?: ItemType;
}

export interface UserItems {
  games: UserItem[];
  shops: UserItem[];
  selectedItem: UserItem | null;
}

export interface User {
  id: string;
  fullName: string | null;
  email: string;
  accountType: AccountType;
  permission: Permission;
}

export type NewUser = Omit<User, 'id'>;

export const getUserDefault = (): NewUser => ({
  fullName: '',
  email: '',
  accountType: AccountType.SCUTI,
  permission: Permission.OPTS,
});
