import React from 'react';
import { observer } from 'mobx-react-lite';
import { useParams, useHistory, useLocation } from 'react-router';
import { ShopEditForm } from './ShopEditForm';
import { useToasts } from 'react-toast-notifications';
import { hasId } from 'types/util-types';
import { shopsRoutes } from 'routing';
import {
  useShopQuery,
  useUpdateShopMutation,
  useCreateShopMutation,
  useInviteShopUserMutation,
  useShopInvitesQuery,
  useRemoveFromShopMutation,
  useResendInviteMutation,
  useEditShopBrandsMutation,
  useShopBrandsQuery,
} from './__generated__/shop-edit-page.hooks';
import { ShopForm, shopFormDefaults, validationSchema as shopSchema } from './ShopEditForm/ShopEditForm.utils';
import { inviteDefaults, validationSchema as invitesSchema } from './ShopInvitesForm/ShopInvitesEditForm.utils';
import { BrandsForm, validationSchema as brandsSchema } from './ShopBrandsEditForm/ShopBrandsEditForm.utils';
import * as queryString from 'query-string';
import { ShopInvitesEditForm } from './ShopInvitesForm/ShopInvitesEditForm';
import { Box, Grid } from '@material-ui/core';
import { Formik } from 'formik';
import { validate } from 'utils/form-utils';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { ShopInvitesForm } from './ShopInvitesForm/ShopInvitesEditForm.utils';
import { useAppStore } from 'store/app-store.hook';
import { hasOneOfRoles } from 'utils/roles.utils';
import { ShopBrandsEditForm } from './ShopBrandsEditForm';
import { Loader } from 'components/Loader';
import { Role } from 'types/__generated__/types';
import { uploadImage } from 'utils/upload-image.utils';

export const ShopEditPage: React.FC = observer(() => {
  const { addToast } = useToasts();
  const { userRoles } = useAppStore().permissions;
  const { shopId } = useParams<{ shopId: string }>();
  const { search } = useLocation();
  const { organizationId } = queryString.parse(search);
  const history = useHistory();
  const title = `${!!shopId ? 'Edit' : 'Create new'} shop`;
  const isAdmin = hasOneOfRoles(userRoles, [Role.ScutiAdmin, Role.ShopOwner]);
  const isScutiAdmin = hasOneOfRoles(userRoles, [Role.ScutiAdmin]);

  const { data, isLoading, error, refetch } = useShopQuery({ id: shopId }, { enabled: !!shopId });
  const shopInvitesQuery = useShopInvitesQuery({ id: shopId }, { enabled: !!shopId });
  const useShopBrands = useShopBrandsQuery({ id: shopId }, { enabled: !!shopId });

  const useUpdateShop = useUpdateShopMutation();
  const useCreateShop = useCreateShopMutation();
  const useEditBrands = useEditShopBrandsMutation();
  const useInviteShopUser = useInviteShopUserMutation();
  const useRemoveFromShop = useRemoveFromShopMutation();
  const useResendInvite = useResendInviteMutation();

  React.useEffect(() => {
    if (error) addToast(error.message, { appearance: 'error', autoDismiss: false });
  }, [addToast, error]);

  const saveOrUpdate = React.useCallback(
    async ({ invites, admins, brands, thumbnail, ...shop }: ShopForm & ShopInvitesForm & BrandsForm) => {
      const freeShippingThreshold = !!shop.freeShippingThreshold ? +shop.freeShippingThreshold : null;
      const flatShippingRate = !!shop.flatShippingRate ? +shop.flatShippingRate : 0;
      try {
        if (hasId(shop)) {
          await Promise.all(
            invites
              .filter(({ email }) => !!email)
              .map(user =>
                useInviteShopUser.mutateAsync({
                  id: shopId,
                  user: {
                    email: user.email,
                    roles: user.roles,
                  },
                }),
              ),
          );
          await useUpdateShop.mutateAsync({
            input: {
              ...shop,
              freeShippingThreshold,
              flatShippingRate,
              thumbnail:
                thumbnail && typeof thumbnail === 'object'
                  ? (await uploadImage(thumbnail, { width: 84, height: 84 })).url
                  : thumbnail,
            },
          });
          await useEditBrands.mutateAsync({ input: { id: shop.id, brands } });
          addToast('Shop has been updated!', { appearance: 'success', autoDismiss: true });
          await Promise.all([refetch(), shopInvitesQuery.refetch(), useShopBrands.refetch()]);
        } else {
          const { createShop } = await useCreateShop.mutateAsync({
            input: {
              ...shop,
              freeShippingThreshold,
              flatShippingRate,
              organizationId: organizationId as string,
              thumbnail:
                thumbnail && typeof thumbnail === 'object'
                  ? (await uploadImage(thumbnail, { width: 84, height: 84 })).url
                  : thumbnail,
            },
          });
          addToast('Shop has created!', { appearance: 'success', autoDismiss: true });
          history.push(shopsRoutes.SHOP_EDIT(createShop.id));
        }
      } catch (e) {
        addToast(e.message, { appearance: 'error', autoDismiss: false });
      }
    },
    [
      useUpdateShop,
      useEditBrands,
      addToast,
      refetch,
      shopInvitesQuery,
      useShopBrands,
      useInviteShopUser,
      shopId,
      useCreateShop,
      organizationId,
      history,
    ],
  );

  const removeUser = React.useCallback(
    async (email: string) => {
      await useRemoveFromShop.mutateAsync({ email, shopId });
      Promise.all([refetch(), shopInvitesQuery.refetch()]);
    },
    [refetch, shopId, shopInvitesQuery, useRemoveFromShop],
  );

  const existedInvites = [
    ...(data?.shop?.admins || []).map(({ email, roles }) => ({ email, roles, accepted: true })),
    ...(shopInvitesQuery.data?.shopInvites.nodes || []),
  ];
  return (
    <Box>
      <Formik
        initialValues={{
          ...(data?.shop || shopFormDefaults()),
          invites: [inviteDefaults()],
          brands: useShopBrands.data?.shopBrands || [],
        }}
        validate={validate<ShopForm & ShopInvitesForm>(() =>
          shopSchema()
            .concat(invitesSchema())
            .concat(brandsSchema()),
        )}
        onSubmit={async (formData, helpers) => {
          await saveOrUpdate(formData);
          helpers.resetForm();
        }}
        enableReinitialize
        validateOnMount
      >
        {formik => {
          return (
            <form className="w-100" onSubmit={formik.handleSubmit}>
              <div className="topbar d-sm-flex justify-content-sm-between">
                <div className="col-heading">
                  <Link className="btn btn-back" to={shopsRoutes.SHOPS} />
                  <h1 className="page-title">{title}</h1>
                </div>
                <div className="col-action">
                  <Button color="outline-secondary" onClick={formik.handleReset}>
                    Cancel
                  </Button>
                  <Button color="primary" type="submit">
                    Save changes
                  </Button>
                </div>
              </div>
              {/* @ts-ignore */}
              <ShopEditForm formik={formik} />
              {isLoading && <Loader />}
              {data?.shop?.id && (
                <Grid container spacing={2}>
                  {isAdmin && (
                    <Grid item xs={12} md={isScutiAdmin ? 8 : 12}>
                      <ShopInvitesEditForm
                        // @ts-ignore
                        formik={formik}
                        existedInvites={existedInvites}
                        onRemove={removeUser}
                        onResend={async (email: string) => {
                          await useResendInvite.mutateAsync({ email });
                          addToast('Invite has been resent!', { appearance: 'success', autoDismiss: true });
                        }}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12} md={4}>
                    {isScutiAdmin && (
                      <ShopBrandsEditForm
                        // @ts-ignore
                        formik={formik}
                        existedBrands={[]}
                      />
                    )}
                  </Grid>
                </Grid>
              )}
            </form>
          );
        }}
      </Formik>
    </Box>
  );
});
