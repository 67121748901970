import { Alert, Box } from '@mui/material';
import { useAppStore } from '../../store/app-store.hook';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';

const LookerStudioPage = observer(() => {
  const { selectedItem } = useAppStore().uiStore;
  const [params, setParams] = useState<string>('');
  const [validGame, setValidGame] = useState(false);

  useEffect(() => {
    setValidGame(!!!selectedItem.shopifyName);

    const params = {
      'ds14.mygameid': selectedItem.id,
      'ds16.mygameid': selectedItem.id,
      'ds18.mygameid': selectedItem.id,
      'ds31.mygameid': selectedItem.id,
    };
    const paramsAsString = JSON.stringify(params);
    setParams(encodeURIComponent(paramsAsString));
  }, [selectedItem]);
  return (
    <Box component="section" sx={{ p: 2, height: '100%' }}>
      {!validGame && (
        <Alert severity="info" variant="outlined" sx={{ color: '#000' }}>
          <p style={{ color: 'black', margin: 0 }}>Please select a valid game.</p>
        </Alert>
      )}
      {validGame && !!params && (
        <iframe
          title="LookerStudio x Scuti"
          width="100%"
          height="100%"
          src={`https://lookerstudio.google.com/embed/reporting/a0274ac9-ff1d-44fe-8d24-da68eff8334c/page/1X9uD?params=${params}`}
          frameBorder="0"
          style={{
            border: 0,
          }}
          allowFullScreen
          sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
        ></iframe>
      )}
    </Box>
  );
});

export default LookerStudioPage;
