import React from 'react';
import { FormGroup, CustomInput } from 'reactstrap';
import { GENDER_SELECT_OPTIONS } from 'types/select-types';
import InputRange, { Range } from 'react-input-range';
import { FormikProps } from 'formik';
import { Box, Card, CardContent, CardHeader } from '@material-ui/core';
import { ScutiSelect } from 'components/material/ScutiSelect';
import { CampaignForm, isPaused } from './CampaignForm.utils';

export const AudienceDetails: React.FC<FormikProps<CampaignForm>> = props => {
  const { values } = props;
  const [genderSpecific, setGenderSpecific] = React.useState(!!values.demographic.gender);
  const [ageRangeSpecific, setAgeRangeSpecific] = React.useState(
    values.demographic.minAge !== 0 || values.demographic.maxAge !== 100,
  );
  const toggleGender = () => {
    if (!genderSpecific) props.setFieldValue('demographic.gender', GENDER_SELECT_OPTIONS[1].value, true);
    else props.setFieldValue('demographic.gender', null, true);
    setGenderSpecific(!genderSpecific);
  };

  const toggleAgeRange = () => {
    props.setFieldValue(`demographic`, { ...values.demographic, minAge: 0, maxAge: 100 }, true);
    setAgeRangeSpecific(!ageRangeSpecific);
  };

  const onRangeChange = (v: Range | number) => {
    if (typeof v === 'number') return;
    props.setFieldValue(
      `demographic`,
      { ...values.demographic, minAge: Math.max(0, v.min), maxAge: Math.min(v.max, 100) },
      true,
    );
  };

  return (
    <Card>
      <CardHeader title="Audience" />
      <CardContent>
        <FormGroup>
          <label htmlFor={`demographic.gender`}>
            <CustomInput
              id="gender-box"
              type="checkbox"
              label="Gender specific"
              onChange={toggleGender}
              checked={genderSpecific}
              disabled={!isPaused(values)}
            />
          </label>
          <ScutiSelect
            placeholder="Select gender"
            name="demographic.gender"
            value={values.demographic.gender || ''}
            options={GENDER_SELECT_OPTIONS.slice(1)}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            disabled={!genderSpecific || !isPaused(values)}
          />
        </FormGroup>
        <FormGroup>
          <label htmlFor={`demographic.gender`}>
            <CustomInput
              id="age-rage-box"
              type="checkbox"
              label="Age Range"
              onChange={toggleAgeRange}
              checked={ageRangeSpecific}
              disabled={!isPaused(values)}
            />
          </label>
          <Box pl={2} pr={2}>
            <InputRange
              maxValue={100}
              minValue={0}
              value={{ min: values.demographic.minAge, max: values.demographic.maxAge }}
              onChange={onRangeChange}
              disabled={!ageRangeSpecific || !isPaused(values)}
            />
          </Box>
        </FormGroup>
      </CardContent>
    </Card>
  );
};
