import * as Yup from 'yup';
import { FormValidationSchema } from 'utils/form-utils';
import { ShopBrandFragment } from '../__generated__/shop-edit-page.hooks';
import { v4 as uuidv4 } from 'uuid';

export interface BrandsForm {
  brands: ShopBrandFragment[];
}

export const brandDefaults = (name: string): ShopBrandFragment => ({ id: uuidv4(), name });

export const validationSchema = (): FormValidationSchema<BrandsForm> => {
  return Yup.object({
    brands: Yup.array().of(
      Yup.object({
        id: Yup.string().required(''),
        name: Yup.string().required(''),
      }),
    ),
  });
};
