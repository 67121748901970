import 'animate.css';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './App.scss';
import * as history from 'history';
import { Router } from 'react-router';
import { RouterStore, syncHistoryWithStore } from 'mobx-react-router';
import { AppStoreProvider } from './store/app-store.hook';
import { AppStore } from './store';
import { AppRouter } from './routing/AppRouter';
import { ToastProvider } from 'react-toast-notifications';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ThemeProvider } from '@material-ui/core';
import { theme } from 'material-theme';
import { StyledEngineProvider } from '@mui/material/styles';

const browserHistory = history.createBrowserHistory();
const routerStore = new RouterStore();
const syncHistory = syncHistoryWithStore(browserHistory, routerStore);
const appStore = new AppStore(routerStore);

const createAppStore = () => appStore;

const client = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

export const App = () => {
  return (
    <QueryClientProvider client={client}>
      <ThemeProvider theme={theme}>
        <StyledEngineProvider injectFirst>
          <AppStoreProvider createAppStore={createAppStore}>
            <Router history={syncHistory}>
              <ToastProvider placement="bottom-right" autoDismissTimeout={2000}>
                <AppRouter />
              </ToastProvider>
            </Router>
          </AppStoreProvider>
        </StyledEngineProvider>
      </ThemeProvider>
    </QueryClientProvider>
  );
};
