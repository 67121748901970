import React from 'react';
import { ButtonToolbar, ButtonGroup, Button } from 'reactstrap';
import { DateRangePicker, FocusedInputShape } from 'react-dates';
import moment from 'moment';
import { Period } from 'types/app-types';

interface Props {
  onSelect: (period: Period | null) => void;
}

export const PeriodSelector: React.FC<Props> = ({ onSelect }) => {
  const [today] = React.useState(new Date());
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [focusedInput, setFocusedInput] = React.useState<FocusedInputShape | null>(null);
  const [dates, setDates] = React.useState<{ startDate: moment.Moment | null; endDate: moment.Moment | null }>({
    startDate: null,
    endDate: null,
  });

  const selectPeriod = React.useCallback(
    (tabIndex: number, period: Period | null) => () => {
      onSelect(period);
      setSelectedTab(tabIndex);
      if (period) {
        const { startDate, endDate } = period;
        setDates({ startDate: startDate && moment(startDate), endDate: endDate && moment(endDate) });
      } else {
        setDates({ startDate: null, endDate: null });
      }
    },
    [onSelect],
  );

  const onDatesChange = React.useCallback(
    (v: { startDate: moment.Moment | null; endDate: moment.Moment | null }) => {
      setDates(v);
      if (v.startDate && v.endDate) {
        onSelect({ startDate: v.startDate.toDate(), endDate: v.endDate.toDate() });
      }
    },
    [onSelect],
  );
  return (
    <div className="period-selector">
      <ButtonToolbar>
        <ButtonGroup className="mr-3">
          <Button color="secondary" onClick={selectPeriod(0, null)} active={selectedTab === 0}>
            All
          </Button>
          <Button
            color="secondary"
            onClick={selectPeriod(1, { startDate: today, endDate: today })}
            active={selectedTab === 1}
          >
            Day
          </Button>
          <Button
            color="secondary"
            onClick={selectPeriod(2, {
              startDate: moment()
                .subtract(1, 'months')
                .toDate(),
              endDate: today,
            })}
            active={selectedTab === 2}
          >
            Month
          </Button>
          <Button
            color="secondary"
            onClick={selectPeriod(3, {
              startDate: moment()
                .subtract(1, 'years')
                .toDate(),
              endDate: today,
            })}
            active={selectedTab === 3}
          >
            Year
          </Button>
        </ButtonGroup>
      </ButtonToolbar>
      <DateRangePicker
        startDateId="startDate"
        endDateId="endDate"
        startDate={dates.startDate}
        endDate={dates.endDate}
        onDatesChange={onDatesChange}
        focusedInput={focusedInput}
        onFocusChange={setFocusedInput}
        small
      />
    </div>
  );
};
