import { Box } from '@mui/material';
import { ChangeEventHandler, LegacyRef, useRef } from 'react';
import cn from 'classnames';
import { ImgPreview } from './ImgPreview';
import styles from './ScutiImgInput.module.scss';

interface Props {
  image?: string | File | null;
  error?: boolean;
  disabled?: boolean;
  onChange: (file: File | null) => void;
}

export const ScutiImgInput: React.FC<Props> = ({ image, children, error, disabled, onChange }) => {
  const fileInputField: LegacyRef<HTMLInputElement> = useRef(null);

  const handleUploadClick = () => {
    fileInputField.current?.click();
  };

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = event => {
    const { files } = event.target;
    if (files?.length) {
      onChange(files[0]);
    }
  };

  return (
    <Box
      className={cn(styles.scuti_img_input, { [styles.scuti_img_input_error]: error })}
      width="100%"
      height="100%"
      onClick={handleUploadClick}
    >
      {image ? (
        <ImgPreview
          url={typeof image === 'object' ? URL.createObjectURL(image) : image}
          onDelete={() => onChange(null)}
          disabled={disabled}
        />
      ) : (
        <>
          <Box
            height="100%"
            className={cn({
              [styles.scuti_img_input_wrapper]: !disabled,
              [styles.scuti_img_input_wrapper_disabled]: disabled,
            })}
          >
            {children}
          </Box>
          {!disabled && (
            <input className={styles.file_input} type="file" ref={fileInputField} onChange={handleInputChange} />
          )}
        </>
      )}
    </Box>
  );
};
