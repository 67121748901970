import { deepOrange, lightBlue, yellow, indigo } from '@material-ui/core/colors';

const colorPalettes = [deepOrange, lightBlue, yellow, indigo];
const colorShade = [50, 100, 200, 300, 400, 500, 600, 700, 800, 900];

const getRandomPalate = () => {
  const index = Math.floor(Math.random() * colorPalettes.length);
  return colorPalettes[index];
};

export const getRandomColor = () => {
  const index = Math.floor(Math.random() * colorShade.length);
  const key = colorShade[index];
  // @ts-ignore
  return getRandomPalate()[key];
};
