import React from 'react';
import { observer } from 'mobx-react-lite';
import { ProductListingEditForm } from './ProductListingEditForm';
import { useHistory, useLocation, useParams } from 'react-router';
import queryString from 'query-string';
import { useToasts } from 'react-toast-notifications';
import {
  useCreateProductListingMutation,
  useProductListingQuery,
  useToggleListingMutation,
  useUpdateProductListingMutation,
} from './__generated__/product-listing-edit-page.hooks';
import { ProductListingForm } from './ProductListingEditForm/ProductListingEditForm.utils';
import { hasId } from 'types/util-types';
import { productListingRoutes } from 'routing';
import { Loader } from 'components/Loader';
import { CampaignType, ToggleCampaignInput } from 'types/__generated__/types';

export const ProductListingEditPage: React.FC = observer(() => {
  const { listingId } = useParams<{ listingId: string }>();
  const { search } = useLocation();
  const searchQuery = queryString.parse(search);
  const history = useHistory();
  const { addToast } = useToasts();

  const productListingQuery = useProductListingQuery({ id: listingId }, { enabled: !!listingId });
  const productListing = productListingQuery.data?.campaign;
  const shopId = (searchQuery.shopId as string) || productListing?.shop?.id;

  const useUpdateProductListing = useUpdateProductListingMutation();
  const useCreateProductListing = useCreateProductListingMutation();
  const useToggleListing = useToggleListingMutation();

  const isLoading = productListingQuery.isLoading || useToggleListing.isLoading;

  React.useEffect(() => {
    if (productListingQuery.error)
      addToast(productListingQuery.error.message, { appearance: 'error', autoDismiss: false });
  }, [addToast, productListingQuery.error]);

  const saveOrUpdate = React.useCallback(
    async ({ shop, status, shopBrand, ...productListing }: ProductListingForm) => {
      if (hasId(productListing)) {
        try {
          await useUpdateProductListing.mutateAsync({
            input: {
              game: {},
              ...productListing,
              shopBrandId: shopBrand?.id || null,
              category: productListing.category || null,
              location: {
                states: productListing.location?.states || null,
                country: productListing.location?.country || null,
              },
              product: {
                productId: productListing.product?.product.id || '',
                promotion: productListing.product?.promotion,
              },
              type: CampaignType.ProductListing,
              shopId: shopId as string,
            },
          });
          await productListingQuery.refetch();
          addToast('Product Listing has been updated!', {
            appearance: 'success',
            autoDismiss: true,
          });
        } catch (e) {
          addToast(e.message, { appearance: 'error', autoDismiss: false });
        }
      } else {
        try {
          await useCreateProductListing.mutateAsync({
            input: {
              game: {},
              ...productListing,
              shopBrandId: shopBrand?.id || null,
              category: productListing.category || '',
              location: {
                states: productListing.location?.states || null,
                country: productListing.location?.country || null,
              },
              product: {
                productId: productListing.product?.product.id || '',
                promotion: productListing.product?.promotion,
              },
              type: CampaignType.ProductListing,
              shopId: shopId as string,
            },
          });
          addToast('Product Listing has created!', { appearance: 'success', autoDismiss: true });
          history.push(productListingRoutes.PRODUCT_LISTING);
        } catch (e) {
          addToast(e.message, { appearance: 'error', autoDismiss: false });
        }
      }
    },
    [addToast, history, productListingQuery, shopId, useCreateProductListing, useUpdateProductListing],
  );

  const toggleListing = React.useCallback(
    async (input: ToggleCampaignInput) => {
      try {
        await useToggleListing.mutateAsync({ input });
        await productListingQuery.refetch();
        addToast('Product Listing has been paused!', { appearance: 'success', autoDismiss: true });
      } catch (e) {
        addToast(e.message, { appearance: 'error', autoDismiss: false });
      }
    },
    [addToast, productListingQuery, useToggleListing],
  );

  const showForm = listingId ? productListingQuery.isSuccess && !!productListing : true;

  return (
    <div>
      {isLoading && <Loader />}
      {showForm && (
        <ProductListingEditForm
          linkBack={productListingRoutes.PRODUCT_LISTING}
          productListing={
            productListing
              ? {
                  ...productListing,
                  reward: {
                    scutiPercentage: productListing.reward?.scutiPercentage || 0,
                  },
                }
              : null
          }
          onSave={saveOrUpdate}
          onToggle={toggleListing}
        />
      )}
    </div>
  );
});
