import React from 'react';
import { Form, Button } from 'reactstrap';
import { Formik } from 'formik';
import { Link } from 'react-router-dom';
import { validate } from 'utils/form-utils';
import { Box, Grid, Typography } from '@material-ui/core';
import {
  isPaused,
  ProductListingForm,
  productListingFormDefaults,
  validationSchema,
} from './ProductListingEditForm.utils';
import { ProductDetails } from './ProductDetails';
import { DurationDetails } from './DurationDetails';
import { AudienceDetails } from './AudienceDetails';
import { RewardsDetails } from './RewardsDetails';
import { PromotionDetails } from './PromotionDetails';
import { EnhancementsDetails } from './EnhancementsDetails';
import { ScutiInput } from 'components/material/ScutiInput';
import { ToggleCampaignInput } from 'types/__generated__/types';

interface Props {
  productListing?: ProductListingForm | null;
  linkBack: string;
  onSave: (values: ProductListingForm) => void;
  onToggle: (input: ToggleCampaignInput) => void;
}

export const ProductListingEditForm: React.FC<Props> = ({ productListing, linkBack, onSave, onToggle }) => {
  return (
    <Formik
      enableReinitialize
      initialValues={productListing || productListingFormDefaults()}
      validate={validate<ProductListingForm>(() => validationSchema())}
      onSubmit={onSave}
      validateOnMount
    >
      {props => {
        const { values, touched, isSubmitting, errors } = props;
        return (
          <Form className="w-100" onSubmit={props.handleSubmit}>
            <div className="topbar d-sm-flex justify-content-sm-between">
              <div className="col-heading">
                <Link className="btn btn-back" to={linkBack}></Link>
                <Box ml={5} width={'50%'}>
                  <ScutiInput
                    name="name"
                    placeholder="Product listing name"
                    value={values.name}
                    error={touched.name && errors.name}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    disabled={!isPaused(values)}
                  />
                </Box>
              </div>
              <div className="col-action">
                <Button color="outline-secondary" onClick={props.handleReset} disabled={!isPaused(values)}>
                  Cancel
                </Button>
                {productListing?.id && (
                  <Button
                    color={!isPaused(values) ? 'primary' : 'outline-secondary'}
                    onClick={() => onToggle({ id: productListing.id!, isPaused: !isPaused(values) })}
                  >
                    {isPaused(values) ? 'Activate' : 'Pause'}
                  </Button>
                )}
                <Button color="primary" type="submit" disabled={isSubmitting || !isPaused(values)}>
                  Save changes
                </Button>
              </div>
            </div>
            <Grid container spacing={4}>
              <Grid item xs={12} md={6} lg={4}>
                <ProductDetails {...props} />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <DurationDetails {...props} />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <AudienceDetails {...props} />
              </Grid>
            </Grid>
            <Box mt={2} mb={2}>
              <Typography variant="h6">Add Extras</Typography>
              <Box>Please select the extras you’d like to create below.</Box>
            </Box>
            <Grid container spacing={4}>
              <Grid item xs={12} md={6} lg={4}>
                <RewardsDetails {...props} />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <PromotionDetails {...props} />
              </Grid>
              <Grid item xs={12} md={6} lg={4}>
                <EnhancementsDetails {...props} />
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};
