import React from 'react';
import classNames from 'classnames';
import { Button, Card, CardBody, CustomInput, Form, FormFeedback, FormGroup, Input, NavLink } from 'reactstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { RegisterRequest } from '../../../types/auth-types';

import { EmailIcon } from 'components/svg-icons/Email';
import { UserIcon } from 'components/svg-icons/User';
import { LockIcon } from 'components/svg-icons/Lock';
import { Link } from 'react-router-dom';
import { appRoutes } from 'routing';
import { validators, validate } from 'utils/form-utils';

interface FormValues {
  email: string;
  fullName: string;
  password: string;
  accept: string[];
}

const validationSchema = () =>
  Yup.object().shape({
    email: validators.email,
    fullName: validators.fullName,
    password: validators.password,
    accept: validators.accept,
  });

const initialValues = {
  email: '',
  fullName: '',
  password: '',
  accept: [],
};

interface RegistrationFormProps {
  error?: string;
  email?: string;
  onRegister: (params: RegisterRequest) => void;
}

export const RegistrationForm: React.FC<RegistrationFormProps> = ({ onRegister, email = '', error }) => {
  const formik = useFormik<FormValues>({
    enableReinitialize: true,
    initialValues: {
      ...initialValues,
      email,
    },
    onSubmit: onRegister,
    isInitialValid: false,
    validate: validate(validationSchema),
  });
  const { values, errors, touched, handleChange, handleBlur, handleSubmit, isValid } = formik;

  return (
    <Card className="w-23">
      <CardBody>
        <div className="card-headline">
          <h1>Register</h1>
          <p>Fill out the following information to sign up.</p>
        </div>
        <Form onSubmit={handleSubmit} noValidate>
          <FormGroup
            className={classNames(
              'form-group-addon',
              { 'input-active': values.email },
              { 'input-invalid': touched.email && !!errors.email },
              { 'input-touched': touched.email },
              { 'input-valid': touched.email && !errors.email },
            )}
          >
            <label htmlFor="email" className="ico-label">
              <EmailIcon />
            </label>
            {!!email ? (
              <Input type="email" name="email" placeholder="Email" valid={true} defaultValue={email} />
            ) : (
              <Input
                type="email"
                name="email"
                placeholder="Email"
                autoComplete="email"
                valid={touched.email && !errors.email}
                invalid={touched.email && !!errors.email}
                required
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
              />
            )}
            <FormFeedback>{errors.email}</FormFeedback>
          </FormGroup>
          <FormGroup
            className={classNames(
              'form-group-addon',
              { 'input-active': values.fullName },
              { 'input-invalid': touched.fullName && !!errors.fullName },
              { 'input-touched': touched.fullName },
              { 'input-valid': touched.fullName && !errors.fullName },
            )}
          >
            <label htmlFor="fullName" className="ico-label">
              <UserIcon />
            </label>
            <Input
              type="text"
              name="fullName"
              id="fullName"
              placeholder="Full Name"
              valid={touched.fullName && !errors.fullName}
              invalid={touched.fullName && !!errors.fullName}
              required
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.fullName}
            />
            <FormFeedback>{errors.fullName}</FormFeedback>
          </FormGroup>
          <FormGroup
            className={classNames(
              'form-group-addon',
              { 'input-active': values.password },
              { 'input-invalid': touched.password && !!errors.password },
              { 'input-touched': touched.password },
              { 'input-valid': touched.password && !errors.password },
            )}
          >
            <label htmlFor="password" className="ico-label">
              <LockIcon />
            </label>
            <Input
              type="password"
              name="password"
              id="password"
              placeholder="Password"
              autoComplete="new-password"
              valid={touched.password && !errors.password}
              invalid={touched.password && !!errors.password}
              required
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
            />
            <FormFeedback>{errors.password}</FormFeedback>
          </FormGroup>
          <FormGroup className="d-flex pt-6">
            <CustomInput
              type="checkbox"
              id="accept"
              label="I agree to all statements included in"
              required
              valid={touched.accept && !errors.accept}
              invalid={touched.accept && !!errors.accept}
              onChange={handleChange}
              onBlur={handleBlur}
            >
              <FormFeedback>{errors.accept}</FormFeedback>
            </CustomInput>
            <NavLink target="_blank" href="https://creativecommons.org/terms/" className="fs-12 link-terms">
              Terms of Use
            </NavLink>
          </FormGroup>
          {error && <div className="text-center text-danger mb-1">{error}</div>}
          <FormGroup className="actions">
            <Button type="submit" color="primary" className="btn-block" disabled={!isValid}>
              Create New Account
            </Button>
          </FormGroup>
          <div className="d-flex align-items-center justify-content-center">
            <span className="mr-1">Already a member?</span>
            <Link className="text-center" to={appRoutes.LOGIN}>
              Login
            </Link>
          </div>
        </Form>
      </CardBody>
    </Card>
  );
};
