import { useMemo } from 'react';
import { SortingRule } from 'react-table';
import { SortFieldInput } from 'types/__generated__/types';

export function useSorting<T extends object>(sortBy: SortingRule<T>[], defaultSorting: SortFieldInput[] = []) {
  return useMemo(() => {
    const tableSorting = sortBy.reduce<SortFieldInput[]>((acc, sortBy) => {
      if (sortBy.desc === undefined) return acc;
      return [
        ...acc,
        {
          name: sortBy.id,
          dir: sortBy.desc ? 'DESC' : 'ASC',
        },
      ];
    }, []);
    return tableSorting.length ? tableSorting : defaultSorting;
  }, [defaultSorting, sortBy]);
}
