import React from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory, useParams } from 'react-router';
import { OrganizationEditForm } from './OrganizationEditForm';
import {
  useCreateOrganizationMutation,
  useOrganizationQuery,
  useUpdateOrganizationMutation,
} from './__generated__/organization-edit-page.hooks';
import { OrganizationForm } from './OrganizationEditForm/OrganizationEditForm.utils';
import { hasId } from '../../../types/util-types';
import { useToasts } from 'react-toast-notifications';
import { organizationsRoutes } from 'routing';

export const OrganizationEditPage: React.FC<{ onBack?: () => void }> = observer(({ onBack }) => {
  const history = useHistory();
  const { id = '' } = useParams<{ id?: string }>();
  const { addToast } = useToasts();
  const title = `${!!id ? 'Edit' : 'Create new'} organization`;

  const organizationQuery = useOrganizationQuery({ id }, { enabled: !!id });
  const useUpdateOrganization = useUpdateOrganizationMutation();
  const useCreateOrganization = useCreateOrganizationMutation();

  const organization = organizationQuery.data?.organization;

  React.useEffect(() => {
    if (organizationQuery.error) addToast(organizationQuery.error.message, { appearance: 'error', autoDismiss: false });
  }, [addToast, organizationQuery.error]);

  const saveOrUpdate = React.useCallback(
    async (organization: OrganizationForm) => {
      try {
        if (hasId(organization)) {
          const { owner, ...withoutOwner } = organization;
          await useUpdateOrganization.mutateAsync({
            input: { ...withoutOwner, dbaName: withoutOwner.dbaName || '', phone: withoutOwner.phone || '' },
          });
          addToast('Organization has been updated!', { appearance: 'success', autoDismiss: true });
          organizationQuery.refetch();
        } else {
          const { createOrganization } = await useCreateOrganization.mutateAsync({
            input: {
              ...organization,
              dbaName: organization.dbaName || '',
              phone: organization.phone || '',
              owner: {
                ...organization.owner,
                fullName: organization.owner.fullName || '',
              },
            },
          });
          addToast('Organization has created!', { appearance: 'success', autoDismiss: true });
          history.push(organizationsRoutes.ORGANIZATION_EDIT(createOrganization.id));
        }
      } catch (e) {
        addToast(e.message, { appearance: 'error', autoDismiss: false });
      }
    },
    [addToast, history, organizationQuery, useCreateOrganization, useUpdateOrganization],
  );

  return (
    <OrganizationEditForm
      isLoading={organizationQuery.isLoading}
      title={title}
      organization={organization}
      onSave={saveOrUpdate}
      onBack={onBack}
    />
  );
});
