import React from 'react';
import { FormikProps } from 'formik';
import { Box, Typography, Card, CardHeader, CardContent } from '@material-ui/core';
import { CampaignForm, isPaused } from './CampaignForm.utils';
import { ScutiImgInput } from 'components/material/ScutiImgInput';

export const ProductBannerDetails: React.FC<FormikProps<CampaignForm>> = props => {
  const { values, errors, setFieldValue } = props;
  return (
    <Card>
      <CardHeader title="Banner Ad" />
      <CardContent>
        <Box>
          <Box width="100%" height="15rem">
            <ScutiImgInput
              image={values.media?.vertical}
              // @ts-ignore
              error={!!errors.media?.vertical}
              onChange={file => setFieldValue('media.vertical', file, true)}
              disabled={!isPaused(values)}
            >
              <Box
                height="100%"
                pl="2rem"
                pr="2rem"
                display="flex"
                flexDirection="column"
                color="text.secondary"
                justifyContent="center"
                alignItems="center"
              >
                <Typography variant="body2">
                  Upload a 300x600px or 1:2 aspect ratio image for vertical portrait tile.
                </Typography>
              </Box>
            </ScutiImgInput>
            <Box>
              <Typography color="error" variant="caption">
                {/* @ts-ignore */}
                {errors.media?.vertical}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box mt={4}>
          <Box width="100%" height="7.5rem">
            <ScutiImgInput
              image={values.media?.tile}
              // @ts-ignore
              error={!!errors.media?.tile}
              onChange={file => setFieldValue('media.tile', file, true)}
              disabled={!isPaused(values)}
            >
              <Box
                height="100%"
                pl="2rem"
                pr="2rem"
                display="flex"
                flexDirection="column"
                color="text.secondary"
                justifyContent="center"
                alignItems="center"
              >
                <Typography variant="body2">
                  Upload a 300x250px or 1.2:1 aspect ratio image for medium rectangle tile.
                </Typography>
              </Box>
            </ScutiImgInput>
            <Box mb={2}>
              <Typography color="error" variant="caption">
                {/* @ts-ignore */}
                {errors.media?.tile}
              </Typography>
            </Box>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};
