import * as Yup from 'yup';
import { AccountType, Permission } from 'types/user-types';
import { FulfillmentType, Role, WeightUnit } from 'types/__generated__/types';

export type FormValidationSchema<FormValues extends object> = Yup.ObjectSchema<Yup.Shape<object, FormValues>>;

export const getErrorsFromValidationError = (validationError: { inner?: string[] }) => {
  const FIRST_ERROR = 0;
  return validationError.inner?.reduce((errors: { [key: string]: string }, error: any) => {
    return {
      ...errors,
      [error.path]: error.errors[FIRST_ERROR],
    };
  }, {});
};

export const validate = <T extends object>(
  getValidationSchema: () => FormValidationSchema<T>,
  preProcessValues?: (_: T) => T,
) => {
  return (values: T) => {
    const validationSchema = getValidationSchema();
    try {
      const processed = preProcessValues ? preProcessValues(values) : values;
      validationSchema.validateSync(processed, { abortEarly: false });
      return {};
    } catch (error) {
      return getErrorsFromValidationError(error);
    }
  };
};

export const transformedNumber = Yup.number().transform(value => (isNaN(value) ? 0 : value));
export const websiteRegEx = /[\w.-]+(?:\.[\w\\.-]+)+[\w\-\\._~:/?#[\]@!\\$&'\\(\\)\\*\\+,;=.]+$/;
export const einRegEx = /^([07][1-7]|1[0-6]|2[0-7]|[35][0-9]|[468][0-8]|9[0-589])-?\d{7}$/;
export const ssnRegEx = /^(?!000|666)[0-8][0-9]{2}-(?!00)[0-9]{2}-(?!0000)[0-9]{4}$/;
export const phoneNumberRegEx = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
export const zipRegEx = /^\d{5}(-\d{4})?$/;
export const routingNumberRegEx = /^[0-9]{10,12}$/;
export const bankAccountRegEx = /^[0-9]{7,14}$/;

export const validators = {
  email: Yup.string()
    .email('Email is wrong — please correct.')
    .required('Email is required!'),
  fullName: Yup.string()
    .matches(
      /^([a-zA-Z0-9]+|[a-zA-Z0-9]+\s{1}[a-zA-Z0-9]{1,}|[a-zA-Z0-9]+\s{1}[a-zA-Z0-9]{3,}\s{1}[a-zA-Z0-9]{1,})$/,
      'Full name can not contain symbols.',
    )
    .min(3, 'Full name should be at least 3 characters.')
    .max(25, 'Full name can be 25 characters or less.')
    .required('Full name is required!'),
  password: Yup.string()
    .min(6, `Password has to be at least ${6} characters!`)
    .matches(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/, 'Password must contain: numbers, uppercase and lowercase characters')
    .required('Password is required'),
  repeatPassword: Yup.string().when('password', {
    is: val => (val && val.length > 0 ? true : false),
    then: Yup.string().oneOf([Yup.ref('password')], 'Both password need to be the same'),
    otherwise: Yup.string().min(6, `Enter new password first.`),
  }),
  name: Yup.string()
    .matches(
      /^([a-zA-Z0-9]+|[a-zA-Z0-9]+\s{1}[a-zA-Z0-9]{1,}|[a-zA-Z0-9]+\s{1}[a-zA-Z0-9]{3,}\s{1}[a-zA-Z0-9]{1,})$/,
      'Name can not contain symbols.',
    )
    .min(3, 'Name should be at least 3 characters.')
    .max(25, 'Name can be 25 characters or less.')
    .required('Name is required!'),
  description: Yup.string()
    .max(12, 'Descritions is too short. Min size is 12 characters.')
    .max(180, 'Descritions is too long. Max size is 180 characters.')
    .nullable(),
  productDescription: Yup.string()
    .max(12, 'Descritions is too short. Min size is 12 characters.')
    .max(380, 'Descritions is too long. Max size is 380 characters.')
    .nullable(),
  businessName: Yup.string()
    .matches(
      /^([a-zA-Z0-9]+|[a-zA-Z0-9]+\s{1}[a-zA-Z0-9]{1,}|[a-zA-Z0-9]+\s{1}[a-zA-Z0-9]{3,}\s{1}[a-zA-Z0-9]{1,})$/,
      'Business name can not contain symbols.',
    )
    .min(3, 'Business name should be at least 3 characters.')
    .max(25, 'Business name can be 25 characters or less.')
    .required('Business name is required!'),
  website: Yup.string()
    .matches(websiteRegEx, 'Website url is invalid!')
    .nullable(),
  category: Yup.string()
    .min(1, 'Category is required!')
    .required('Category is required!'),
  identificationNumber: Yup.string().matches(
    /(^[1-9]\d?-\d{7}$|^\d{3}-?\d{2}-?\d{4}$)/,
    'Please enter correct EIN or SSN',
  ),
  address1: Yup.string()
    .min(6, 'Address1 is too short. Min size is 6 characters.')
    .max(30, 'Address1 is too long. Max size is 30 characters.'),
  address2: Yup.string().max(30, 'Address1 is too long. Max size is 30 characters.'),
  city: Yup.string()
    .matches(/^[a-zA-Z]+(?:[\s-][a-zA-Z]+)*$/, 'City is wrong.')
    .required('City is required!'),
  country: Yup.string()
    .min(5, 'Country is required')
    .required(),
  zipCode: Yup.string()
    .matches(/^\d{5}(-\d{4})?$/, 'Code is invalid.')
    .required('Code is required!'),
  videoUrl: Yup.string().matches(
    /[\w.-]+(?:\.[\w\\.-]+)+[\w\-\\._~:/?#[\]@!\\$&'\\(\\)\\*\\+,;=.]+$/,
    'Video url is invalid.',
  ),
  tags: Yup.array<string>(Yup.string()),
  product: Yup.object().shape({
    id: Yup.string().min(20, 'Product is required!'),
    name: Yup.string(),
  }),
  banner: Yup.string().nullable(),
  thumbnail: Yup.string().nullable(),
  currency: Yup.object()
    .shape({
      name: Yup.string()
        .min(2)
        .required('Name is required!'),
      thumbnail: Yup.string().nullable(),
      exchangeRate: transformedNumber.moreThan(0, 'Exchange rate is required'),
    })
    .nullable(),
  genderPercent: Yup.object()
    .shape({
      male: transformedNumber.min(0).max(100),
      female: transformedNumber.min(0).max(100),
    })
    .nullable(),
  accountType: Yup.mixed().oneOf([AccountType.SCUTI, AccountType.SHOP], 'Account type is not specified'),
  permission: Yup.mixed().oneOf(
    [Permission.ADMIN, Permission.FINANCE, Permission.OPTS, Permission.OWNER],
    'Permission type is not specified',
  ),
  phone: Yup.string()
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      `Phone format is not correct!`,
    )
    .required('Phone is required'),
  countryCode: transformedNumber.moreThan(0, 'Code is required'),
  accept: Yup.array<string>()
    .required('* required')
    .test('accept', 'You have to accept our Terms of Use!', value => !!value.length),
  code: Yup.string()
    .min(6, 'Code is incorrect')
    .required('Code is required'),
  price: Yup.object().shape({
    amount: transformedNumber.moreThan(0, 'Price can not be 0').required('Price should be specified'),
    currency: Yup.string()
      .min(3, 'Currency name is to short')
      .required('Currency is required!'),
  }),
  sku: Yup.string()
    .min(5, 'SKU is invalid')
    .max(15, 'SKU is invalid')
    .required('SKU is required'),
  barcode: Yup.string()
    .min(5, 'Barcode is invalid')
    .max(15, 'Barcode is invalid')
    .required('Barcode is required'),
  weigh: Yup.object().shape({
    amount: transformedNumber.moreThan(0).required('Weight is required'),
    unit: Yup.mixed<WeightUnit>()
      .oneOf([WeightUnit.Kg, WeightUnit.Lb])
      .required('Units is required'),
  }),
  inStock: transformedNumber.min(0, 'Can not be less than 0').required('In Stock is Required'),
  fulfillment: Yup.object().shape({
    id: Yup.string().required('id is required'),
    name: Yup.string().required('name is required'),
    type: Yup.mixed<FulfillmentType>()
      .oneOf([FulfillmentType.Shipping, FulfillmentType.Digital, FulfillmentType.Pickup])
      .required('Type is required'),
  }),
  admins: (roles: string[]) => {
    return Yup.array<{ email: string; roles: Role[]; fullName: string | null }>(
      Yup.object().shape({
        fullName: Yup.string()
          .min(3, 'FullName is to short')
          .required('FullName is required!'),
        email: Yup.string()
          .email('Email is incorrect!')
          .required('Email is required!'),
        roles: Yup.array().of(Yup.mixed().oneOf(roles, 'Role is not specified')),
      }),
    );
  },
};
