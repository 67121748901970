import React from 'react';
import { DateRangePicker, FocusedInputShape } from 'react-dates';
import { Card, FormGroup, CustomInput } from 'reactstrap';
import moment from 'moment';
import { FormikProps } from 'formik';
import { Box, CardHeader, CardContent } from '@material-ui/core';
import { ScutiInput } from 'components/material/ScutiInput';
import { CampaignForm, isPaused } from './CampaignForm.utils';

export const DurationDetails: React.FC<FormikProps<CampaignForm> & { showInventory?: boolean }> = ({
  touched,
  errors,
  values,
  handleChange,
  handleBlur,
  setFieldValue,
  showInventory = true,
}) => {
  const [focusedInput, setFocusedInput] = React.useState<FocusedInputShape | null>(null);
  const [timeSpecific, setTimeSpecific] = React.useState(!!values.duration.start || !!values.duration.end);
  const [inventorySpecific, setInventorySpecific] = React.useState(!!values.duration.inventory);

  const toggleTime = () => {
    setTimeSpecific(!timeSpecific);
    setFieldValue('duration', { ...values.duration, start: null, end: null }, true);
  };

  const toggleInventory = () => {
    setInventorySpecific(!inventorySpecific);
    setFieldValue('duration.inventory', 0, true);
  };

  const onDatesChange = React.useCallback(
    (v: { startDate: moment.Moment | null; endDate: moment.Moment | null }) => {
      setFieldValue(
        'duration',
        { start: v.startDate && v.startDate.toDate(), end: v.endDate && v.endDate.toDate() },
        true,
      );
    },
    [setFieldValue],
  );

  const onRunStockZeroChange = React.useCallback(() => {
    setFieldValue(`duration.runUntilStockZero`, !values.duration.runUntilStockZero, true);
  }, [setFieldValue, values.duration.runUntilStockZero]);

  return (
    <Card className="bg-custom">
      <CardHeader title="Duration" />
      <CardContent>
        <FormGroup className="pt-6">
          <label htmlFor={`time-box`}>
            <CustomInput
              id="time-box"
              type="checkbox"
              label="Date specific"
              onChange={toggleTime}
              checked={timeSpecific}
              disabled={!isPaused(values)}
            />
          </label>
          <DateRangePicker
            disabled={!timeSpecific || !isPaused(values)}
            startDateId="start"
            endDateId="end"
            startDate={values.duration.start ? moment(values.duration.start) : null}
            endDate={values.duration.end ? moment(values.duration.end) : null}
            onDatesChange={onDatesChange}
            focusedInput={focusedInput}
            onFocusChange={setFocusedInput}
          />
        </FormGroup>
        {showInventory && (
          <>
            {' '}
            <Box>
              <label htmlFor={`box-inventory`}>
                <CustomInput
                  id="box-inventory"
                  type="checkbox"
                  label="Inventory specific"
                  onChange={toggleInventory}
                  checked={inventorySpecific}
                  disabled={!isPaused(values)}
                />
              </label>
            </Box>
            <Box>
              <ScutiInput
                type="number"
                name={`duration.inventory`}
                placeholder="How much inventory do you have?"
                // @ts-ignore
                error={touched.duration?.inventory && errors[`duration.inventory`]}
                value={values.duration?.inventory || ''}
                onChange={handleChange}
                onBlur={handleBlur}
                inputProps={{
                  min: 0,
                }}
                disabled={!inventorySpecific || !isPaused(values)}
              />
            </Box>
            <Box mt={2}>
              <label htmlFor={`box-runUntilStockZero`}>
                <CustomInput
                  id="box-runUntilStockZero"
                  type="checkbox"
                  label="Run campaign until stock is zero."
                  onChange={onRunStockZeroChange}
                  checked={!!values.duration.runUntilStockZero}
                  disabled={!isPaused(values)}
                />
              </label>
            </Box>
          </>
        )}
      </CardContent>
    </Card>
  );
};
