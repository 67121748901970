import { DeepPartialBy } from 'types/util-types';
import { websiteRegEx } from 'utils/form-utils';
import * as Yup from 'yup';
import isPostalCode from 'validator/lib/isPostalCode';
import isMobilePhone from 'validator/lib/isMobilePhone';
import { OrganizationFullFragment } from '../__generated__/organization-edit-page.hooks';
import { taxIdValidator } from 'utils/tax-id-validator';

export type OrganizationForm = DeepPartialBy<OrganizationFullFragment, 'id'>;

export const organizationFormDefaults = (): OrganizationForm => {
  return {
    address1: '',
    address2: '',
    city: '',
    zipCode: '',
    country: '',
    state: null,
    name: '',
    website: null,
    category: '',
    identificationNumber: '',
    dbaName: '',
    phone: '',
    owner: { fullName: '', email: '' },
  };
};

export const validationSchema = Yup.object()
  .shape({
    owner: Yup.object()
      .shape({
        id: Yup.string(),
        fullName: Yup.string()
          .min(3, 'Name should be at least 3 characters.')
          .max(25, 'Name can be 25 characters or less.'),
        email: Yup.string()
          .email('Email is not correct')
          .required('Email is required.'),
      })
      .required(),
    name: Yup.string()
      .min(3, 'Name should be at least 3 characters.')
      .max(25, 'Name can be 25 characters or less.')
      .required('Name is required.'),
    businessWebsite: Yup.string()
      .matches(websiteRegEx, 'Website url is not correct')
      .nullable()
      .notRequired(),
    category: Yup.string()
      .min(2, 'Category is required.')
      .required('Category is required.'),
    identificationNumber: Yup.string()
      .test('identificationNumber', 'Number is not correct', function(value) {
        return taxIdValidator(value, this.parent.country);
      })
      .required('Number is required.'),
    dbaName: Yup.string()
      .min(3, 'Name should be at least 3 characters.')
      .max(25, 'Name can be 25 characters or less.')
      .transform(value => (value === null ? '' : value))
      .required('Name is required.'),
    phone: Yup.string()
      .test('phone', 'Phone is not correct', value => {
        return isMobilePhone(`${value}`.replace(/(\(|\)|\s|-)/g, ''), undefined, { strictMode: true });
      })
      .transform(value => (value === null ? '' : value))
      .required('Phone is required.'),
    address1: Yup.string()
      .min(3, 'Address should be at least 3 characters.')
      .max(25, 'Address can be 25 characters or less.')
      .required('Address is required.'),
    address2: Yup.string()
      .min(3, 'Address2 should be at least 3 characters.')
      .max(25, 'Address2 can be 25 characters or less.')
      .required(),
    country: Yup.string()
      .min(2, 'Country is required.')
      .required('Country is required.'),
    state: Yup.string()
      .transform(value => (value === null ? '' : value))
      .when('country', {
        is: val => val === 'US',
        then: Yup.string()
          .min(1, 'State is required')
          .required('State is required'),
        otherwise: Yup.string()
          .min(2, 'Province be at least 2 characters.')
          .required('Province is required'),
      }),
    zipCode: Yup.string()
      .test('zipCode', 'Zip code is not correct', function(value) {
        try {
          return isPostalCode(value, this.parent.country);
        } catch {}
        return true;
      })
      .required('Zip code is required.'),
    city: Yup.string()
      .min(2, 'City should be at least 3 characters.')
      .required('City is required.'),
  })
  .required();
