import { ChangeEventHandler, LegacyRef, useCallback, useRef } from 'react';
import { Box } from '@material-ui/core';
import styles from './ScutiImgInput.module.scss';
import { ImgListPreview } from './ImgListPreview';

interface Props {
  images: (string | File)[];
  defaultImage: string | File | null | undefined;
  onChange: (files: (string | File)[]) => void;
  onDefaultImageUpdate: (image: string | File) => void;
}

export const ScutiMultipleImgInput: React.FC<Props> = ({
  images,
  defaultImage,
  children,
  onChange,
  onDefaultImageUpdate,
}) => {
  const fileInputField: LegacyRef<HTMLInputElement> = useRef(null);

  const handleUploadClick = () => fileInputField.current?.click();

  const handleInputChange: ChangeEventHandler<HTMLInputElement> = event => {
    const { files } = event.target;
    if (files?.length) {
      onChange([...images, files[0]]);
    }
  };

  const handleRemove = useCallback(
    (image: string | File) => {
      onChange(images.filter(img => img !== image));
    },
    [images, onChange],
  );

  const showPreview = !!images.length;

  return (
    <Box width="100%" height="100%">
      {showPreview ? (
        <ImgListPreview
          images={images}
          defaultImage={defaultImage}
          onAdd={handleUploadClick}
          onDelete={handleRemove}
          onDefaultImageUpdate={onDefaultImageUpdate}
        />
      ) : (
        <Box height="100%" onClick={handleUploadClick}>
          {children}
        </Box>
      )}
      <input className={styles.file_input} type="file" ref={fileInputField} onChange={handleInputChange} />
    </Box>
  );
};
