interface Routes {
  [key: string]: string | (() => string);
}

export const getRoutesValues = (routes: Routes): string[] => {
  return Object.values(routes).map(r => ('function' === typeof r ? r() : r));
};

export enum appRoutes {
  LOGIN = '/login',
  REGISTRATION = '/registration',
  FORGOT_PASSWORD = '/forgot-password',
  RESET_PASSWORD = '/reset-password',
  CHECK_EMAIL = '/check-email',
  EMAIL_VERIFIED = '/email-verified',
  PASSWORD_CHANGED = '/password-changed',
  REGISTER_PHONE = '/register-phone',
  VERIFY_PHONE = '/verify-phone',
  DASHBOARD = '/dashboard-sales',
  GAMES = '/games',
  SHOPS = '/shops',
  ORDERS = '/orders',
  CAMPAIGNS = '/campaigns',
  AFFILIATE = '/affiliate',
  FINANCES = '/finances',
  USERS = '/users',
  SETTINGS = '/settings',
  HOME = '/',
  LOOKER_STUDIO = '/live',
}

export const dashboardRoutes = {
  HOME: '/',
  DASHBOARD: '/dashboard',
  SALES_DASHBOARD: '/dashboard-sales',
  DASHBOARD_CAMPAIGN: (campaignId: string = ':campaignId') => `/dashboard/campaign/${campaignId}`,
};

export const organizationsRoutes = {
  ORGANIZATIONS: '/organizations',
  ORGANIZATION_EDIT: (id: string = ':id') => `/organizations/${id}`,
  ORGANIZATION_OVERVIEW: (id: string = ':id') => `/organizations/${id}/overview`,
};

export const shopsRoutes = {
  SHOPS: '/shops',
  SHOP_NEW: '/shop/',
  SHOP_EDIT: (shopId: string = ':shopId') => `/shop-edit/${shopId}`,
  SHOP: '/shops/:shopId',
  SHOP_OVERVIEW: (shopId: string = ':shopId') => `/shops/${shopId}/overview`,
  SHOP_PRODUCTS: (shopId: string = ':shopId') => `/shops/${shopId}/products`,
  SHOP_PRODUCT: (shopId: string = ':shopId', productId: string = ':productId') =>
    `/shops/${shopId}/products/${productId}`,
  SHOP_PRODUCT_EDIT: (shopId: string = ':shopId', productId: string = ':productId') =>
    `/shops/${shopId}/product-edit/${productId}`,
  SHOP_PRODUCT_NEW: (shopId: string = ':shopId') => `/shops/${shopId}/product-new`,
  SHOP_ORDERS: (shopId: string = ':shopId') => `/shops/${shopId}/orders`,
  SHOP_CAMPAIGNS: (shopId: string = ':shopId') => `/shops/${shopId}/campaigns`,
  SHOP_USERS: (shopId: string = ':shopId') => `/shops/${shopId}/users`,
  SHOP_FINANCE: (shopId: string = ':shopId') => `/shops/${shopId}/finance`,
  SHOP_SETTINGS: (shopId: string = ':shopId') => `/shops/${shopId}/settings`,
};

export const productsRoutes = {
  PRODUCTS: '/products',
  PRODUCT_NEW: '/product',
  PRODUCT_OVERVIEW: (productId: string = ':productId') => `/products/${productId}`,
  PRODUCT_EDIT: (productId: string = ':productId') => `/product-edit/${productId}`,
  PRODUCTS_IMPORT: '/products-import',
};

export const productListingRoutes = {
  PRODUCT_LISTING: '/product-listings',
  PRODUCT_LISTING_NEW: '/product-listing',
  PRODUCT_LISTING_OVERVIEW: (listingId: string = ':listingId') => `/product-listings/${listingId}`,
  PRODUCT_LISTING_EDIT: (listingId: string = ':listingId') => `/product-listing/${listingId}`,
};

export const usersRoutes = {
  USERS: '/users',
  USER_NEW: '/user/',
  USER_EDIT: (userId: string = ':userId') => `/user-edit/${userId}`,
};

export const campaignRoutes = {
  CAMPAIGNS: '/campaigns',
  CAMPAIGN_NEW: () => `/campaign`,
  CAMPAIGN: (campaignId: string = ':campaignId') => `/campaigns/${campaignId}`,
  CAMPAIGN_EDIT: (campaignId: string = ':campaignId') => `/campaign-edit/${campaignId}`,
};

export const ordersRoutes = {
  ORDERS: '/orders',
  ORDER_OVERVIEW: (orderId: string = ':orderId') => `/orders/${orderId}/fulfillment`,
  ORDER_SUMMARY: (orderId: string = ':orderId') => `/orders/${orderId}/summary`,
  ORDER_OVERVIEW_REFUNDS: (orderId: string = ':orderId') => `/orders/${orderId}/refunds`,
};

export const gamesRoutes = {
  GAMES: '/games',
  GAME_NEW: '/game',
  GAME: (gameId: string = ':gameId') => `/games/${gameId}`,
  GAME_EDIT: (gameId: string = ':gameId') => `/game-edit/${gameId}`,
  GAME_OVERVIEW: (gameId: string = ':gameId') => `/games/${gameId}`,
};

export const financesRoutes = {
  FINANCES: '/finances',
  NEW_PAYMENT_METHOD: `/finances/payment-method-new`,
  PAYMENT_METHOD: (methodId: string = ':methodId') => `/finances/payment-method/${methodId}`,
  NEW_BANK_ACCOUNT: '/finances/bank-account-new',
  BANK_ACCOUNT: (accountId: string = ':accountId') => `/finances/bank-account/${accountId}`,
};

export const settingsRoutes = {
  SETTINGS: '/settings',
  ACCOUNT_SETTINGS: `/settings/account-settings`,
  BUSINESS_DETAILS: '/settings/business-details',
};
