import React from 'react';
import { Box, FormControl, FormHelperText, Grid, MenuItem, Select, SelectProps } from '@material-ui/core';
import { BadgeClose } from './BadgeClose';

interface Props extends Omit<SelectProps, 'error'> {
  title?: string;
  error?: React.ReactNode;
  values?: (string[] | string | number)[];
  options?: { value: string[] | string | number; label: React.ReactNode }[];
  onRemove: (value: string[] | string | number) => void;
}

export const ScutiMultipleSelect: React.FC<Props> = ({
  title,
  error,
  values = [],
  options = [],
  value,
  onRemove,
  ...props
}) => {
  const selected = options.filter(o => values.find(v => o.value === v));
  const notSelected = options.filter(o => !values.find(v => o.value === v));

  return (
    <FormControl fullWidth error={!!error}>
      {title && <label>{title}</label>}
      <Select fullWidth variant="outlined" {...props} error={!!error} value="">
        {notSelected.map((option, key) => (
          <MenuItem key={`${key}_${option.value}`} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText>{error}</FormHelperText>}
      <Box mt={1}>
        <Grid container spacing={1}>
          {selected.map(({ value, label }) => (
            <Grid item key={value.toString()}>
              <BadgeClose title={label} onClose={() => onRemove(value)} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </FormControl>
  );
};
