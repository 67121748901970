import { observable, action, computed } from 'mobx';
import * as Auth from '../api/auth-api';
import {
  LoginRequest,
  RegisterRequest,
  DecodedToken,
  PhoneRegisterRequest,
  PhoneVerifyRequest,
  UserInfo,
  InviteVerifyRequest,
  ForgotPasswordRequest,
  ResetPasswordRequest,
  ChangePasswordRequest,
  ChangeFullnameRequest,
} from '../types/auth-types';
import jwtDecode from 'jwt-decode';
import { tokenStorage } from 'utils/token-storage.utils';
import { Role } from 'types/__generated__/types';

const userInfoDefaults = {
  fullName: '',
  auth: null,
  email: null,
  emailVerified: false,
  phone: null,
  phoneVerified: false,
  twoFactorEnabled: false,
  roles: [],
};

const saveToLocalStorage = (userInfo: UserInfo) => {
  localStorage.setItem('user_info', JSON.stringify(userInfo));
};

const clearLocalStorage = () => {
  localStorage.removeItem('user_info');
};

const readFromLocalStorage = (): UserInfo => {
  const userInfo = localStorage.getItem('user_info');
  return userInfo ? JSON.parse(userInfo) : { ...userInfoDefaults };
};

const errorHandler = (message?: string[] | string): string | undefined => {
  if (!message) return;
  if (typeof message === 'string') {
    return message;
  } else {
    return message.reduce((acc: any, { constraints }: any) => {
      return `${acc}${Object.values(constraints).join(';\n')}`;
    }, '');
  }
};

export class AuthStore {
  @observable userInfo: UserInfo = { ...userInfoDefaults };
  @observable loginToken = '';
  @observable need2Fa = false;
  @observable instructionsSent = false;
  @observable error?: string;

  constructor() {
    Auth.API.interceptors.response.use(
      r => r,
      err => Promise.reject(err),
    );
    this.userInfo = readFromLocalStorage();
  }

  decodeToken = (token: string): DecodedToken => jwtDecode(token);

  twoFaNeeded = (token: string) => {
    const decodedToken: DecodedToken = jwtDecode(token);
    return !!decodedToken.roles.find(r => {
      return r === `ROLE_${Role.Login_2Fa}` || r === `ROLE_${Role.ChangePassword_2Fa}`;
    });
  };

  @computed
  get userAccessToken() {
    const { auth } = this.userInfo;
    return auth?.token || '';
  }

  @computed
  get userRefreshToken() {
    const { auth } = this.userInfo;
    return auth?.refreshToken || '';
  }

  applyUserInfo = (userInfo: UserInfo) => {
    this.userInfo = { ...userInfoDefaults, ...userInfo };
  };

  @action
  cleanError = () => {
    this.error = undefined;
  };

  getFreshToken = async () => {
    if (this.decodeToken(this.userAccessToken).exp * 1000 >= Date.now()) {
      return this.userAccessToken;
    }
    await this.refreshToken();
    return this.userAccessToken;
  };

  @action
  logIn = async (params: LoginRequest): Promise<void> => {
    try {
      const auth = await Auth.logIn(params);
      if (this.twoFaNeeded(auth.token)) {
        this.need2Fa = true;
        this.loginToken = auth.token;
        return this.applyUserInfo({ ...this.userInfo, auth });
      }
      const decodedToken = this.decodeToken(auth.token);
      const { roles } = decodedToken;
      const userInfo = await Auth.loadUserInfo(auth.token);
      saveToLocalStorage({ ...userInfo, auth, roles });
      this.applyUserInfo({ ...userInfo, auth, roles });
    } catch ({ response }) {
      clearLocalStorage();
      this.error = errorHandler(response?.data?.message);
    }
  };

  @action
  logInWith2FA = async (params: PhoneVerifyRequest) => {
    try {
      const accessToken = this.userInfo.auth && this.userInfo.auth.token;
      if (!accessToken) return;
      const auth = await Auth.logInWith2FA(params, accessToken);
      if (auth) {
        const userInfo = await Auth.loadUserInfo(auth.token);
        saveToLocalStorage({ ...userInfo, auth });
        this.applyUserInfo({ ...userInfo, auth });
      }
    } catch ({ response }) {
      this.error = errorHandler(response?.data?.message);
    }
  };

  @action
  resetWith2FA = async (params: PhoneVerifyRequest) => {
    try {
      const token = await Auth.resetWith2FA(params, this.loginToken);
      this.loginToken = token.token;
    } catch ({ response }) {
      this.error = errorHandler(response?.data?.message);
    }
  };

  @action
  logOut = async () => {
    this.userInfo = { ...userInfoDefaults };
    this.need2Fa = false;
    tokenStorage.clearStorage();
    localStorage.clear();
  };

  @action
  register = async (params: RegisterRequest) => {
    try {
      const userInfo = await Auth.register(params);
      saveToLocalStorage(userInfo);
      this.applyUserInfo(userInfo);
    } catch ({ response }) {
      this.error = errorHandler(response?.data?.message);
    }
  };

  @action
  inviteVerify = async (params: InviteVerifyRequest) => {
    try {
      const userInfo = await Auth.inviteVerify(params, this.loginToken);
      if (!userInfo.twoFactorEnabled && userInfo.email) {
        await this.logIn({ email: userInfo.email, password: params.password });
        return;
      }
      saveToLocalStorage(userInfo);
      this.applyUserInfo(userInfo);
    } catch ({ response }) {
      this.error = errorHandler(response?.data?.message);
    }
  };

  @action
  phoneRegister = async (params: PhoneRegisterRequest) => {
    try {
      const userInfo = await Auth.phoneRegister(params, this.loginToken);
      await Auth.sendSMS(this.loginToken);
      saveToLocalStorage(userInfo);
      this.applyUserInfo(userInfo);
    } catch ({ response }) {
      this.error = errorHandler(response?.data?.message);
    }
  };

  @action
  phoneVerify = async (params: PhoneVerifyRequest) => {
    try {
      const userInfo = await Auth.phoneVerify(params, this.loginToken);
      saveToLocalStorage(userInfo);
      this.applyUserInfo(userInfo);
    } catch ({ response }) {
      this.error = errorHandler(response?.data?.message);
    }
  };

  @action
  sendSms = async () => {
    try {
      if (this.loginToken) await Auth.sendSMS(this.loginToken);
    } catch ({ response }) {
      this.error = errorHandler(response?.data?.message);
    }
  };

  @action
  forgotPassword = async (params: ForgotPasswordRequest) => {
    try {
      await Auth.forgotPassword(params);
      this.instructionsSent = true;
    } catch ({ response }) {
      this.error = errorHandler(response?.data?.message);
    }
  };

  @action
  resetPassword = async (params: ResetPasswordRequest) => {
    try {
      await Auth.resetPassword(params, this.loginToken);
      this.instructionsSent = true;
    } catch ({ response }) {
      this.error = errorHandler(response?.data?.message);
    }
  };

  @action
  changePassword = async (params: ChangePasswordRequest) => {
    try {
      await Auth.changePassword(params, (await tokenStorage.getToken())!);
      this.instructionsSent = true;
    } catch ({ response }) {
      this.error = errorHandler(response?.data?.message);
    }
  };

  @action
  changeFullname = async (params: ChangeFullnameRequest) => {
    try {
      const userInfo = await Auth.changeFullname(params, (await tokenStorage.getToken())!);
      saveToLocalStorage({ ...this.userInfo, ...userInfo });
      this.applyUserInfo({ ...this.userInfo, ...userInfo });
      this.instructionsSent = true;
    } catch ({ response }) {
      this.error = errorHandler(response?.data?.message);
    }
  };

  @action
  saveLoginToken = async (token: string) => {
    this.loginToken = token;
  };

  @action
  refreshToken = async () => {
    try {
      const refreshed = await Auth.refreshToken(this.userRefreshToken);
      this.applyUserInfo({
        ...this.userInfo,
        auth: {
          refreshToken: this.userRefreshToken,
          token: refreshed.token,
        },
      });
      saveToLocalStorage(this.userInfo);
      this.instructionsSent = true;
    } catch ({ response }) {
      clearLocalStorage();
      this.error = errorHandler(response?.data?.message);
    }
  };

  @action
  acceptInvite = async (token: string) => {
    const userInfo = await Auth.acceptInvite(token);
    saveToLocalStorage({ ...userInfo });
    this.applyUserInfo({ ...userInfo });
  };
}
