import React, { useMemo, useReducer, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Grid } from '@material-ui/core';
import { TopNavigation } from 'components/material/TopNavigation';
import { ScutiInput } from 'components/material/ScutiInput';
import { productListingRoutes } from 'routing';
import { useToasts } from 'react-toast-notifications';
import { useAppStore } from 'store/app-store.hook';
import { useDebounce } from 'use-debounce/lib';
import { Link } from 'react-router-dom';
import { CampaignType, Filter_Type, Role } from 'types/__generated__/types';
import {
  ProductListingItemFragment,
  useDeleteProductListingMutation,
  useProductListingsQuery,
} from './__generated__/product-listing-list-page.hooks';
import { InfoPopup } from 'components/InfoPopup';
import { PaginationLayout } from 'layouts/PaginationLayout';
import { usePaging } from 'hooks/usePaging';
import { Button } from 'reactstrap';
import { Modal } from '@mui/material';
import { TodayDeals } from './TodayDeals';
import { useSetTodayDealsMutation } from './TodayDeals/__generated__/today-deals.hooks';
import { hasOneOfRoles } from 'utils/roles.utils';
import { useProductListingsTableColumns } from './useProductListingsTableColumns';
import { useSortBy, useTable } from 'react-table';
import { useSorting } from 'hooks/useSorting';
import { defaultSorting } from 'types/app-types';
import { ScutiTable } from 'components/ScutiTable';

export const ProductListingListPage: React.FC = observer(() => {
  const { selectedItem } = useAppStore().uiStore;
  const { addToast } = useToasts();
  const paging = usePaging();
  const [searchInput, setSearchInput] = React.useState<string>('');
  const [deleteId, setDeleteId] = React.useState<string | null>(null);
  const [inputValue] = useDebounce(searchInput, 500);
  const [open, toggleOpen] = useReducer(s => !s, false);
  const { userRoles } = useAppStore().permissions;
  const isAdmin = hasOneOfRoles(userRoles, [Role.ScutiAdmin]);
  const [productListings, setProductListings] = useState<ProductListingItemFragment[]>([]);
  const columns = useProductListingsTableColumns(setDeleteId);
  const tableInstance = useTable({ columns, data: productListings, manualSortBy: true }, useSortBy);
  const sorting = useSorting(tableInstance.state.sortBy, defaultSorting);

  const filters = useMemo(() => {
    const response = [
      {
        name: 'type',
        operator: Filter_Type.Eq,
        value: [CampaignType.ProductListing],
      },
    ];
    if (inputValue) {
      response.push({
        name: 'search',
        operator: Filter_Type.Like,
        value: [inputValue as any],
      });
    }
    return response;
  }, [inputValue]);

  const productListingsQuery = useProductListingsQuery(
    {
      id: selectedItem.id,
      paging,
      filters,
      sorting,
    },
    {
      keepPreviousData: true,
      onSuccess: ({ shopCampaigns }) => setProductListings(shopCampaigns.nodes),
    },
  );

  const deleteProductListing = useDeleteProductListingMutation();
  const useSetTodayDeals = useSetTodayDealsMutation();

  React.useEffect(() => {
    if (productListingsQuery.error)
      addToast(productListingsQuery.error.message, { appearance: 'error', autoDismiss: false });
  }, [addToast, productListingsQuery.error]);

  const onArchive = React.useCallback(async () => {
    if (deleteId) await deleteProductListing.mutateAsync({ id: deleteId });
    addToast('Product listing has been archived!', { appearance: 'success', autoDismiss: false });
    setDeleteId(null);
    productListingsQuery.refetch();
  }, [addToast, deleteId, deleteProductListing, productListingsQuery]);

  const onCancel = React.useCallback(() => setDeleteId(null), []);

  const isLoading = productListingsQuery.isLoading;
  const showProductListings = !!productListings.length && !productListingsQuery.isLoading;
  const pagingResponse = productListingsQuery.data?.shopCampaigns.paging;

  return (
    <>
      <PaginationLayout
        isLoading={isLoading}
        paging={pagingResponse}
        header={
          <TopNavigation title="Product Listing">
            <Grid container justifyContent="flex-end" alignItems="center" spacing={1}>
              <Grid item md={5}>
                <ScutiInput
                  placeholder="Search..."
                  value={searchInput}
                  onChange={({ target }) => setSearchInput(target.value)}
                />
              </Grid>
              {isAdmin && (
                <Grid item md={2}>
                  <Button className="btn btn-primary d-block w-100" onClick={toggleOpen}>
                    Today's deals
                  </Button>
                </Grid>
              )}
              <Grid item md={3}>
                <Link
                  className="btn btn-primary d-block"
                  to={`${productListingRoutes.PRODUCT_LISTING_NEW}?shopId=${selectedItem.id}`}
                >
                  Add Product Listing
                </Link>
              </Grid>
            </Grid>
          </TopNavigation>
        }
        content={
          showProductListings && (
            <Box>
              {!!deleteId && (
                <InfoPopup
                  title="Archive Product Listing?"
                  description="This Product Listing be archived. You’ll be able to access them under your archived listings."
                  proceedText="Archive"
                  cancelText="Cancel"
                  onProceed={onArchive}
                  onCancel={onCancel}
                />
              )}
              {<ScutiTable table={tableInstance} />}
            </Box>
          )
        }
      />
      <Modal open={open} onClose={toggleOpen}>
        <Box>
          <TodayDeals
            onCancel={toggleOpen}
            onSave={async ids => {
              await useSetTodayDeals.mutateAsync({ ids, shopId: selectedItem.id });
              toggleOpen();
            }}
          />
        </Box>
      </Modal>
    </>
  );
});
