import React from 'react';
import { Box, Card, CardHeader, CardContent } from '@material-ui/core';
import { ScutiSelect } from 'components/material/ScutiSelect';
import { FormikProps } from 'formik';
import { CAMPAIGN_BUDGET_SELECT_OPTIONS, PROMOTION_SELECT_OPTIONS } from 'types/select-types';
import { CustomInput, FormGroup } from 'reactstrap';
import { ScutiInput } from 'components/material/ScutiInput';
import { CampaignBudgetOption } from 'types/__generated__/types';
import { CampaignForm, isPaused } from './CampaignForm.utils';
import { useProductCategories } from 'hooks/useProductCategories';

export const BudgetDetails: React.FC<FormikProps<CampaignForm> & { showBudget?: boolean }> = props => {
  const { values, touched, errors, showBudget } = props;
  const [categorySpecific, setCategorySpecific] = React.useState(!!values.product?.promotion?.promotionType);
  const productCategoriesOptions = useProductCategories();

  const toggleCategory = () => {
    if (categorySpecific) props.setFieldValue('product.promotion', null, true);
    else props.setFieldValue('product.promotion.promotionType', PROMOTION_SELECT_OPTIONS[0].value, true);
    setCategorySpecific(!categorySpecific);
  };

  return (
    <Card>
      <CardHeader title="Budget" />
      <CardContent>
        {showBudget && (
          <Box>
            <ScutiSelect
              title="Option"
              name="budget.option"
              placeholder="Select type"
              value={values.budget?.option || ''}
              options={CAMPAIGN_BUDGET_SELECT_OPTIONS}
              onChange={({ target }) => {
                props.setFieldValue(
                  'budget',
                  {
                    ...values.budget,
                    option: target.value,
                  },
                  true,
                );
                props.setFieldValue('category', null, true);
              }}
              onBlur={props.handleBlur}
              disabled={!isPaused(values)}
            />
          </Box>
        )}
        {values.budget?.option === CampaignBudgetOption.Featured && (
          <Box mt={2}>
            <FormGroup>
              <label>
                <CustomInput
                  id="category-box"
                  type="checkbox"
                  label="Category"
                  onChange={toggleCategory}
                  checked={categorySpecific}
                  disabled={!isPaused(values)}
                />
              </label>
              <ScutiSelect
                title="Category"
                name="category"
                placeholder="Select category"
                value={values.category || ''}
                options={productCategoriesOptions}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                disabled={!categorySpecific || !isPaused(values)}
              />
            </FormGroup>
          </Box>
        )}
        <Box mt={2}>
          <ScutiInput
            type="number"
            title="Maximum spend *"
            name="budget.maxSpend"
            placeholder="The most you’ll spend"
            value={values.budget?.maxSpend || ''}
            // @ts-ignore
            error={touched.budget?.maxSpend && errors.budget?.maxSpend}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            disabled={!isPaused(values)}
          />
        </Box>
        <Box mt={2}>
          <ScutiInput
            type="number"
            title="Maximum daily spend *"
            name="budget.maxDailySpend"
            placeholder="The cap for how much you’ll spend in one day"
            value={values.budget?.maxDailySpend || ''}
            // @ts-ignore
            error={touched.budget?.maxDailySpend && errors.budget?.maxDailySpend}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            disabled={!isPaused(values)}
          />
        </Box>
        <Box mt={2}>
          <ScutiInput
            type="number"
            title="Limit of repeat impressions *"
            name="budget.limitOfImpressions"
            placeholder="6 is the minimum"
            value={values.budget?.limitOfImpressions || ''}
            // @ts-ignore
            error={touched.budget?.limitOfImpressions && errors.budget?.limitOfImpressions}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            disabled={!isPaused(values)}
          />
        </Box>
      </CardContent>
    </Card>
  );
};
