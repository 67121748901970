import { CampaignStatus, CampaignType } from 'types/__generated__/types';
import { CampaignFullFragment } from '../__generated__/campaign-edit-page.hooks';

export interface CampaignForm extends Omit<CampaignFullFragment, 'id' | 'status' | 'media'> {
  id?: string;
  status?: CampaignStatus;
  media?: {
    banner?: string | File | null;
    vertical?: string | null;
    verticalFile?: File | null;
    tile?: string | null;
    tileFile?: File | null;
  } | null;
  appDownloadIds?: {
    androidId?: string | null;
    appleId?: number | null;
    pcLink?: string | null;
  };
  type: CampaignType.Product | CampaignType.Video | CampaignType.Banner | CampaignType.AppDownload;
}

export const isPaused = (form: CampaignForm) => !form.status || form.status === CampaignStatus.Paused;
