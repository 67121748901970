import React from 'react';
import { Skeleton } from '@mui/material';
import { FormControl, FormHelperText, MenuItem, Select, SelectProps } from '@material-ui/core';

interface Props extends Omit<SelectProps, 'error'> {
  title?: string;
  error?: React.ReactNode;
  options?: { value: string[] | string | number | null; label: React.ReactNode }[];
  isLoading?: boolean;
}

export const ScutiSelect: React.FC<Props> = ({ title, error, options = [], isLoading, ...props }) => {
  return (
    <FormControl fullWidth error={!!error}>
      {title && <label>{title}</label>}
      {isLoading ? (
        <Skeleton height={58} />
      ) : (
        <Select fullWidth variant="outlined" {...props} error={!!error}>
          {options.map((option, key) => (
            <MenuItem key={`${key}_${option.value}`} value={option.value || undefined}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      )}
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};
