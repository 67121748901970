import { GameItemFragment } from '../__generated__/games-list-page.hooks';
import { useMemo } from 'react';
import { Column } from 'react-table';
import { Link } from 'react-router-dom';
import { gamesRoutes } from 'routing';
import noPicUrl from 'assets/svgs/no-pic-sm.svg';
import { contentCategoryName } from 'types/app-types';

export function useGamesTableColumns(onDelete: (id: string) => void): Column<GameItemFragment>[] {
  return useMemo<Column<GameItemFragment>[]>(
    () => [
      {
        Header: '',
        id: 'thumbnail',
        width: '6%',
        Cell: ({ row }: any) => {
          const { thumbnail } = row.original;
          return (
            <div className="variant-pic">
              <img src={thumbnail || noPicUrl} alt="" />
            </div>
          );
        },
      },
      {
        Header: 'Name',
        accessor: 'name',
        Cell: ({ row }: any) => {
          return <Link to={gamesRoutes.GAME(row.original.id)}>{row.original.name}</Link>;
        },
      },
      {
        Header: 'Category',
        accessor: 'category',
        Cell: ({ row }: any) => {
          const { category } = row.original;
          return <span>{category}</span>;
        },
      },
      {
        Header: 'Content Category',
        accessor: 'contentCategory',
        Cell: ({ row }: any) => {
          const { contentCategory } = row.original;
          return <span>{contentCategoryName[contentCategory]}</span>;
        },
      },
      {
        Header: 'Admins',
        id: 'admins',
        disableSortBy: true,
        accessor: row => row.admins.map(({ fullName }) => fullName).join(', '),
      },
      {
        Header: '',
        id: 'delete',
        width: '6%',
        disableSortBy: true,
        Cell: ({ row }: any) => {
          return (
            <div className="row-actions">
              <Link to={gamesRoutes.GAME_EDIT(row.original.id)} className="link-edit" title="Edit">
                Edit
              </Link>
              <span className="link-delete" title="Delete" onClick={() => onDelete(row.original.id)}>
                Delete
              </span>
            </div>
          );
        },
      },
    ],
    [onDelete],
  );
}
