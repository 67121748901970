import React from 'react';
import { BUSINESS_SELECT_OPTIONS } from 'types/select-types';
import { Box, Card, CardContent, CardHeader } from '@material-ui/core';
import { ScutiInput } from 'components/material/ScutiInput';
import { ScutiSelect } from 'components/material/ScutiSelect';
import { FormikProps } from 'formik';
import { OrganizationForm } from './OrganizationEditForm.utils';
import MuiPhoneNumber from 'material-ui-phone-number';
import { hasId } from 'types/util-types';

export const OrganizationBusinessDetails: React.FC<FormikProps<OrganizationForm>> = props => {
  const { values, touched, errors, handleChange, handleBlur } = props;
  const isExist = hasId(values);
  return (
    <Card>
      <CardHeader title="Business Details" />
      <CardContent>
        <Box>
          <ScutiInput
            title="Business name *"
            name="name"
            placeholder="Business name"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.name || ''}
            error={(isExist || touched.name) && errors?.name}
          />
        </Box>
        <Box mt={2}>
          <ScutiInput
            title="Website"
            name="website"
            placeholder="Website"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.website || ''}
            error={(isExist || touched.website) && errors?.website}
          />
        </Box>
        <Box mt={2}>
          <ScutiSelect
            title="Category *"
            name="category"
            placeholder="Category"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.category || ''}
            error={(isExist || touched.category) && errors?.category}
            options={BUSINESS_SELECT_OPTIONS}
          />
        </Box>
        <Box mt={2}>
          <ScutiInput
            title="Tax ID *"
            name="identificationNumber"
            placeholder="XXX-XX-XXXX"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.identificationNumber || ''}
            error={(isExist || touched.identificationNumber) && errors?.identificationNumber}
          />
        </Box>
        <Box mt={2}>
          <ScutiInput
            title="DBA Name *"
            name="dbaName"
            placeholder="DBA"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.dbaName || ''}
            error={(isExist || touched.dbaName) && errors?.dbaName}
          />
        </Box>
        <Box mt={2}>
          <label>Phone Number *</label>
          <MuiPhoneNumber
            fullWidth
            variant="outlined"
            size="small"
            defaultCountry="us"
            disableAreaCodes
            name="phone"
            placeholder="XXX-XXX-XXXX"
            onChange={phone => props.setFieldValue('phone', phone, true)}
            onBlur={handleBlur}
            value={values.phone || ''}
            error={!!(isExist || touched.phone) && !!errors?.phone}
            helperText={(isExist || touched.phone) && errors?.phone}
          />
        </Box>
      </CardContent>
    </Card>
  );
};
