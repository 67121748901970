import axios from 'axios';
import { API_ANALYTICS_URL } from '../config';
import { tokenStorage } from 'utils/token-storage.utils';

const API = axios.create({
  baseURL: API_ANALYTICS_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

interface Params {
  date_from: string;
  date_to: string;
  id: string;
  group_by?: 'day' | 'week' | 'month' | 'year' | 'hour';
}

type MetricType = 'shop' | 'game';

export const analyticsFetcher = (metricType: MetricType) => <TData>(metricName: string, params: Params) => {
  const { date_from, date_to, id, group_by } = params;
  return async (): Promise<TData> => {
    const token = await tokenStorage.getToken();
    const { data } = await API.get(
      `${API_ANALYTICS_URL}/api/${metricType}/metrics/${metricName}?date_from=${date_from}&date_to=${date_to}&${metricType}_id=${id}${
        group_by ? `&group_by=${group_by}` : ''
      }`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    );

    return data;
  };
};
