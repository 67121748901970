import { CampaignStatus, OfferType, PromotionType } from 'types/__generated__/types';
import { FormValidationSchema, transformedNumber } from 'utils/form-utils';
import { ProductListingFullFragment } from '../__generated__/product-listing-edit-page.hooks';
import * as Yup from 'yup';

export interface ProductListingForm extends Omit<ProductListingFullFragment, 'id' | 'budget' | 'reward' | 'status'> {
  id?: string;
  status?: CampaignStatus;
  reward: {
    scutiPercentage: number;
  };
}

export const productListingFormDefaults = (): ProductListingForm => ({
  name: '',
  category: '',
  product: {
    product: {
      id: '',
      name: '',
    },
  },
  demographic: {
    gender: null,
    minAge: 0,
    maxAge: 100,
    contentCategory: null,
  },
  duration: {
    start: null,
    end: null,
    inventory: null,
    runUntilStockZero: null,
  },
  location: {},
  reward: {
    scutiPercentage: 0,
  },
  extras: { moreExposure: false },
});

export const isPaused = (form: ProductListingForm) =>
  !form.status || form.status === CampaignStatus.Paused || form.status === CampaignStatus.Pending;

export const validationSchema = (): FormValidationSchema<ProductListingForm> => {
  return Yup.object().shape({
    name: Yup.string()
      .min(3, 'Name should be at least 3 characters.')
      .max(25, 'Name can be 25 characters or less.')
      .required('Name is required!'),
    category: Yup.string()
      .min(2, 'Category is required!')
      .required('Category is required!'),
    product: Yup.object()
      .shape({
        product: Yup.object()
          .shape({
            id: Yup.string()
              .min(2, 'Product is required')
              .required('Product is required'),
            name: Yup.string(),
          })
          .required('Product is required'),
        promotion: Yup.object()
          .shape({
            promotionType: Yup.mixed()
              .oneOf([...Object.values(PromotionType), null])
              .nullable(),
            offerType: Yup.mixed().when('promotionType', {
              is: PromotionType.SpecialOffer,
              then: Yup.mixed()
                .oneOf(Object.values(OfferType), 'Please specify Offer Type')
                .required('Offer type is required'),
              otherwise: Yup.string().nullable(),
            }),
            offerCopy: Yup.string().when('offerType', {
              is: value => !!value,
              then: Yup.string()
                .nullable()
                .required('Offer Copy is required'),
              otherwise: Yup.string().nullable(),
            }),
            discount: Yup.number()
              .when('promotionType', {
                is: PromotionType.HotPrice,
                then: transformedNumber
                  .moreThan(0, 'Cannot be less then 0')
                  .max(100, 'Cannot be greater then 100')
                  .required('Discount % is required'),
                otherwise: Yup.number().nullable(),
              })
              .nullable(),
          })
          .nullable(),
      })
      .nullable()
      .required('Product is required!'),
    demographic: Yup.object().shape({
      gender: Yup.string().nullable(),
      minAge: Yup.number()
        .min(0)
        .max(100)
        .required(),
      maxAge: Yup.number()
        .min(0)
        .max(100)
        .required(),
    }),
    duration: Yup.object().shape({
      start: Yup.date().nullable(),
      end: Yup.date().nullable(),
      runUntilStockZero: Yup.boolean().nullable(),
      inventory: Yup.number().nullable(),
    }),
    location: Yup.object()
      .shape({
        country: Yup.string().nullable(),
        states: Yup.array()
          .of(Yup.string())
          .nullable(),
      })
      .nullable(),

    reward: Yup.object()
      .shape({
        scutiPercentage: transformedNumber
          .moreThan(0, 'Cannot be less then 0')
          .max(100, 'Cannot be greater then 100')
          .required('Reward % is required'),
      })
      .required(),
    extras: Yup.object().shape({
      moreExposure: Yup.boolean(),
    }),
  });
};
