import React from 'react';
import { Box, Card, CardHeader, CardContent } from '@material-ui/core';
import { FormikProps } from 'formik';
import queryString from 'query-string';
import { useLocation } from 'react-router';
import { ScutiAutocomplete } from 'components/material/ScutiAutoComplete';
import { useDebounce } from 'use-debounce/lib';
import { Filter_Type } from 'types/__generated__/types';
import { useCampaignProductsQuery } from '../__generated__/campaign-edit-page.hooks';
import { CampaignForm } from './CampaignForm.utils';

export const ProductDetails: React.FC<FormikProps<CampaignForm>> = props => {
  const { values, touched, errors } = props;
  const { search } = useLocation();
  const { shopId } = queryString.parse(search);
  const [searchInput, setSearchInput] = React.useState<string>('');
  const [inputValue] = useDebounce(searchInput, 500);
  const campaignProductsQuery = useCampaignProductsQuery(
    {
      id: shopId as string,
      filters: [
        {
          name: 'type',
          operator: Filter_Type.Eq,
          value: ['PRODUCT_LISTING'],
        },
        {
          name: 'name',
          operator: Filter_Type.Contains,
          value: [inputValue],
        },
      ],
    },
    { keepPreviousData: true },
  );

  const products =
    campaignProductsQuery.data?.shopCampaigns.nodes?.reduce(
      (acc: { id: string; name: string; listingId: string }[], { id, product }) => {
        return product?.product ? [...acc, { ...product?.product, listingId: id }] : acc;
      },
      [],
    ) || [];

  const productOptions = products.map(p => ({ value: [p.id, p.listingId], label: p.name }));
  const selectedProduct = values.product?.product || { id: '', name: '' };

  return (
    <Card>
      <CardHeader title="Product" />
      <CardContent>
        <Box>
          <ScutiAutocomplete
            placeholder="Select Product"
            title="Product *"
            getOptionLabel={option => option.label}
            value={productOptions.find(({ value }) => value[0] === selectedProduct.id) || null}
            options={productOptions}
            onInputChange={(_, value, reason) => {
              if ('input' === reason) setSearchInput(value);
            }}
            onChange={(event, value, reason) => {
              if ('selectOption' === reason && !!value) {
                const [id, productListingId] = value.value;
                props.setFieldValue('product.product', { id: id, name: value.label }, true);
                props.setFieldValue('productListingId', productListingId, true);
              }
              if ('clear' === reason || !value) {
                props.setFieldValue('product.product', { id: '', name: '' }, true);
              }
            }}
            onBlur={() => props.setFieldTouched('product.product.id', true, true)}
            // @ts-ignore
            error={touched.product?.product?.id && errors['product.product.id']}
            disabled={!!values.id}
          />
        </Box>
      </CardContent>
    </Card>
  );
};
