import React from 'react';
import { Box, Card, CardContent, CardHeader } from '@material-ui/core';
import { FormikProps } from 'formik';
import { brandDefaults, BrandsForm } from './ShopBrandsEditForm.utils';
import TagsInput from 'react-tagsinput';

interface Props {
  existedBrands?: BrandsForm['brands'];
  formik: FormikProps<BrandsForm>;
}

export const ShopBrandsEditForm: React.FC<Props> = ({ formik, existedBrands = [] }) => {
  const { brands } = formik.values;

  const onBrandsChange = React.useCallback(
    (_: string[], changed: string[], changedIndexes: number[]) => {
      const changedName = changed[0];

      if (brands.length === changedIndexes[0]) {
        formik.setFieldValue('brands', [...brands, brandDefaults(changedName)], true);
      } else {
        formik.setFieldValue(
          'brands',
          brands.filter(({ name }) => name !== changedName),
        );
      }
    },
    [brands, formik],
  );

  return (
    <Card>
      <CardHeader title="Brands" />
      <CardContent>
        <Box className="tags-control">
          <TagsInput
            value={brands.map(b => b.name)}
            addKeys={[',', 'Enter']}
            onChange={onBrandsChange}
            onlyUnique
            inputProps={{
              placeholder: 'Add brand',
            }}
          />
        </Box>
      </CardContent>
    </Card>
  );
};
