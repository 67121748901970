import { OrganizationItemFragment } from './__generated__/organizations-page.hooks';
import { useMemo } from 'react';
import { Column } from 'react-table';
import { Link } from 'react-router-dom';
import { organizationsRoutes } from 'routing';

export function useOrganizationsTableColumns(): Column<OrganizationItemFragment>[] {
  return useMemo<Column<OrganizationItemFragment>[]>(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        Cell: ({ row }: any) => (
          <Link to={organizationsRoutes.ORGANIZATION_OVERVIEW(row.original.id)}>{row.original.name}</Link>
        ),
      },
      {
        Header: 'Owner',
        id: 'owner',
        disableSortBy: true,
        accessor: row => row.owner.fullName,
      },
      {
        Header: 'Website',
        id: 'website',
        accessor: 'website',
      },
      {
        Header: 'Category',
        id: 'category',
        accessor: 'category',
      },
      {
        Header: '',
        id: 'delete',
        width: '6%',
        disableSortBy: true,
        Cell: ({ row }: any) => {
          return (
            <div className="row-actions">
              <Link className="link-edit" title="Edit" to={organizationsRoutes.ORGANIZATION_EDIT(row.original.id)}>
                Edit
              </Link>
            </div>
          );
        },
      },
    ],
    [],
  );
}
