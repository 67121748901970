export const scutiColors = {
  borderMain: '#404249',
  borderFocused: 'rgba(255, 255, 255, 0.5)',
  inputBackgroundDisabled: 'rgba(0,0,0, 0.1)',
  textMain: '#FFFFFF',
  textError: '#f86c6b',
  menuBackground: '#1f2129',
  menuItemBackgroundHover: '#2a2c34',
  cardBackground: 'rgb(42, 44, 52)',
  cardHeaderBackground: 'rgb(31, 33, 41)',
};

export const scutiSizes = {
  inputFontSize: 14,
  inputPadding: 8,
  borderRadius: 4,
};
