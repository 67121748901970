import React from 'react';
import { FormControl, TextField, TextFieldProps } from '@material-ui/core';

interface Props extends Omit<TextFieldProps, 'error' | 'helperText'> {
  title?: string;
  error?: React.ReactNode;
  hideHelper?: boolean;
}

export const ScutiInput: React.FC<Props> = ({ title, error, hideHelper, ...props }) => {
  return (
    <FormControl fullWidth>
      {title && <label style={{ opacity: props.disabled ? 0.4 : 1 }}>{title}</label>}
      <TextField
        fullWidth
        size="small"
        variant="outlined"
        error={!!error}
        helperText={hideHelper ? undefined : error}
        {...props}
      />
    </FormControl>
  );
};
