import React from 'react';
import { FormikProps } from 'formik';
import { isPaused, ProductListingForm } from './ProductListingEditForm.utils';
import { Card, CardHeader, CardContent, FormGroup, Box } from '@material-ui/core';
import { CustomInput } from 'reactstrap';

export const EnhancementsDetails: React.FC<FormikProps<ProductListingForm>> = props => {
  const { values } = props;
  const onExtrasChange = () => {
    props.setFieldValue(`extras.moreExposure`, !values.extras.moreExposure, true);
  };
  return (
    <Card>
      <CardHeader title="Enhancements" />
      <CardContent>
        <FormGroup>
          <label htmlFor={`extras-box`}>
            <CustomInput
              id="extras-box"
              type="checkbox"
              name="extras.moreExposure"
              label="More exposure"
              onChange={onExtrasChange}
              onBlur={props.handleBlur}
              checked={!!values.extras.moreExposure}
              disabled={!isPaused(values)}
            />
          </label>
          <Box color="text.secondary">Grab more attention by adding a glowing border to your product.</Box>
        </FormGroup>
      </CardContent>
    </Card>
  );
};
