import React from 'react';
import { FormikProps } from 'formik';
import { isPaused, ProductListingForm } from './ProductListingEditForm.utils';
import { Card, CardHeader, CardContent } from '@material-ui/core';
import { ScutiInput } from 'components/material/ScutiInput';

export const RewardsDetails: React.FC<FormikProps<ProductListingForm>> = props => {
  const { values, touched, errors } = props;
  return (
    <Card>
      <CardHeader title="Rewards" />
      <CardContent>
        <ScutiInput
          title="Scuti rewards percentage *"
          name="reward.scutiPercentage"
          placeholder="%"
          onChange={props.handleChange}
          onBlur={props.handleBlur}
          type="number"
          // @ts-ignore
          error={touched?.reward?.scutiPercentage && errors['reward.scutiPercentage']}
          value={values.reward?.scutiPercentage || ''}
          disabled={!isPaused(values)}
        />
      </CardContent>
    </Card>
  );
};
