import React, { useMemo } from 'react';
import { Box, Card, CardContent, CardHeader, Typography } from '@material-ui/core';
import { FormikProps } from 'formik';
import { ProductForm } from './ProductEditForm.utils';
import { ScutiMultipleImgInput } from 'components/material/ScutiMultipleImgInput';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';

export const ProductImages: React.FC<FormikProps<ProductForm>> = ({ values, errors, touched, setFieldValue }) => {
  const modifiedImgs = useMemo(() => {
    // @ts-ignore
    const modImgs = [...new Set(values.images)] || [];

    // This moves the default image to the beginning of the images array
    if (values.defaultImage) {
      const defaultIndex = modImgs.indexOf(values.defaultImage);
      modImgs.unshift(modImgs.splice(defaultIndex, 1)[0]);
    }

    return modImgs;
  }, [values.defaultImage, values.images]);

  return (
    <Card>
      <CardHeader title="Images" />
      <CardContent>
        <ScutiMultipleImgInput
          images={modifiedImgs}
          onChange={images => setFieldValue('images', images, true)}
          defaultImage={values.defaultImage}
          onDefaultImageUpdate={image => {
            setFieldValue('defaultImage', image, true);
          }}
        >
          <Box
            mt={10}
            width="100%"
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
          >
            <PhotoLibraryIcon style={{ width: '12rem', height: '7.5rem' }} width="100%" color="secondary" />
            <Box fontWeight="bold" width="70%" textAlign="center">
              <Typography variant="body1" color="secondary">
                Upload a 520x520px or 1:1 aspect ratio images for your products.
              </Typography>
            </Box>
          </Box>
        </ScutiMultipleImgInput>
        <Box>
          <Typography color="error">{errors.images}</Typography>
        </Box>
      </CardContent>
    </Card>
  );
};
