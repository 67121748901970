import * as Types from '../../../../types/__generated__/types';

import { ApolloError } from 'apollo-client';
import { useQuery, UseQueryOptions, useMutation, UseMutationOptions } from 'react-query';
import { fetcher } from 'api';
export type CampaignFullFragment = {
  id: string;
  name: string;
  category?: Types.Maybe<string>;
  type: Types.CampaignType;
  status: Types.CampaignStatus;
  productListingId?: Types.Maybe<string>;
  shop?: Types.Maybe<{ id: string; name: string }>;
  product?: Types.Maybe<{
    product: { id: string; name: string };
    promotion?: Types.Maybe<{
      promotionType?: Types.Maybe<Types.PromotionType>;
      offerType?: Types.Maybe<Types.OfferType>;
      offerCopy?: Types.Maybe<string>;
      discount?: Types.Maybe<number>;
    }>;
  }>;
  media?: Types.Maybe<{ banner?: Types.Maybe<string>; vertical?: Types.Maybe<string>; tile?: Types.Maybe<string> }>;
  video?: Types.Maybe<{ videoUrl: string }>;
  demographic: {
    gender?: Types.Maybe<string>;
    minAge: number;
    maxAge: number;
    contentCategory?: Types.Maybe<Types.ContentCategory>;
  };
  duration: {
    start?: Types.Maybe<any>;
    end?: Types.Maybe<any>;
    inventory?: Types.Maybe<number>;
    runUntilStockZero?: Types.Maybe<boolean>;
  };
  budget?: Types.Maybe<{
    option?: Types.Maybe<Types.CampaignBudgetOption>;
    maxSpend: number;
    maxDailySpend: number;
    limitOfImpressions: number;
  }>;
  game?: Types.Maybe<{
    gameGenres?: Types.Maybe<Array<string>>;
    games?: Types.Maybe<Array<{ id: string; name: string }>>;
  }>;
  location?: Types.Maybe<{ country?: Types.Maybe<string>; states?: Types.Maybe<Array<string>> }>;
  reward?: Types.Maybe<{ scutiPercentage: number }>;
  extras: { moreExposure?: Types.Maybe<boolean> };
  appDownloadIds?: Types.Maybe<{
    appleId?: Types.Maybe<number>;
    androidId?: Types.Maybe<string>;
    pcLink?: Types.Maybe<string>;
  }>;
};

export type CampaignQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;

export type CampaignQuery = { campaign: CampaignFullFragment };

export type CreateCampaignMutationVariables = Types.Exact<{
  input: Types.NewCampaignInput;
}>;

export type CreateCampaignMutation = { createCampaign: CampaignFullFragment };

export type UpdateCampaignMutationVariables = Types.Exact<{
  input: Types.UpdateCampaignInput;
}>;

export type UpdateCampaignMutation = { updateCampaign: CampaignFullFragment };

export type CampaignProductsQueryVariables = Types.Exact<{
  id: Types.Scalars['ID'];
  filters?: Types.Maybe<Array<Types.FilterInput> | Types.FilterInput>;
}>;

export type CampaignProductsQuery = {
  shopCampaigns: { nodes: Array<{ id: string; product?: Types.Maybe<{ product: { id: string; name: string } }> }> };
};

export type ToggleCampaignMutationVariables = Types.Exact<{
  input: Types.ToggleCampaignInput;
}>;

export type ToggleCampaignMutation = { toggleCampaign: { id: string } };

export const CampaignFullFragmentDoc = `
    fragment CampaignFull on Campaign {
  id
  name
  category
  shop {
    id
    name
  }
  type
  status
  product {
    product {
      id
      name
    }
    promotion {
      promotionType
      offerType
      offerCopy
      discount
    }
  }
  media {
    banner
    vertical
    tile
  }
  video {
    videoUrl
  }
  demographic {
    gender
    minAge
    maxAge
    contentCategory
  }
  duration {
    start
    end
    inventory
    runUntilStockZero
  }
  productListingId
  budget {
    option
    maxSpend
    maxDailySpend
    limitOfImpressions
  }
  game {
    games {
      id
      name
    }
    gameGenres
  }
  location {
    country
    states
  }
  reward {
    scutiPercentage
  }
  extras {
    moreExposure
  }
  appDownloadIds {
    appleId
    androidId
    pcLink
  }
}
    `;
export const CampaignDocument = `
    query Campaign($id: String!) {
  campaign(id: $id) {
    ...CampaignFull
  }
}
    ${CampaignFullFragmentDoc}`;
export const useCampaignQuery = <TData = CampaignQuery, TError = ApolloError>(
  variables: CampaignQueryVariables,
  options?: UseQueryOptions<CampaignQuery, TError, TData>,
) =>
  useQuery<CampaignQuery, TError, TData>(
    ['Campaign', variables],
    fetcher<CampaignQuery, CampaignQueryVariables>(CampaignDocument, variables),
    options,
  );
export const CreateCampaignDocument = `
    mutation createCampaign($input: NewCampaignInput!) {
  createCampaign(input: $input) {
    ...CampaignFull
  }
}
    ${CampaignFullFragmentDoc}`;
export const useCreateCampaignMutation = <TError = ApolloError, TContext = unknown>(
  options?: UseMutationOptions<CreateCampaignMutation, TError, CreateCampaignMutationVariables, TContext>,
) =>
  useMutation<CreateCampaignMutation, TError, CreateCampaignMutationVariables, TContext>(
    (variables?: CreateCampaignMutationVariables) =>
      fetcher<CreateCampaignMutation, CreateCampaignMutationVariables>(CreateCampaignDocument, variables)(),
    options,
  );
export const UpdateCampaignDocument = `
    mutation updateCampaign($input: UpdateCampaignInput!) {
  updateCampaign(input: $input) {
    ...CampaignFull
  }
}
    ${CampaignFullFragmentDoc}`;
export const useUpdateCampaignMutation = <TError = ApolloError, TContext = unknown>(
  options?: UseMutationOptions<UpdateCampaignMutation, TError, UpdateCampaignMutationVariables, TContext>,
) =>
  useMutation<UpdateCampaignMutation, TError, UpdateCampaignMutationVariables, TContext>(
    (variables?: UpdateCampaignMutationVariables) =>
      fetcher<UpdateCampaignMutation, UpdateCampaignMutationVariables>(UpdateCampaignDocument, variables)(),
    options,
  );
export const CampaignProductsDocument = `
    query CampaignProducts($id: ID!, $filters: [FilterInput!]) {
  shopCampaigns(id: $id, filters: $filters) {
    nodes {
      id
      product {
        product {
          id
          name
        }
      }
    }
  }
}
    `;
export const useCampaignProductsQuery = <TData = CampaignProductsQuery, TError = ApolloError>(
  variables: CampaignProductsQueryVariables,
  options?: UseQueryOptions<CampaignProductsQuery, TError, TData>,
) =>
  useQuery<CampaignProductsQuery, TError, TData>(
    ['CampaignProducts', variables],
    fetcher<CampaignProductsQuery, CampaignProductsQueryVariables>(CampaignProductsDocument, variables),
    options,
  );
export const ToggleCampaignDocument = `
    mutation ToggleCampaign($input: ToggleCampaignInput!) {
  toggleCampaign(input: $input) {
    id
  }
}
    `;
export const useToggleCampaignMutation = <TError = ApolloError, TContext = unknown>(
  options?: UseMutationOptions<ToggleCampaignMutation, TError, ToggleCampaignMutationVariables, TContext>,
) =>
  useMutation<ToggleCampaignMutation, TError, ToggleCampaignMutationVariables, TContext>(
    (variables?: ToggleCampaignMutationVariables) =>
      fetcher<ToggleCampaignMutation, ToggleCampaignMutationVariables>(ToggleCampaignDocument, variables)(),
    options,
  );
