import * as Types from '../../../../types/__generated__/types';

import { ApolloError } from 'apollo-client';
import { useQuery, UseQueryOptions, useMutation, UseMutationOptions } from 'react-query';
import { fetcher } from 'api';
export type ShopFullFragment = {
  id: string;
  name: string;
  description?: Types.Maybe<string>;
  shopifyName?: Types.Maybe<string>;
  flatShippingRate: number;
  freeShippingThreshold?: Types.Maybe<number>;
  returnPolicy: string;
  thumbnail?: Types.Maybe<string>;
  admins: Array<{ fullName?: Types.Maybe<string>; email: string; roles: Array<Types.Role> }>;
};

export type ShopQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;

export type ShopQuery = { shop?: Types.Maybe<ShopFullFragment> };

export type UpdateShopMutationVariables = Types.Exact<{
  input: Types.UpdateShopInput;
}>;

export type UpdateShopMutation = { updateShop?: Types.Maybe<{ id: string }> };

export type CreateShopMutationVariables = Types.Exact<{
  input: Types.ShopInput;
}>;

export type CreateShopMutation = { createShop: { id: string } };

export type ShopInviteFragment = {
  id: string;
  createdAt: any;
  updatedAt: any;
  email: string;
  roles: Array<Types.Role>;
  entityId?: Types.Maybe<string>;
  entityType?: Types.Maybe<string>;
  accepted: boolean;
  hide: boolean;
};

export type ShopInvitesQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
  paging?: Types.Maybe<Types.PagingInput>;
  sorting?: Types.Maybe<Array<Types.SortFieldInput> | Types.SortFieldInput>;
  filters?: Types.Maybe<Array<Types.FilterInput> | Types.FilterInput>;
}>;

export type ShopInvitesQuery = {
  shopInvites: { paging: { offset: number; limit: number; totalCount: number }; nodes: Array<ShopInviteFragment> };
};

export type InviteShopUserMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
  user: Types.UserRoleInput;
}>;

export type InviteShopUserMutation = { inviteToShop: Array<Types.Role> };

export type RemoveFromShopMutationVariables = Types.Exact<{
  email: Types.Scalars['String'];
  shopId: Types.Scalars['String'];
}>;

export type RemoveFromShopMutation = { removeFromShop: boolean };

export type ResendInviteMutationVariables = Types.Exact<{
  email: Types.Scalars['String'];
}>;

export type ResendInviteMutation = { resendInviteMail: boolean };

export type ShopBrandFragment = { id: string; name: string };

export type ShopBrandsQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;

export type ShopBrandsQuery = { shopBrands: Array<ShopBrandFragment> };

export type EditShopBrandsMutationVariables = Types.Exact<{
  input: Types.EditShopBrandsInput;
}>;

export type EditShopBrandsMutation = { editShopBrands: Array<ShopBrandFragment> };

export const ShopFullFragmentDoc = `
    fragment ShopFull on Shop {
  id
  name
  description
  shopifyName
  flatShippingRate
  freeShippingThreshold
  returnPolicy
  thumbnail
  admins {
    fullName
    email
    roles
  }
}
    `;
export const ShopInviteFragmentDoc = `
    fragment ShopInvite on Invite {
  id
  createdAt
  updatedAt
  email
  roles
  entityId
  entityType
  accepted
  hide
}
    `;
export const ShopBrandFragmentDoc = `
    fragment ShopBrand on ShopBrand {
  id
  name
}
    `;
export const ShopDocument = `
    query Shop($id: String!) {
  shop(id: $id) {
    ...ShopFull
  }
}
    ${ShopFullFragmentDoc}`;
export const useShopQuery = <TData = ShopQuery, TError = ApolloError>(
  variables: ShopQueryVariables,
  options?: UseQueryOptions<ShopQuery, TError, TData>,
) =>
  useQuery<ShopQuery, TError, TData>(
    ['Shop', variables],
    fetcher<ShopQuery, ShopQueryVariables>(ShopDocument, variables),
    options,
  );
export const UpdateShopDocument = `
    mutation UpdateShop($input: UpdateShopInput!) {
  updateShop(input: $input) {
    id
  }
}
    `;
export const useUpdateShopMutation = <TError = ApolloError, TContext = unknown>(
  options?: UseMutationOptions<UpdateShopMutation, TError, UpdateShopMutationVariables, TContext>,
) =>
  useMutation<UpdateShopMutation, TError, UpdateShopMutationVariables, TContext>(
    (variables?: UpdateShopMutationVariables) =>
      fetcher<UpdateShopMutation, UpdateShopMutationVariables>(UpdateShopDocument, variables)(),
    options,
  );
export const CreateShopDocument = `
    mutation CreateShop($input: ShopInput!) {
  createShop(input: $input) {
    id
  }
}
    `;
export const useCreateShopMutation = <TError = ApolloError, TContext = unknown>(
  options?: UseMutationOptions<CreateShopMutation, TError, CreateShopMutationVariables, TContext>,
) =>
  useMutation<CreateShopMutation, TError, CreateShopMutationVariables, TContext>(
    (variables?: CreateShopMutationVariables) =>
      fetcher<CreateShopMutation, CreateShopMutationVariables>(CreateShopDocument, variables)(),
    options,
  );
export const ShopInvitesDocument = `
    query ShopInvites($id: String!, $paging: PagingInput, $sorting: [SortFieldInput!], $filters: [FilterInput!]) {
  shopInvites(id: $id, paging: $paging, sorting: $sorting, filters: $filters) {
    paging {
      offset
      limit
      totalCount
    }
    nodes {
      ...ShopInvite
    }
  }
}
    ${ShopInviteFragmentDoc}`;
export const useShopInvitesQuery = <TData = ShopInvitesQuery, TError = ApolloError>(
  variables: ShopInvitesQueryVariables,
  options?: UseQueryOptions<ShopInvitesQuery, TError, TData>,
) =>
  useQuery<ShopInvitesQuery, TError, TData>(
    ['ShopInvites', variables],
    fetcher<ShopInvitesQuery, ShopInvitesQueryVariables>(ShopInvitesDocument, variables),
    options,
  );
export const InviteShopUserDocument = `
    mutation InviteShopUser($id: String!, $user: UserRoleInput!) {
  inviteToShop(id: $id, user: $user)
}
    `;
export const useInviteShopUserMutation = <TError = ApolloError, TContext = unknown>(
  options?: UseMutationOptions<InviteShopUserMutation, TError, InviteShopUserMutationVariables, TContext>,
) =>
  useMutation<InviteShopUserMutation, TError, InviteShopUserMutationVariables, TContext>(
    (variables?: InviteShopUserMutationVariables) =>
      fetcher<InviteShopUserMutation, InviteShopUserMutationVariables>(InviteShopUserDocument, variables)(),
    options,
  );
export const RemoveFromShopDocument = `
    mutation RemoveFromShop($email: String!, $shopId: String!) {
  removeFromShop(email: $email, shopId: $shopId)
}
    `;
export const useRemoveFromShopMutation = <TError = ApolloError, TContext = unknown>(
  options?: UseMutationOptions<RemoveFromShopMutation, TError, RemoveFromShopMutationVariables, TContext>,
) =>
  useMutation<RemoveFromShopMutation, TError, RemoveFromShopMutationVariables, TContext>(
    (variables?: RemoveFromShopMutationVariables) =>
      fetcher<RemoveFromShopMutation, RemoveFromShopMutationVariables>(RemoveFromShopDocument, variables)(),
    options,
  );
export const ResendInviteDocument = `
    mutation ResendInvite($email: String!) {
  resendInviteMail(email: $email)
}
    `;
export const useResendInviteMutation = <TError = ApolloError, TContext = unknown>(
  options?: UseMutationOptions<ResendInviteMutation, TError, ResendInviteMutationVariables, TContext>,
) =>
  useMutation<ResendInviteMutation, TError, ResendInviteMutationVariables, TContext>(
    (variables?: ResendInviteMutationVariables) =>
      fetcher<ResendInviteMutation, ResendInviteMutationVariables>(ResendInviteDocument, variables)(),
    options,
  );
export const ShopBrandsDocument = `
    query ShopBrands($id: String!) {
  shopBrands(id: $id) {
    ...ShopBrand
  }
}
    ${ShopBrandFragmentDoc}`;
export const useShopBrandsQuery = <TData = ShopBrandsQuery, TError = ApolloError>(
  variables: ShopBrandsQueryVariables,
  options?: UseQueryOptions<ShopBrandsQuery, TError, TData>,
) =>
  useQuery<ShopBrandsQuery, TError, TData>(
    ['ShopBrands', variables],
    fetcher<ShopBrandsQuery, ShopBrandsQueryVariables>(ShopBrandsDocument, variables),
    options,
  );
export const EditShopBrandsDocument = `
    mutation EditShopBrands($input: EditShopBrandsInput!) {
  editShopBrands(input: $input) {
    ...ShopBrand
  }
}
    ${ShopBrandFragmentDoc}`;
export const useEditShopBrandsMutation = <TError = ApolloError, TContext = unknown>(
  options?: UseMutationOptions<EditShopBrandsMutation, TError, EditShopBrandsMutationVariables, TContext>,
) =>
  useMutation<EditShopBrandsMutation, TError, EditShopBrandsMutationVariables, TContext>(
    (variables?: EditShopBrandsMutationVariables) =>
      fetcher<EditShopBrandsMutation, EditShopBrandsMutationVariables>(EditShopBrandsDocument, variables)(),
    options,
  );
