import React, { useMemo } from 'react';
import { Card, Grid, Box, CardContent, Typography } from '@material-ui/core';
import { ProductOverviewQuery } from './__generated__/product-overview-page.hooks';
import { unescape } from 'html-escaper';

interface Props {
  product: ProductOverviewQuery['product'];
}

export const ProductOverview: React.FC<Props> = ({ product }) => {
  const { name, description, category, tags, defaultImage, images } = product;

  const modifiedImgs = useMemo(() => {
    // @ts-ignore
    const modImgs = [...new Set(images)] || [];

    // This moves the default image to the beginning of the images array
    if (defaultImage) {
      const defaultIndex = modImgs.indexOf(defaultImage);
      modImgs.unshift(modImgs.splice(defaultIndex, 1)[0]);
    }

    return modImgs;
  }, [defaultImage, images]);

  return (
    <Card>
      <CardContent>
        <Box p={2} color="white">
          <Grid container spacing={2}>
            <Grid item sm={6}>
              <Typography variant="h5">
                <Box fontWeight="bold">{name}</Box>
              </Typography>
              <Box mt={1}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: unescape(description || ''),
                  }}
                ></div>
              </Box>
              {!!(tags || []).length && (
                <Box mt={3}>
                  <Box>Product tags</Box>
                  <Box mt={1}>
                    {(tags || []).map(t => (
                      <span className="badge badge-secondary" key={t}>
                        {t}
                      </span>
                    ))}
                  </Box>
                </Box>
              )}
              <Box mt={3}>
                <Box>Product category</Box>
                <Box fontWeight="bold">
                  <Typography variant="h6">
                    <Box fontWeight="bold">{category}</Box>
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item sm={6}>
              <h3>Images</h3>
              <div className="product-imgs">
                {modifiedImgs.map(i => (
                  <span className="product-img" key={i}>
                    <img src={i} alt={i} />
                  </span>
                ))}
              </div>
              <Box mt={4} textAlign="left">
                <Grid container item spacing={2} sm={12}>
                  <Grid item>
                    <Box mb={1}>Option 1</Box>
                    <Typography variant="h6">
                      <Box fontWeight="bold">{product.option1}</Box>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Box mb={1}>Option 2</Box>
                    <Typography variant="h6">
                      <Box fontWeight="bold">{product.option2}</Box>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Box mb={1}>Option 3</Box>
                    <Typography variant="h6">
                      <Box fontWeight="bold">{product.option3}</Box>
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </CardContent>
    </Card>
  );
};
