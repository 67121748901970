import { analyticsFetcher } from 'api/analytics-api';
import { useQuery } from 'react-query';
import { AnalyticsRange } from 'types/app-types';

export interface OpenedScuti {
  openedScuti: number;
  openedSdk: number;
}

export interface TotalOrders {
  totalOrders: number;
}

export type AvgProductsImpressions = {
  clickedOffer: number;
  openedScuti: number;
}[];

export type AvgAdsImpressions = {
  adsImpressions: number;
  openedScuti: number;
}[];

export type AvgTimeSpent = {
  avgDurationSeconds: number;
};

interface Params {
  gameId: string;
  range: AnalyticsRange;
  metric: 'opened-scuti' | 'total-orders' | 'avg-products-impressions' | 'avg-ads-impressions' | 'avg-time-spent';
}

export const useStatQuery = <T>({ range, metric, gameId }: Params) =>
  useQuery<T>(
    [range, metric, gameId],
    (() => {
      return analyticsFetcher('game')<T>(metric, {
        date_from: range.from.format('YYYY-MM-DD'),
        // Add additional day to cover last minutes of `to` date
        date_to: range.to
          .clone()
          .add(1, 'day')
          .format('YYYY-MM-DD'),
        id: gameId,
      });
    })(),
    { enabled: !!gameId },
  );
