import React from 'react';
import classNames from 'classnames';
import { Button, Card, CardBody, Form, FormFeedback, FormGroup, Input } from 'reactstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { ResetPasswordRequest } from 'types/auth-types';

import { SuccessPopup } from 'components/SuccessPopup';
import { LockIcon } from 'components/svg-icons/Lock';
import { validators, validate } from 'utils/form-utils';

interface FormValues {
  password: string;
  repeatPassword: string;
}

const validationSchema = () =>
  Yup.object().shape({
    password: validators.password,
    repeatPassword: validators.repeatPassword,
  });

const initialValues = {
  password: '',
  repeatPassword: '',
};

interface ResetPasswordFormProps {
  success?: boolean;
  error?: string;
  onResetPassword: (params: ResetPasswordRequest) => void;
}

export const ResetPasswordForm: React.FC<ResetPasswordFormProps> = ({ onResetPassword, success, error }) => {
  const formik = useFormik<FormValues>({
    enableReinitialize: true,
    initialValues: {
      ...initialValues,
    },
    onSubmit: onResetPassword,
    isInitialValid: false,
    validate: validate(validationSchema),
  });
  const { values, errors, touched, handleChange, handleBlur, handleSubmit, isValid } = formik;

  return (
    <Card className={classNames('w-23', { success })}>
      <CardBody>
        <div className="card-headline">
          <h1>Reset password</h1>
          <p>Enter in your new password for your account.</p>
        </div>
        <Form onSubmit={handleSubmit} noValidate name="simpleForm">
          <FormGroup
            className={classNames(
              'form-group-addon',
              { 'input-active': values.password },
              { 'input-invalid': touched.password && !!errors.password },
              { 'input-touched': touched.password },
              { 'input-valid': touched.password && !errors.password },
            )}
          >
            <label htmlFor="password" className="ico-label">
              <LockIcon />
            </label>
            <Input
              type="password"
              name="password"
              id="password"
              placeholder="New password"
              valid={touched.password && !errors.password}
              invalid={touched.password && !!errors.password}
              required
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.password}
            />
            <FormFeedback>{errors.password}</FormFeedback>
          </FormGroup>
          <FormGroup
            className={classNames(
              'form-group-addon',
              { 'input-active': values.repeatPassword },
              {
                'input-invalid': touched.repeatPassword && !!errors.repeatPassword,
              },
              { 'input-touched': touched.repeatPassword },
              {
                'input-valid': touched.repeatPassword && !errors.repeatPassword,
              },
            )}
          >
            <label htmlFor="repeatPassword" className="ico-label">
              <LockIcon />
            </label>
            <Input
              type="password"
              name="repeatPassword"
              id="repeatPassword"
              placeholder="Repeat password"
              valid={touched.repeatPassword && !errors.repeatPassword}
              invalid={touched.repeatPassword && !!errors.repeatPassword}
              required
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.repeatPassword}
            />
            <FormFeedback>{errors.repeatPassword}</FormFeedback>
          </FormGroup>
          {error && <div className="text-center text-danger mb-1">{error}</div>}
          <FormGroup className="actions">
            <Button
              type="submit"
              color="primary"
              className="btn-block"
              disabled={!isValid}
              onclick={() => handleSubmit()}
            >
              Set new password
            </Button>
          </FormGroup>
        </Form>
      </CardBody>
      <SuccessPopup />
    </Card>
  );
};
