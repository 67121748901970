import { useUserGameItemsQuery, useUserShopItemsQuery } from './__generated__/use-user-items.hooks';
import { getRandomColor } from 'utils/color-utils';
import { getFromStorage, saveToStorage, StorageKeys } from 'utils/local-storage.utils';
import { useState } from 'react';
import { ItemType, UserItem, UserItems } from 'types/user-types';
import { useAppStore } from 'store/app-store.hook';
import { hasOneOfRoles } from 'utils/roles.utils';
import { Role } from 'types/__generated__/types';

export const useUserItems = () => {
  const { uiStore, permissions } = useAppStore();
  const { userRoles } = permissions;
  const selectedItem = getFromStorage<UserItem>(StorageKeys.selectedItem);
  const [items, setItems] = useState<UserItems>({ games: [], shops: [], selectedItem });

  const useUserGameItems = useUserGameItemsQuery(undefined, {
    onSuccess: ({ games }) => {
      const gGames = games.nodes.map(({ id, name }) => ({ id, name, color: getRandomColor() }));
      const mappedGames = gGames.map(i => ({ ...i, itemType: ItemType.Game }));
      const selectedItem = items.selectedItem || mappedGames[0] || { name: '', id: '', color: '#FFF' };
      setItems({
        ...items,
        games: mappedGames,
        selectedItem,
      });
      uiStore.setSelectedItem(selectedItem);
      saveToStorage(StorageKeys.selectedItem, selectedItem);
    },
    enabled: hasOneOfRoles(userRoles, [Role.ScutiAdmin, Role.GameOwner, Role.GameAdmin]),
  });

  const useUserShopItems = useUserShopItemsQuery(undefined, {
    onSuccess: ({ shops }) => {
      const sShops = shops.nodes.map(({ id, name, shopifyName }) => ({
        id,
        name,
        color: getRandomColor(),
        shopifyName,
      }));
      const mappedShops = sShops.map(i => ({ ...i, itemType: ItemType.Shop }));
      const selectedItem = items.selectedItem || mappedShops[0] || { name: '', id: '', color: '#FFF' };
      setItems({
        ...items,
        shops: mappedShops,
        selectedItem: items.selectedItem || mappedShops[0],
      });
      uiStore.setSelectedItem(selectedItem);
      saveToStorage(StorageKeys.selectedItem, selectedItem);
    },
    enabled: hasOneOfRoles(userRoles, [Role.ScutiAdmin, Role.ShopOwner, Role.ShopAdmin]),
  });

  const onSelect = (selectedItem: UserItem) => {
    setItems({ ...items, selectedItem });
    uiStore.setSelectedItem(selectedItem);
    saveToStorage(StorageKeys.selectedItem, selectedItem);
  };

  const isLoading = useUserGameItems.isLoading || useUserShopItems.isLoading;
  return {
    isLoading,
    items: {
      ...items,
      selectedItem: items.selectedItem || items.games[0] || items.shops[0],
    },
    onSelect,
  };
};
