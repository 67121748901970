import { OrderListItemFragment, useSetOrderStatusMutation } from '../__generated__/orders.hooks';
import { useCallback, useMemo } from 'react';
import { Column } from 'react-table';
import { Link } from 'react-router-dom';
import { ordersRoutes } from 'routing';
import moment from 'moment';
import { ScutiSelect } from 'components/material/ScutiSelect';
import { FULFILLMENT_SELECT_OPTIONS } from 'types/select-types';
import { addDecimals } from 'utils/string.utils';
import { OrderStatus } from 'types/__generated__/types';

export function useOrdersTableColumns(onFulfillmentChange: () => void): Column<OrderListItemFragment>[] {
  const useSetOrderStatus = useSetOrderStatusMutation();

  const handleFulfillmentChange = useCallback(
    async (orderId: string, fulfillment: OrderStatus) => {
      await useSetOrderStatus.mutateAsync({
        id: orderId,
        status: fulfillment,
      });
      onFulfillmentChange();
    },
    [onFulfillmentChange, useSetOrderStatus],
  );

  return useMemo<Column<OrderListItemFragment>[]>(
    () => [
      {
        Header: 'Order #',
        accessor: 'id',
        Cell: ({ row }: any) => {
          const order = row.original;
          return (
            <Link title={order.id} className="order-item" to={ordersRoutes.ORDER_OVERVIEW(order.id)}>
              {order.id}
            </Link>
          );
        },
      },
      {
        Header: 'Name',
        id: 'user',
        Cell: ({ row }: any) => {
          const order = row.original;
          return order.user.fullName;
        },
      },
      {
        Header: 'Vendor Order',
        id: 'vendorOrder',
        Cell: () => {
          return '-';
        },
      },
      {
        Header: 'Date',
        id: 'createdAt',
        Cell: ({ row }: any) => {
          const order = row.original;
          return moment(order.createdAt).format('MM/DD/YYYY');
        },
      },
      {
        Header: 'Total',
        id: 'amount',
        Cell: ({ row }: any) => {
          const order = row.original;
          return `$${addDecimals(order.amount)}`;
        },
      },
      {
        Header: 'Quantity',
        id: 'quantity',
        disableSortBy: true,
        Cell: ({ row }: any) => {
          const order = row.original;
          return order.items.reduce((sum: number, { quantity }: any) => sum + quantity, 0);
        },
      },
      {
        Header: 'Fulfillment Status',
        id: 'status',
        Cell: ({ row }: any) => {
          const order = row.original;
          return (
            <ScutiSelect
              placeholder="Select "
              value={order.status}
              options={FULFILLMENT_SELECT_OPTIONS}
              onChange={event => handleFulfillmentChange(order.id, event.target.value as OrderStatus)}
            />
          );
        },
      },
      {
        Header: '',
        id: 'edit',
        width: '6%',
        disableSortBy: true,
        Cell: ({ row }: any) => {
          const order = row.original;
          return (
            <div className="row-actions">
              <Link className="link-edit" title="Edit" to={ordersRoutes.ORDER_OVERVIEW(order.id)}>
                Edit
              </Link>
            </div>
          );
        },
      },
    ],
    [],
  );
}
