import { GAME_CATEGORIES, Gender, PromotionType } from './app-types';
import countryTelephoneData from 'country-telephone-data';
import {
  CampaignBudgetOption,
  CampaignStatus,
  CampaignType,
  ContentCategory,
  OfferType,
  OrderStatus,
} from './__generated__/types';
const states = require('usa-states');

export interface SelectOption<T> {
  label: string;
  value: T;
}

export const getOptionByValue = <T>(value: T, options: SelectOption<T>[]): SelectOption<T> | undefined => {
  return options.find(o => o.value === value);
};

export const getOptionById = <T extends { id: string }>(
  value: T,
  options: SelectOption<T>[],
): SelectOption<T> | undefined => {
  return options.find(o => o.value.id === value.id);
};

export const GENDER_SELECT_OPTIONS: SelectOption<Gender | string>[] = [
  { label: 'Select gender', value: '' },
  { label: 'Male', value: Gender.MALE },
  { label: 'Female', value: Gender.FEMALE },
];

export const CAMPAIGN_STATUS_OPTIONS: SelectOption<CampaignStatus>[] = [
  { label: 'Active', value: CampaignStatus.Active },
  { label: 'Completed', value: CampaignStatus.Completed },
  { label: 'Paused', value: CampaignStatus.Paused },
  { label: 'Pending', value: CampaignStatus.Pending },
];

export const CAMPAIGN_TYPE_OPTIONS: SelectOption<CampaignType>[] = [
  { label: 'Product', value: CampaignType.Product },
  { label: 'Banner', value: CampaignType.Banner },
  { label: 'Video', value: CampaignType.Video },
];

export const GAME_CATEGORIES_SELECT_OPTIONS = GAME_CATEGORIES.map(c => ({ label: c, value: c }));

export const PROMOTION_SELECT_OPTIONS: SelectOption<PromotionType>[] = [
  { label: 'Hot Price', value: PromotionType.HOT_PRICE },
  { label: 'Special Offer', value: PromotionType.SPECIAL_OFFER },
];

export const CAMPAIGN_BUDGET_SELECT_OPTIONS: SelectOption<CampaignBudgetOption | null>[] = [
  { label: 'Normal', value: CampaignBudgetOption.Normal },
  { label: 'Featured', value: CampaignBudgetOption.Featured },
];

export const FULFILLMENT_SELECT_OPTIONS: SelectOption<OrderStatus>[] = [
  { label: 'Cancelled', value: OrderStatus.Cancelled },
  { label: 'Completed', value: OrderStatus.Completed },
  { label: 'Pending', value: OrderStatus.Pending },
  { label: 'Shipping', value: OrderStatus.Shipping },
];

export const OFFER_SELECT_OPTIONS: SelectOption<OfferType | null>[] = [
  { label: 'Select special offer', value: null },
  { label: 'BuyOneGetOne', value: OfferType.BuyOneGetOne },
  { label: 'FreeSamples', value: OfferType.FreeSamples },
  { label: 'GiftWithPurchase', value: OfferType.GiftWithPurchase },
  { label: 'LimitedTimeOffer', value: OfferType.LimitedTimeOffer },
  { label: 'TripWire', value: OfferType.TripWire },
];

export const BUSINESS_SELECT_OPTIONS: SelectOption<string>[] = [
  { label: 'Select category', value: '' },
  { label: 'Retailer', value: 'Retailer' },
  { label: 'Health Practitioner', value: 'Health Practitioner' },
  { label: 'Distributor', value: 'Distributor' },
  { label: 'Food Service', value: 'Food Service' },
  { label: 'Supplier', value: 'Supplier' },
  { label: 'Manufacturer', value: 'Manufacturer' },
  { label: 'Business Services', value: 'Business Services' },
  { label: 'Investor', value: 'Investor' },
  { label: 'Other', value: 'Other' },
];
export const CONTENT_CATEGORY_SELECT_OPTIONS: SelectOption<ContentCategory>[] = [
  { label: 'Male', value: ContentCategory.AdultsOnly },
  { label: 'Male', value: ContentCategory.EarlyChildhood },
  { label: 'Male', value: ContentCategory.Everyone },
  { label: 'Male', value: ContentCategory.Everyone_10Plus },
  { label: 'Male', value: ContentCategory.Mature },
  { label: 'Male', value: ContentCategory.RatingPending },
  { label: 'Male', value: ContentCategory.Teen },
];

export const COUNTRIES_SELECT_OPTIONS: SelectOption<string>[] = [
  { label: 'Select country', value: '' },
  ...countryTelephoneData.allCountries.map(({ name, iso2 = '' }: { name: string; iso2: string }) => ({
    label: name,
    value: iso2.toUpperCase(),
  })),
];

export const USA_STATES_SELECT_OPTIONS_SHORT: SelectOption<string>[] = [
  { label: 'Select state', value: '' },
  ...new states.UsaStates().states.map(({ abbreviation }: { abbreviation: string }) => ({
    label: abbreviation,
    value: abbreviation,
  })),
];

export const USA_STATES_SELECT_OPTIONS: SelectOption<string>[] = new states.UsaStates().states.map(
  ({ name, abbreviation }: { name: string; abbreviation: string }) => ({
    label: name,
    value: abbreviation.toUpperCase(),
  }),
);

export const REFUND_REASONS_SELECT_OPTIONS: SelectOption<string>[] = [
  { label: 'Select category', value: '' },
  { label: 'Reason 1', value: 'Reason 1' },
  { label: 'Reason 2', value: 'Reason 2' },
  { label: 'Reason 3', value: 'Reason 3' },
  { label: 'Reason 4', value: 'Reason 4' },
];
