import * as Yup from 'yup';
import { DeepPartialBy } from 'types/util-types';
import { FullGameFragment } from '../__generated__/game-edit-page.hooks';
import { ContentCategory, GameInput, Role, UpdateGameInput } from 'types/__generated__/types';
import { getImgAspectRatio, uploadImage } from 'utils/upload-image.utils';

export type GameForm = DeepPartialBy<Omit<FullGameFragment, 'tags'>, 'id' | 'currency'> & {
  tags: FullGameFragment['tags'];
  thumbnailFile?: File | null;
  currency?: (FullGameFragment['currency'] & { thumbnailFile?: File | null }) | null;
};

export const gameFormDefaults = (): GameForm => ({
  name: '',
  category: '',
  banner: null,
  thumbnail: null,
  currency: null,
  ageFrom: 0,
  ageTo: 100,
  tags: [],
  genderPercent: {
    male: 50,
    female: 50,
  },
  contentCategory: ContentCategory.EarlyChildhood,
  admins: [],
});

export const mapCurrency = async (currency: GameForm['currency']) => {
  if (!currency) return currency;
  const { scutiExchangeRate, thumbnail, ...curr } = currency;
  return {
    ...curr,
    thumbnail:
      thumbnail && typeof thumbnail === 'object'
        ? (await uploadImage(thumbnail, { width: 100, height: 100 })).url
        : thumbnail,
  };
};

export const mapGameForCreateInput = async ({ admins, ...form }: GameForm): Promise<GameInput> => {
  const { currency, thumbnail, banner } = form;
  return {
    ...form,
    banner: banner && typeof banner === 'object' ? (await uploadImage(banner, { width: 728, height: 90 })).url : banner,
    thumbnail:
      thumbnail && typeof thumbnail === 'object'
        ? (await uploadImage(thumbnail, { width: 100, height: 100 })).url
        : thumbnail,
    currency: await mapCurrency(currency),
  };
};

export const mapGameForUpdateInput = async (form: GameForm): Promise<UpdateGameInput> => {
  return {
    ...(await mapGameForCreateInput(form)),
    id: form.id!,
  };
};

const FILE_SIZE = 3 * 1024 * 1024;
const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'];

export const validationSchema = () =>
  Yup.object().shape({
    name: Yup.string()
      .min(2, 'Name should be at least 2 characters.')
      .max(50, 'Name can be 50 characters or less.')
      .required('Name is required!'),
    category: Yup.string()
      .min(2, 'Category is required!')
      .required('Category is required!'),
    tags: Yup.array<string>(Yup.string()),
    banner: Yup.string().nullable(),
    bannerFile: Yup.mixed()
      .nullable()
      .test('fileSize', 'File too large', value => (value ? value.size <= FILE_SIZE : true))
      .test('fileFormat', 'Unsupported Format', value => (value ? SUPPORTED_FORMATS.includes(value.type) : true))
      .test('aspectRatio', 'Wrong aspect ratio', async value => {
        if (value) {
          const [width, height] = await getImgAspectRatio(value);
          return width === 364 && height === 45;
        }
        return true;
      })
      .nullable()
      .notRequired(),
    thumbnail: Yup.string().nullable(),
    thumbnailFile: Yup.mixed()
      .test('fileSize', 'File too large', value => (value ? value.size <= FILE_SIZE : true))
      .test('fileFormat', 'Unsupported Format', value => (value ? SUPPORTED_FORMATS.includes(value.type) : true))
      .test('aspectRatio', 'Wrong aspect ratio', async value => {
        if (value) {
          const [width, height] = await getImgAspectRatio(value);
          return width === height;
        }
        return true;
      })
      .nullable()
      .notRequired(),
    currency: Yup.object()
      .shape({
        name: Yup.string()
          .min(2, 'Currency is required!')
          .required('Currency is required!'),
        thumbnail: Yup.string().nullable(),
        thumbnailFile: Yup.mixed()
          .nullable()
          .notRequired()
          .test('fileSize', 'File too large', value => (value ? value.size <= FILE_SIZE : true))
          .test('fileFormat', 'Unsupported Format', value => (value ? SUPPORTED_FORMATS.includes(value.type) : true))
          .test('aspectRatio', 'Wrong aspect ratio', async value => {
            if (value) {
              const [width, height] = await getImgAspectRatio(value);
              return width === height;
            }
            return true;
          }),
        exchangeRate: Yup.number()
          .transform(value => (isNaN(value) ? 0 : value))
          .moreThan(0, 'Exchange rate is required')
          .required('Exchange Rate is required!'),
        scutiExchangeRate: Yup.number()
          .transform(value => (isNaN(value) ? 0 : value))
          .moreThan(0, 'Scuti exchange rate is required'),
        // .required('Scuti exchange rate is required!'),
      })
      .nullable(),
    genderPercent: Yup.object()
      .shape({
        male: Yup.number()
          .transform(value => (isNaN(value) ? 0 : value))
          .min(0)
          .max(100),
        female: Yup.number()
          .transform(value => (isNaN(value) ? 0 : value))
          .min(0)
          .max(100),
      })
      .nullable(),
    ageFrom: Yup.number(),
    ageTo: Yup.number(),
    contentCategory: Yup.mixed().oneOf([
      ContentCategory.AdultsOnly,
      ContentCategory.EarlyChildhood,
      ContentCategory.Everyone,
      ContentCategory.Everyone_10Plus,
      ContentCategory.Mature,
      ContentCategory.RatingPending,
      ContentCategory.Teen,
    ]),
    admins: Yup.array().of(
      Yup.object({
        email: Yup.string().email(),
        roles: Yup.array().of(Yup.mixed().oneOf(Object.values(Role))),
      }),
    ),
  });
