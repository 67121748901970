import React from 'react';
import { FormikProps } from 'formik';
import { Card, CardHeader, CardContent } from '@material-ui/core';
import { ScutiInput } from 'components/material/ScutiInput';
import { CampaignForm, isPaused } from './CampaignForm.utils';

export const VideoDetails: React.FC<FormikProps<CampaignForm>> = props => {
  const { values, touched, errors } = props;
  return (
    <Card>
      <CardHeader title="Video" />
      <CardContent>
        <ScutiInput
          title="Video URL *"
          name="video.videoUrl"
          placeholder="http://"
          value={values.video?.videoUrl || ''}
          // @ts-ignore
          error={touched.video?.videoUrl && errors['video.videoUrl']}
          onChange={props.handleChange}
          onBlur={props.handleBlur}
          disabled={!isPaused(values)}
        />
      </CardContent>
    </Card>
  );
};
