import { useEffect, useState } from 'react';
import { Box, Pagination } from '@mui/material';
import { Loader } from 'components/Loader';
import { NoData } from 'components/material/NoData';
import { usePageClick } from 'hooks/usePageClick';
import { usePaging } from 'hooks/usePaging';
import { Paging } from 'types/__generated__/types';
import { ScutiSelect } from 'components/material/ScutiSelect';

interface Props {
  isLoading: boolean;
  header: React.ReactNode;
  content: React.ReactNode;
  paging?: Paging;
}

export const PaginationLayout: React.FC<Props> = ({ header, content, isLoading, paging }) => {
  const onPageClick = usePageClick();
  const urlPaging = usePaging();
  const [limit, setLimit] = useState(urlPaging.limit);
  const [page, setPage] = useState(Math.floor(urlPaging.offset / urlPaging.limit) + 1);
  const showPaging = paging && paging.limit < paging.totalCount;

  useEffect(() => {
    if (page && urlPaging) {
      const offset = page * urlPaging.limit - urlPaging.limit;
      onPageClick({ limit, offset });
    }
  }, [limit, onPageClick, page, urlPaging]);

  return (
    <Box height="100%" display="flex" flexDirection="column" justifyContent="space-between">
      <Box>{header}</Box>
      <Box
        height={400}
        mt={2}
        flexGrow={1}
        style={{
          overflowY: 'auto',
        }}
      >
        {isLoading && <Loader />}
        {content && !isLoading && content}
        {!content && !isLoading && <NoData />}
      </Box>
      <Box mt={2} width="100%" display="flex" justifyContent="flex-end">
        <Box mr={2}>
          <ScutiSelect
            options={[12, 24, 36].map(value => ({ label: value, value }))}
            value={limit}
            onChange={({ target }) => setLimit(target.value as number)}
          />
        </Box>
        {showPaging && paging && (
          <Pagination
            count={Math.ceil(paging.totalCount / paging.limit)}
            page={page}
            onChange={(_, pageNumber) => {
              if (pageNumber) setPage(pageNumber);
            }}
            variant="outlined"
            shape="rounded"
          />
        )}
      </Box>
    </Box>
  );
};
