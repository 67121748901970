import { Box, Card, CardContent, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    padding: `${theme.spacing(2)}px !important`,
  },
  title: {
    color: '#9DA5B1',
  },
}));

interface Props {
  title: string;
  value: string | number;
  subValue?: string;
  isLoading?: boolean;
}

export const StatsCard: React.FC<Props> = ({ title, value, subValue, isLoading }) => {
  const classes = useStyles();
  return (
    <Card>
      <CardContent className={classes.root}>
        <Box display="flex" width="100%" flexDirection="column" height={102} minWidth={212}>
          <Box fontWeight={600} fontSize={12} className={classes.title}>
            {title}
          </Box>
          {isLoading ? (
            <Box display="flex" width="100%" height="100%" alignItems="center" justifyContent="center">
              <CircularProgress color="inherit" />
            </Box>
          ) : (
            <Box mt={1} mb={1} fontWeight="fontWeightBold" fontSize={36}>
              {value}
            </Box>
          )}
          <Typography className={classes.title} component="div">
            <Box fontWeight={600} fontSize={12}>
              {subValue}
            </Box>
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};
