import { useMemo, useState } from 'react';
import { Box, Card, CardContent, Grow } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { SwitcherSection } from './SwitcherSection';
import { SwitcherHeader } from './SwitcherHeader';
import useOnclickOutside from 'react-cool-onclickoutside';
import { useUserItems } from 'hooks/useUserItems/useUserItems';

export const Switcher = observer(() => {
  const { items, onSelect } = useUserItems();

  const [isOpen, setIsOpen] = useState(false);
  const ref = useOnclickOutside(() => setIsOpen(false));

  const showGames = !!items.games.length;
  const showShops = !!items.shops.length;
  const showSwitcher = useMemo(() => [...items.games, ...items.shops].length > 1, [items]);

  return (
    <Box position="relative" width={200}>
      {!showSwitcher ? null : (
        <>
          <SwitcherHeader selectedItem={items.selectedItem} onClick={() => setIsOpen(!isOpen)} />
          <div ref={ref}>
            <Grow in={isOpen}>
              <Box position="absolute" mt={1} minWidth={360} left={-120}>
                <Card>
                  <CardContent>
                    <Box maxHeight={400} overflow="auto">
                      {showGames && (
                        <SwitcherSection
                          title="Games"
                          items={items.games}
                          selected={items.selectedItem}
                          onClick={onSelect}
                        />
                      )}
                      {showShops && (
                        <SwitcherSection
                          title="Shops"
                          items={items.shops}
                          selected={items.selectedItem}
                          onClick={onSelect}
                        />
                      )}
                    </Box>
                  </CardContent>
                </Card>
              </Box>
            </Grow>
          </div>
        </>
      )}
    </Box>
  );
});
