import React, { useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { AccountBasicDetails } from 'components/AccountBasicDetails';
import { useAppStore } from 'store/app-store.hook';
import { ChangePassword } from 'components/ChangePassword';
import { Row, Col } from 'reactstrap';
import { useToasts } from 'react-toast-notifications';

export const AccountSettingsPage: React.FC = observer(() => {
  const { authStore, logOut } = useAppStore();
  const { addToast } = useToasts();

  React.useEffect(() => {
    authStore.cleanError();
  }, [authStore]);

  const handleChangePassword = useCallback(
    async (params: any) => {
      try {
        await authStore.changePassword(params);
        addToast('Password has been changed!', { appearance: 'success', autoDismiss: true });
        logOut();
      } catch (e) {
        addToast(e.message, { appearance: 'error', autoDismiss: false });
      }
    },
    [addToast, authStore, logOut],
  );

  return (
    <div className="animated fadeIn">
      <Row>
        <Col className="col-xxl-3" md={6} xl={4}>
          <AccountBasicDetails
            basicDetails={{ ...authStore.userInfo }}
            onSave={authStore.changeFullname}
            error={authStore.error}
          />
        </Col>
        <Col className="col-xxl-3" md={6} xl={4}>
          <ChangePassword onSave={handleChangePassword} error={authStore.error} />
        </Col>
      </Row>
    </div>
  );
});
