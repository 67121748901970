import React from 'react';
import { Nav } from 'reactstrap';
import { AppSidebarToggler } from '@coreui/react';
import { ScutiLogo } from 'components/svg-icons/ScutiLogo';
import { observer } from 'mobx-react-lite';
import { useAppStore } from 'store/app-store.hook';
import { Switcher } from 'components/material/Switcher';
import { AvatarMenu } from 'components/material/AvatarMenu';
import { Box } from '@mui/material';

export const DefaultHeader: React.FC = observer(() => {
  const { authStore, logOut } = useAppStore();
  const { userInfo } = authStore;
  return (
    <Box width="100%" display="flex" justifyContent="space-between" alignItems="center" pr={2}>
      <AppSidebarToggler className="d-lg-none" display="md" mobile />
      <ScutiLogo />
      <Nav className="ml-auto" navbar>
        <Box display="flex" alignItems="center">
          <Switcher />
          <AvatarMenu userName={userInfo.fullName} onLogout={logOut} />
        </Box>
      </Nav>
    </Box>
  );
});
