import React from 'react';

import { Card, CardBody } from 'reactstrap';
import { CheckEmailIcon } from 'components/svg-icons/CheckEmail';
import { Box } from '@material-ui/core';

export const EmailVerifiedPage: React.FC = () => {
  return (
    <Card className="d-flex flex-row align-items-center mh-315">
      <CardBody>
        <div className="text-center">
          <div className="icon mb-med">
            <CheckEmailIcon className="icon" />
          </div>
          <div className="card-headline text-center">
            <h1>CONGRATULATIONS!</h1>
            <Box color="text.primary" pl={5} pr={5} mt={4} mb={4} textAlign="left" fontSize={14}>
              <Box component="p">Your email has been successfully verified!</Box>
              <Box component="p">Welcome to the Scuti Rewards Marketplace</Box>
              <Box component="p">
                <strong>EXPLORE</strong> curated offerings for YOU
              </Box>
              <Box component="p">
                <strong>ENJOY</strong> free Scuti$ provided by our Partners
              </Box>
              <Box component="p">
                <strong>ENHANCE</strong> your gaming experience with Scuti$
              </Box>
              <Box component="p">
                <strong>PURCHASE</strong> real world products with Scuti$
              </Box>
              <Box component="p">
                <strong>EXPERIENCE</strong> all the benefits that Scuti provides
              </Box>
            </Box>
            <Box component="h1" textAlign="center" color="text.primary">
              HAVE FUN
            </Box>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};
