import React from 'react';
import { Box, Card, CardHeader, CardContent } from '@material-ui/core';
import { ScutiSelect } from 'components/material/ScutiSelect';
import { FormikProps } from 'formik';
import { isPaused, ProductListingForm } from './ProductListingEditForm.utils';
import { useListingBrandsQuery, useListingProductsQuery } from '../__generated__/product-listing-edit-page.hooks';
import queryString from 'query-string';
import { useLocation } from 'react-router';
import { ScutiAutocomplete } from 'components/material/ScutiAutoComplete';
import { useDebounce } from 'use-debounce/lib';
import { Filter_Type } from 'types/__generated__/types';
import { useProductCategories } from 'hooks/useProductCategories';

export const ProductDetails: React.FC<FormikProps<ProductListingForm>> = props => {
  const { values, touched, errors } = props;
  const { search } = useLocation();
  const { shopId } = queryString.parse(search);
  const productCategoriesOptions = useProductCategories();
  const [searchInput, setSearchInput] = React.useState<string>('');
  const [inputValue] = useDebounce(searchInput, 500);
  const listingProductsQuery = useListingProductsQuery(
    {
      id: shopId as string,
      filters: [
        {
          name: 'name',
          operator: Filter_Type.Contains,
          value: [inputValue],
        },
      ],
    },
    { keepPreviousData: true },
  );

  const listingBrands = useListingBrandsQuery({ id: shopId as string });

  const products = listingProductsQuery.data?.shopProducts.nodes || [];
  const brands = listingBrands.data?.shopBrands || [];

  const productOptions = products.map(p => ({ value: p.id, label: p.name }));
  const brandsOptions = brands.map(p => ({ value: p.id, label: p.name }));
  const selectedBrand = values.shopBrand || { id: '', name: '' };
  const selectedProduct = values.product?.product || { id: '', name: '' };

  return (
    <Card>
      <CardHeader title="Product" />
      <CardContent>
        <Box>
          <ScutiAutocomplete
            placeholder="Select Product"
            title="Product *"
            getOptionLabel={option => option.label}
            value={productOptions.find(({ value }) => value === selectedProduct.id) || null}
            options={productOptions}
            onInputChange={(_, value, reason) => {
              if ('input' === reason) setSearchInput(value);
            }}
            onChange={(event, value, reason) => {
              if ('selectOption' === reason && !!value) {
                props.setFieldValue('product.product', { id: value.value, name: value.label }, true);
              }
              if ('clear' === reason || !value) {
                props.setFieldValue('product.product', { id: '', name: '' }, true);
              }
            }}
            onBlur={() => props.setFieldTouched('product.product.id', true, true)}
            // @ts-ignore
            error={touched.product?.product?.id && errors['product.product.id']}
            disabled={!isPaused(values)}
          />
        </Box>
        <Box mt={2}>
          <ScutiAutocomplete
            placeholder="Select Brand"
            title="Brand"
            getOptionLabel={option => option.label}
            value={brandsOptions.find(({ value }) => value === selectedBrand.id) || null}
            options={brandsOptions}
            onChange={(event, value, reason) => {
              if ('selectOption' === reason && !!value) {
                props.setFieldValue('shopBrand', { id: value.value, name: value.label }, true);
              }
              if ('clear' === reason || !value) {
                props.setFieldValue('shopBrand', { id: '', name: '' }, true);
              }
            }}
            onBlur={() => props.setFieldTouched('shopBrand', true, true)}
            // @ts-ignore
            error={touched.shopBrand && errors['shopBrand']}
            disabled={!isPaused(values)}
          />
        </Box>
        <Box mt={2}>
          <ScutiSelect
            title="Category *"
            name="category"
            placeholder="Select category"
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            error={touched.category && errors?.category}
            value={values.category || ''}
            options={productCategoriesOptions}
            disabled={!isPaused(values)}
          />
        </Box>
      </CardContent>
    </Card>
  );
};
