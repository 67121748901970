import { ShopItemFgFragment } from './__generated__/shops-list-page.hooks';
import { useMemo } from 'react';
import { Column } from 'react-table';
import { Link } from 'react-router-dom';
import { shopsRoutes } from 'routing';
import noPicUrl from 'assets/svgs/no-pic-sm.svg';

export function useShopsTableColumns(onDelete: (id: string) => void): Column<ShopItemFgFragment>[] {
  return useMemo<Column<ShopItemFgFragment>[]>(
    () => [
      {
        Header: '',
        id: 'thumbnail',
        width: '6%',
        Cell: ({ row }: any) => {
          const { thumbnail } = row.original;
          return (
            <div className="variant-pic">
              <img src={thumbnail || noPicUrl} alt="" />
            </div>
          );
        },
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Shopify Name',
        accessor: 'shopifyName',
      },
      {
        Header: 'Admins',
        id: 'admins',
        disableSortBy: true,
        accessor: row => row.admins.map(({ fullName }) => fullName).join(', '),
      },
      {
        Header: '',
        id: 'delete',
        disableSortBy: true,
        width: '6%',
        Cell: ({ row }: any) => {
          return (
            <div className="row-actions">
              <Link to={shopsRoutes.SHOP_EDIT(row.original.id)} className="link-edit" title="Edit">
                Edit
              </Link>
              <span className="link-delete" title="Delete" onClick={() => onDelete(row.original.id)}>
                Delete
              </span>
            </div>
          );
        },
      },
    ],
    [onDelete],
  );
}
