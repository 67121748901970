import React from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';

interface Props {
  product: {
    id: string;
    name: string;
    images?: string[] | null;
    defaultImage?: string | null;
    variants: {
      price: {
        amount: number;
        currency: string;
      };
    }[];
  };
  url: string;
}

const priceLabel = (minPrice: number | null, maxPrice: number | null): string => {
  if (minPrice === maxPrice) return minPrice ? `$${minPrice}` : '';
  if (minPrice && maxPrice) return `$${minPrice}-$${maxPrice}`;
  return `$${minPrice || maxPrice}`;
};

export const ProductGridItem: React.FC<Props> = ({ url, product }) => {
  // Check for default image before selecting the first image if images are available
  const img = product.defaultImage ? product.defaultImage : product.images && product.images[0];
  const max = _.maxBy(product.variants, v => v.price.amount) || { price: { amount: 0 } };
  const min = _.minBy(product.variants, v => v.price.amount) || { price: { amount: 0 } };

  return (
    <div className="product-grid-item">
      <Link className="product-grid-link" to={url}>
        <div className="product-grid-pic">{img ? <img src={img || ''} alt="" /> : <span className="no-image" />}</div>
        <div className="product-grid-body">
          <strong className="product-grid-name">{product.name}</strong>
          <span className="product-grid-price">{priceLabel(min.price.amount, max.price.amount)}</span>
        </div>
      </Link>
    </div>
  );
};
