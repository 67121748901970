import React from 'react';
import { Form, Button } from 'reactstrap';
import { useFormik } from 'formik';
import { Link } from 'react-router-dom';
import { validate } from 'utils/form-utils';
import { appDownloadCampaignFormDefaults, validationSchema } from './AppDownloadCampaignEditForm.utils';
import { Box, Grid } from '@material-ui/core';
import { ScutiInput } from 'components/material/ScutiInput';
import { DurationDetails } from './DurationDetails';
import { AudienceDetails } from './AudienceDetails';
import { BudgetDetails } from './BudgetDetails';
import { AppDownloadIdsDetails } from './AppDownloadIdsDetails';
import { CampaignForm, isPaused } from './CampaignForm.utils';
import { hasId } from 'types/util-types';
import { AppDownloadMediaDetails } from './AppDownloadMediaDetails';

interface Props {
  linkBack: string;
  campaign?: CampaignForm;
  onSave: (campaign: CampaignForm) => void;
  onToggle: (params: { id: string; isPaused: boolean }) => void;
}

export const AppDownloadCampaignEditForm: React.FC<Props> = ({ campaign, linkBack, onSave, onToggle }) => {
  const formik = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues: campaign || appDownloadCampaignFormDefaults(),
    validate: validate(validationSchema),
    onSubmit: onSave,
  });

  const { values, touched, isSubmitting, errors } = formik;
  return (
    <Form className="w-100" onSubmit={formik.handleSubmit}>
      <div className="topbar d-sm-flex justify-content-sm-between">
        <div className="col-heading">
          <Link className="btn btn-back" to={linkBack}></Link>
          <Box ml={5} width={'50%'}>
            <ScutiInput
              name="name"
              placeholder="Campaign name"
              value={values.name}
              error={touched.name && errors.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
          </Box>
        </div>
        <div className="col-action">
          <Button color="outline-secondary" onClick={formik.handleReset} disabled={!formik.dirty}>
            Cancel
          </Button>
          {hasId(formik.values) && (
            <>
              <Button
                color={!isPaused(values) ? 'primary' : 'outline-secondary'}
                className="btn"
                onClick={() => onToggle({ id: formik.values.id || '', isPaused: !isPaused(values) })}
                disabled={!hasId(formik.values)}
              >
                {isPaused(values) ? 'Activate' : 'Pause'}
              </Button>
            </>
          )}
          <Button color="primary" type="submit" disabled={isSubmitting || !isPaused(values)}>
            Save changes
          </Button>
        </div>
      </div>
      <Box pb={2}>
        <Grid container spacing={4}>
          <Grid item container spacing={4}>
            <Grid item xs={12} md={6} lg={4}>
              <AppDownloadIdsDetails {...formik} />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <DurationDetails {...formik} showInventory={false} />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <AudienceDetails {...formik} />
            </Grid>
          </Grid>
          <Grid item container spacing={4}>
            <Grid item xs={12} md={6} lg={4}>
              <BudgetDetails {...formik} />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <AppDownloadMediaDetails {...formik} />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Form>
  );
};
