import React from 'react';
import { FormikProps } from 'formik';
import { Box, Card, CardHeader, CardContent } from '@material-ui/core';
import { ScutiInput } from 'components/material/ScutiInput';
import { CampaignForm, isPaused } from './CampaignForm.utils';

export const AppDownloadIdsDetails: React.FC<FormikProps<CampaignForm>> = props => {
  const { values, touched, errors } = props;
  return (
    <Card>
      <CardHeader title="App Download Ids" />
      <CardContent>
        <Box mt={2}>
          <ScutiInput
            title="Android Id"
            name="appDownloadIds.androidId"
            placeholder="com.example.app"
            value={values.appDownloadIds?.androidId || ''}
            // @ts-ignore
            error={touched.appDownloadIds?.androidId && errors['appDownloadIds.androidId']}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            disabled={!isPaused(values)}
          />
        </Box>
        <Box mt={2}>
          <ScutiInput
            type="number"
            title="Apple Id"
            name="appDownloadIds.appleId"
            placeholder="02522554"
            value={values.appDownloadIds?.appleId || null}
            // @ts-ignore
            error={touched.appDownloadIds?.appleId && errors['appDownloadIds.appleId']}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            disabled={!isPaused(values)}
          />
        </Box>
        <Box mt={2}>
          <ScutiInput
            title="PC Link"
            name="appDownloadIds.pcLink"
            placeholder="https://"
            value={values.appDownloadIds?.pcLink || ''}
            // @ts-ignore
            error={touched.appDownloadIds?.pcLink && errors['appDownloadIds.pcLink']}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            disabled={!isPaused(values)}
          />
        </Box>
      </CardContent>
    </Card>
  );
};
