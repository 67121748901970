import React from 'react';
import { FormFeedback, Button } from 'reactstrap';
import { REFUND_REASONS_SELECT_OPTIONS } from 'types/select-types';
import { ScutiSelect } from 'components/material/ScutiSelect';
import { OrderFragment } from '../__generated__/orders.hooks';
import { Card, CardContent, CardHeader, Grid, Box } from '@material-ui/core';
import { ScutiInput } from 'components/material/ScutiInput';

interface Props {
  order: OrderFragment;
}

export const OrderRefunds: React.FC<Props> = ({ order }) => {
  return (
    <Card>
      <CardHeader title="Amount to refund" />
      <CardContent>
        <Grid container>
          {order.items.map(order => {
            return (
              <Box key={order.id} display="flex" width="100%" justifyContent="space-between" alignItems="center">
                <Box>
                  <label htmlFor={`reason`}>Available to refund: ${order.amount}</label>
                </Box>
                <Box>
                  <ScutiInput placeholder="0.00" />
                  <FormFeedback>{}</FormFeedback>
                </Box>
              </Box>
            );
          })}
        </Grid>
        <Box mt={3}>
          <Grid container>
            <Grid item xs={4}>
              <Box mb={1} fontWeight="bold">
                Reason for refund (optional)
              </Box>
              <ScutiSelect
                placeholder="Select reason"
                name="reason"
                value={REFUND_REASONS_SELECT_OPTIONS[0]}
                options={REFUND_REASONS_SELECT_OPTIONS}
              />
            </Grid>
          </Grid>
        </Box>
        <Box mt={4} width="100%" display="flex" justifyContent="flex-end">
          <Button color="light">Refund $0.00</Button>
        </Box>
      </CardContent>
    </Card>
  );
};
