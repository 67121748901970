import * as Types from '../../../../types/__generated__/types';

import { ApolloError } from 'apollo-client';
import { useQuery, UseQueryOptions } from 'react-query';
import { fetcher } from 'api';
export type CampaignItemFragment = {
  id: string;
  name: string;
  category?: Types.Maybe<string>;
  type: Types.CampaignType;
  status: Types.CampaignStatus;
  shop?: Types.Maybe<{ id: string }>;
  product?: Types.Maybe<{ product: { id: string; name: string; images?: Types.Maybe<Array<string>> } }>;
  demographic: { gender?: Types.Maybe<string>; minAge: number; maxAge: number };
  budget?: Types.Maybe<{ maxSpend: number; maxDailySpend: number; limitOfImpressions: number }>;
  duration: {
    start?: Types.Maybe<any>;
    end?: Types.Maybe<any>;
    inventory?: Types.Maybe<number>;
    runUntilStockZero?: Types.Maybe<boolean>;
  };
  statistics?: Types.Maybe<{ totalSpend: number; totalRevenue: number; roi: number }>;
};

export type CampaignsQueryVariables = Types.Exact<{
  paging?: Types.Maybe<Types.PagingInput>;
  sorting?: Types.Maybe<Array<Types.SortFieldInput> | Types.SortFieldInput>;
  filters?: Types.Maybe<Array<Types.FilterInput> | Types.FilterInput>;
  id: Types.Scalars['ID'];
}>;

export type CampaignsQuery = {
  shopCampaigns: {
    sorting?: Types.Maybe<Array<{ name: string; dir: string }>>;
    paging: { offset: number; limit: number; totalCount: number };
    nodes: Array<CampaignItemFragment>;
  };
};

export const CampaignItemFragmentDoc = `
    fragment CampaignItem on Campaign {
  id
  name
  category
  type
  status
  shop {
    id
  }
  product {
    product {
      id
      name
      images
    }
  }
  demographic {
    gender
    minAge
    maxAge
  }
  budget {
    maxSpend
    maxDailySpend
    limitOfImpressions
  }
  duration {
    start
    end
    inventory
    runUntilStockZero
  }
  budget {
    maxSpend
    maxDailySpend
    limitOfImpressions
  }
  statistics {
    totalSpend
    totalRevenue
    roi
  }
}
    `;
export const CampaignsDocument = `
    query Campaigns($paging: PagingInput, $sorting: [SortFieldInput!], $filters: [FilterInput!], $id: ID!) {
  shopCampaigns(id: $id, paging: $paging, sorting: $sorting, filters: $filters) {
    sorting {
      name
      dir
    }
    paging {
      offset
      limit
      totalCount
    }
    nodes {
      ...CampaignItem
    }
  }
}
    ${CampaignItemFragmentDoc}`;
export const useCampaignsQuery = <TData = CampaignsQuery, TError = ApolloError>(
  variables: CampaignsQueryVariables,
  options?: UseQueryOptions<CampaignsQuery, TError, TData>,
) =>
  useQuery<CampaignsQuery, TError, TData>(
    ['Campaigns', variables],
    fetcher<CampaignsQuery, CampaignsQueryVariables>(CampaignsDocument, variables),
    options,
  );
