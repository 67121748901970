import { Box, Typography } from '@material-ui/core';
import { ScutiLogo } from 'components/svg-icons/ScutiLogo';
import { FallbackProps } from 'react-error-boundary';

export const ErrorFallback: React.FC<FallbackProps> = () => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" width="100%" height="100%">
      <ScutiLogo />
      <Box mt={2} textAlign="center">
        <Typography variant="h4">
          <Box color="text.secondary">Oooops... Something went wrong</Box>
        </Typography>
        <Typography variant="h6">
          <a href="mailto:andrew@scuti.store">
            <Box color="info.main" style={{ textDecoration: 'underline' }}>
              please contact out support support team
            </Box>
          </a>
        </Typography>
      </Box>
    </Box>
  );
};
