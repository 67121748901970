import { useMemo } from 'react';
import { useProductCategoriesQuery } from './__generated__/use-product-categories.hooks';

export const useProductCategories = () => {
  const useProductCategories = useProductCategoriesQuery(undefined, { cacheTime: 1000 * 60 * 60 });
  return useMemo(() => {
    return [
      { label: 'Select category', value: '' },
      ...(useProductCategories.data?.productCategories || []).sort().map(c => ({ label: c, value: c })),
    ];
  }, [useProductCategories.data?.productCategories]);
};
