import React from 'react';
import Select, { ValueContainerProps, components } from 'react-select';

export const MultipleSelect: React.FC<Select['props']> = props => {
  return (
    <Select
      className="react-select select_input-sm"
      placeholder="Filter by"
      {...props}
      hideSelectedOptions={false}
      isSearchable={false}
      classNamePrefix="react-select"
      components={{
        MultiValueContainer: () => null,
        Placeholder: () => null,
        ValueContainer: (props: ValueContainerProps<any, false>) => {
          return (
            <div>
              <div
                style={{
                  color: 'hsl(0,0%,50%)',
                  marginLeft: 8,
                  marginRight: 8,
                  top: '50%',
                  transform: 'translateY(-50%)',
                  position: 'absolute',
                }}
              >
                Filter by
              </div>
              <components.ValueContainer {...props} />
            </div>
          );
        },
      }}
    />
  );
};
