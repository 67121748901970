import React from 'react';
import { FormikProps } from 'formik';
import { isPaused, ProductListingForm } from './ProductListingEditForm.utils';
import { Card, CardHeader, CardContent, FormGroup, Box } from '@material-ui/core';
import { ScutiSelect } from 'components/material/ScutiSelect';
import { OFFER_SELECT_OPTIONS, PROMOTION_SELECT_OPTIONS } from 'types/select-types';
import { CustomInput } from 'reactstrap';
import { PromotionType } from 'types/__generated__/types';
import { ScutiInput } from 'components/material/ScutiInput';

export const PromotionDetails: React.FC<FormikProps<ProductListingForm>> = props => {
  const { values, touched, errors } = props;

  const [promotionSpecific, setPromotionSpecific] = React.useState(!!values.product?.promotion?.promotionType);

  const togglePromotion = () => {
    if (promotionSpecific) props.setFieldValue('product.promotion', null, true);
    else props.setFieldValue('product.promotion.promotionType', PROMOTION_SELECT_OPTIONS[0].value, true);
    setPromotionSpecific(!promotionSpecific);
  };

  const promotionType = values.product?.promotion?.promotionType;
  const isOfferPromotion = promotionType === PromotionType.SpecialOffer;
  const isCopyOffer = isOfferPromotion && values.product?.promotion?.offerType;
  const isHotPrice = promotionType === PromotionType.HotPrice;

  return (
    <Card>
      <CardHeader title="Promotion" />
      <CardContent>
        <FormGroup>
          <label>
            <CustomInput
              id="promotion-box"
              type="checkbox"
              label="Promotion"
              onChange={togglePromotion}
              checked={promotionSpecific}
              disabled={!isPaused(values)}
            />
          </label>
          <ScutiSelect
            title="Offers"
            name="product.promotion.promotionType"
            placeholder="Select type"
            value={promotionType || ''}
            options={PROMOTION_SELECT_OPTIONS}
            onChange={({ target }) => {
              if (target.value === PromotionType.SpecialOffer) {
                props.setFieldValue('product.promotion', { promotionType: PromotionType.SpecialOffer }, true);
              }
              if (target.value === PromotionType.HotPrice) {
                props.setFieldValue('product.promotion', { promotionType: PromotionType.HotPrice }, true);
              }
            }}
            onBlur={props.handleBlur}
            disabled={!promotionSpecific || !isPaused(values)}
          />
        </FormGroup>
        {isOfferPromotion && (
          <Box mt={2}>
            <ScutiSelect
              name="product.promotion.offerType"
              placeholder="Select offer"
              value={values.product?.promotion?.offerType || ''}
              // @ts-ignore
              error={touched.product?.promotion?.offerType && errors['product.promotion.offerType']}
              options={OFFER_SELECT_OPTIONS}
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              disabled={!isPaused(values)}
            />
          </Box>
        )}
        {isCopyOffer && (
          <Box mt={2}>
            <ScutiInput
              name="product.promotion.offerCopy"
              placeholder="Copy"
              value={values.product?.promotion?.offerCopy || ''}
              // @ts-ignore
              error={touched.product?.promotion?.offerCopy && errors['product.promotion.offerCopy']}
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              disabled={!isPaused(values)}
            />
          </Box>
        )}
        {isHotPrice && (
          <Box mt={2}>
            <ScutiInput
              name="product.promotion.discount"
              placeholder="Discount %"
              value={values.product?.promotion?.discount || ''}
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              // @ts-ignore
              error={touched.product?.promotion?.discount && errors['product.promotion.discount']}
              type="number"
              disabled={!isPaused(values)}
            />
          </Box>
        )}
      </CardContent>
    </Card>
  );
};
