import React from 'react';
import { FormikProps } from 'formik';
import { ShopEditDetails } from './ShopEditDetails';
import { ShopShipping } from './ShopShipping';
import { Grid } from '@material-ui/core';
import { ShopForm } from './ShopEditForm.utils';

interface Props {
  formik: FormikProps<ShopForm>;
}

export const ShopEditForm: React.FC<Props> = ({ formik }) => {
  return (
    <Grid container spacing={2}>
      <Grid container item spacing={2}>
        <Grid item sm={12} md={6} lg={6}>
          <ShopEditDetails {...formik} />
        </Grid>
        <Grid item sm={12} md={6} lg={6}>
          <ShopShipping {...formik} />
        </Grid>
      </Grid>
    </Grid>
  );
};
