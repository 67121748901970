import React from 'react';
import { PrivateRoute } from 'components/PrivateRoute';
import { Switch } from 'react-router-dom';
import { settingsRoutes } from 'routing/routes';
import { NavTabs } from 'components/NavTabs';
import { AccountSettingsPage } from './AccountSettingsPage';
import { useAppStore } from 'store/app-store.hook';
import { useLocation, Redirect } from 'react-router';
import { hasUserRole } from 'utils/roles.utils';
import { Role } from 'types/__generated__/types';

const settingsTabs = (tabs: string[]) => {
  return tabs.map(path => {
    const name = path.split('/').slice(-1)[0] || '';
    return {
      linkTo: path,
      name: name.replace('-', ' '),
      active: (pathname: string) => pathname.split('/').slice(-1)[0] === name,
    };
  });
};

export const SettingsRoutes: React.FC = () => {
  const { pathname } = useLocation();
  const { permissions } = useAppStore();

  if (pathname === settingsRoutes.SETTINGS) {
    return <Redirect to={settingsRoutes.ACCOUNT_SETTINGS} />;
  }
  const tabs = hasUserRole(permissions.userRoles, Role.ScutiAdmin)
    ? [settingsRoutes.ACCOUNT_SETTINGS]
    : [settingsRoutes.ACCOUNT_SETTINGS];
  return (
    <>
      <h1>Settings</h1>
      <NavTabs tabs={settingsTabs(tabs)} />
      <Switch>
        <PrivateRoute exact path={settingsRoutes.ACCOUNT_SETTINGS} component={AccountSettingsPage} />
      </Switch>
    </>
  );
};
