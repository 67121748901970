import React, { ChangeEventHandler } from 'react';
import { Button } from 'reactstrap';
import { Box, Card, CardContent, CardHeader, Grid, Typography } from '@material-ui/core';
import { ScutiInput } from 'components/material/ScutiInput';
import { OrderFragment } from '../__generated__/orders.hooks';
import { addDecimals } from 'utils/string.utils';
import noPicUrl from 'assets/svgs/no-pic-sm.svg';
import moment from 'moment';

interface Props {
  order: OrderFragment;
  onCancelGroup: () => void;
  addTrackingNumber: (trackingNumber: string) => void;
}

export const OrderDetails: React.FC<Props> = ({ order, addTrackingNumber }) => {
  const [trackingNumber, setTrackingNumber] = React.useState('');

  const onTrackingNumberChange: ChangeEventHandler<{ value: string }> = e => setTrackingNumber(e.target.value);
  const onAddTrackingNumber = () => addTrackingNumber(trackingNumber);
  const shoppingMethod = order.items[0]?.fulfillmentMethod.name;

  return (
    <Card>
      <CardHeader title="Order details" />
      <CardContent>
        <Grid container spacing={6}>
          <Grid item xs={12} md={12} lg={6}>
            <Box mt={1} display="flex" justifyContent="space-between">
              <Box fontWeight="bold">Order Date:</Box>
              <Box>{moment(order.createdAt).format('MM/DD/YYYY')}</Box>
            </Box>
            <Box mt={2}>
              <Typography variant="h6">Items</Typography>
            </Box>
            {order.items.map((orderItem, index) => (
              <Box mt={1} key={index}>
                <Box mt={1} display="flex" justifyContent="space-between">
                  <img width="75px" height="75px" src={orderItem.product.variant.image || noPicUrl} alt="" />
                </Box>
                <Box mt={1} display="flex" justifyContent="space-between">
                  <Box fontWeight="bold">SKU: </Box>
                  <Box>{orderItem.product.variant.sku}</Box>
                </Box>
                <Box mt={1} display="flex" justifyContent="space-between">
                  <Box fontWeight="bold">{orderItem.product.name}</Box>
                  <Box className="text-right">${addDecimals(orderItem.amount)}</Box>
                </Box>
                <Box mt={1} display="flex" justifyContent="space-between">
                  <Box></Box>
                </Box>
                <Box mt={1} display="flex" justifyContent="space-between">
                  <Box fontWeight="bold">Quantity: {orderItem.quantity}</Box>
                </Box>
              </Box>
            ))}
          </Grid>
          <Grid item xs={12} md={12} lg={6}>
            <Grid item>
              <Typography variant="h6">Shipping address</Typography>
              <Box mt={2}>{order.user.fullName}</Box>
              <Box>{order.shippingInformation?.address1}</Box>
              <Box>
                {order.shippingInformation?.city}, {order.shippingInformation?.state}
              </Box>
              <Box>{order.shippingInformation?.zipCode}</Box>
              <Box>{order.shippingInformation?.country}</Box>
              <Box mt={4}>
                <Typography variant="h6">Shopping Method</Typography>
                <Box>{shoppingMethod}</Box>
              </Box>
            </Grid>

            <Box mt={2} mb={2}>
              <Box mb={1}>
                <Typography variant="h6">Tracking number</Typography>
              </Box>
              <ScutiInput
                name="trackingNumber"
                placeholder="Tracking number"
                value={trackingNumber}
                required
                onChange={onTrackingNumberChange}
              />
            </Box>
            <Button color="light" onClick={onAddTrackingNumber} disabled={!trackingNumber}>
              Add
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
