import { CampaignBudgetOption, CampaignType } from 'types/__generated__/types';
import { FormValidationSchema, transformedNumber } from 'utils/form-utils';
import { getImgAspectRatio, uploadImage } from 'utils/upload-image.utils';
import * as Yup from 'yup';
import { CampaignForm } from './CampaignForm.utils';

export const productCampaignFormDefaults = (): CampaignForm => ({
  name: '',
  category: null,
  product: {
    product: {
      id: '',
      name: '',
    },
  },
  type: CampaignType.Product,
  budget: {
    option: CampaignBudgetOption.Normal,
    maxSpend: 0,
    maxDailySpend: 0,
    limitOfImpressions: 0,
  },
  demographic: {
    gender: null,
    minAge: 0,
    maxAge: 100,
  },
  duration: {
    start: null,
    end: null,
    inventory: null,
    runUntilStockZero: null,
  },
  media: {
    banner: null,
    vertical: null,
    tile: null,
  },
  extras: { moreExposure: false },
});

const mapMedia = async (media: CampaignForm['media']) => {
  if (!media) return media;
  const { vertical, tile } = media;
  return {
    vertical:
      vertical && typeof vertical === 'object'
        ? (await uploadImage(vertical, { height: 600, width: 300 })).url
        : vertical,
    tile: tile && typeof tile === 'object' ? (await uploadImage(tile, { height: 250, width: 300 })).url : tile,
  };
};

export const mapProductCampaignToInput = async (campaign: CampaignForm, shopId: string) => {
  return {
    ...campaign,
    product: null,
    shopId,
    game: {},
    reward: null,
    location: {},
    media: campaign.media && (await mapMedia(campaign.media)),
  };
};

const FILE_SIZE = 3 * 1024 * 1024;
const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png'];

export const validationSchema = (): FormValidationSchema<CampaignForm> => {
  return Yup.object().shape({
    type: Yup.mixed().oneOf([CampaignType.Product]),
    name: Yup.string()
      .min(3, 'Name should be at least 3 characters.')
      .max(25, 'Name can be 25 characters or less.')
      .required('Name is required!'),
    product: Yup.object()
      .shape({
        product: Yup.object()
          .shape({
            id: Yup.string()
              .min(2, 'Product is required')
              .required('Product is required'),
            name: Yup.string(),
          })
          .required('Product is required'),
      })
      .nullable()
      .required('Product is required!'),
    media: Yup.object()
      .shape({
        vertical: Yup.mixed()
          .nullable()
          .test('fileSize', 'File too large', value => {
            if (!value) return true;
            return typeof value === 'string' || value.size <= FILE_SIZE;
          })
          .test('fileFormat', 'Unsupported Format', value => {
            if (!value) return true;
            return typeof value === 'string' || SUPPORTED_FORMATS.includes(value.type);
          })
          .test('aspectRatio', 'Wrong aspect ratio', async value => {
            if (!value) return true;
            if (typeof value === 'string') return true;
            const [width, height] = await getImgAspectRatio(value);
            return width === 1 && height === 2;
          }),
        tile: Yup.mixed()
          .nullable()
          .test('fileSize', 'File too large', value => {
            if (!value) return true;
            return typeof value === 'string' || value.size <= FILE_SIZE;
          })
          .test('fileFormat', 'Unsupported Format', value => {
            if (!value) return true;
            return typeof value === 'string' || SUPPORTED_FORMATS.includes(value.type);
          })
          .test('aspectRatio', 'Wrong aspect ratio', async value => {
            if (!value) return true;
            if (typeof value === 'string') return true;
            const [width, height] = await getImgAspectRatio(value);
            return width === 6 && height === 5;
          }),
      })
      .nullable(),
    demographic: Yup.object().shape({
      gender: Yup.string().nullable(),
      minAge: Yup.number()
        .min(0)
        .max(100)
        .required(),
      maxAge: Yup.number()
        .min(0)
        .max(100)
        .required(),
    }),
    duration: Yup.object().shape({
      start: Yup.date().nullable(),
      end: Yup.date().nullable(),
      runUntilStockZero: Yup.boolean().nullable(),
      inventory: Yup.number().nullable(),
    }),
    location: Yup.object()
      .shape({
        country: Yup.string().nullable(),
        states: Yup.array()
          .of(Yup.string())
          .nullable(),
      })
      .nullable(),
    extras: Yup.object().shape({
      moreExposure: Yup.boolean(),
    }),
    budget: Yup.object().shape({
      option: Yup.mixed()
        .oneOf(Object.values(CampaignBudgetOption))
        .required(),
      maxSpend: transformedNumber.moreThan(0, 'Cannot be 0 or less').required('Max Spend is required!'),
      maxDailySpend: transformedNumber.moreThan(0, 'Cannot be 0 or less').required('Daily Max Spend is required!'),
      limitOfImpressions: transformedNumber.min(6, 'Cannot be less then 6').required('Daily Max Spend is required!'),
    }),
  });
};
