import React from 'react';
import { ScutiInput } from 'components/material/ScutiInput';
import { FormikProps } from 'formik';
import { ShopForm } from './ShopEditForm.utils';
import { ScutiImgInput } from 'components/material/ScutiImgInput';
import { Box, Card, CardContent, CardHeader, Typography } from '@material-ui/core';
import ImageIcon from '@mui/icons-material/Image';

export const ShopEditDetails: React.FC<FormikProps<ShopForm>> = ({
  values,
  touched,
  errors,
  setFieldValue,
  handleChange,
  handleBlur,
}) => {
  return (
    <Card>
      <CardHeader title="Details" />
      <CardContent>
        <Box display="flex" justifyContent="between">
          <Box flex="1 0 auto">
            <label>Shop Thumbnail</label>
            <Box color="text.secondary">
              <Typography variant="body2">The thumbnail image for your game.</Typography>
              <Typography variant="body2">Upload a 84 x 84px or 1:1 aspect ratio image.</Typography>
            </Box>
          </Box>
          <Box ml={1} flex="0 0 auto" width="4rem" height="4rem">
            <ScutiImgInput
              image={values.thumbnail}
              onChange={file => setFieldValue('thumbnail', file, false)}
              error={!!errors.thumbnail}
            >
              <ImageIcon color="secondary" style={{ width: '100%', height: '100%' }} />
            </ScutiImgInput>
          </Box>
        </Box>
        <Box mt={2}>
          <ScutiInput
            title="Title"
            name="name"
            placeholder="Title of your shop"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.name}
            error={values.id ? errors['name'] : touched.name && errors['name']}
          />
        </Box>
        <Box mt={2}>
          <ScutiInput
            title="Description"
            name="description"
            multiline
            rows={8}
            placeholder="Description of your shop"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.description || ''}
            error={values.id ? errors['description'] : touched.description && errors['description']}
          />
        </Box>
        <Box mt={2}>
          <ScutiInput
            title="Shopify"
            name="shopifyName"
            placeholder="Name on shopify"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.shopifyName || ''}
            error={values.id ? errors['shopifyName'] : touched.shopifyName && errors['shopifyName']}
          />
        </Box>
      </CardContent>
    </Card>
  );
};
