import React, { Suspense } from 'react';
import { Container } from 'reactstrap';
import { AppHeader, AppSidebar } from '@coreui/react';
import { observer } from 'mobx-react-lite';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorFallback } from 'layouts/ErrorFallback';
import { DefaultHeader } from './DefaultHeader';

const LeftNavigation = React.lazy(() =>
  import('./LeftNavigation').then(module => ({
    default: module.LeftNavigation,
  })),
);

export const DefaultLayout: React.FC = observer(({ children }) => {
  const loading = () => (
    <div className="animated fadeIn pt-1 text-center">
      <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
  );
  return (
    <div className="app">
      <AppHeader fixed>
        <DefaultHeader />
      </AppHeader>
      <div className="app-body">
        <AppSidebar fixed display="lg">
          <Suspense fallback={loading()}>
            <LeftNavigation />
          </Suspense>
        </AppSidebar>
        <main className="main">
          <Container fluid>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <Suspense fallback={loading()}>{children}</Suspense>
            </ErrorBoundary>
          </Container>
        </main>
      </div>
    </div>
  );
});

export const withDefaultLayout = (Page: React.ComponentType) => () => (
  <DefaultLayout>
    <Page />
  </DefaultLayout>
);
