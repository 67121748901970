import * as Types from '../../../../types/__generated__/types';

import { ApolloError } from 'apollo-client';
import { useQuery, UseQueryOptions, useMutation, UseMutationOptions } from 'react-query';
import { fetcher } from 'api';
export type OrganizationFullFragment = {
  id: string;
  address1: string;
  address2?: Types.Maybe<string>;
  city: string;
  zipCode: string;
  country: string;
  state?: Types.Maybe<string>;
  name: string;
  website?: Types.Maybe<string>;
  category: string;
  phone?: Types.Maybe<string>;
  identificationNumber: string;
  dbaName?: Types.Maybe<string>;
  owner: { id: string; fullName?: Types.Maybe<string>; email: string };
};

export type OrganizationQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;

export type OrganizationQuery = { organization: OrganizationFullFragment };

export type UpdateOrganizationMutationVariables = Types.Exact<{
  input: Types.UpdateOrganizationInput;
}>;

export type UpdateOrganizationMutation = { updateOrganization: OrganizationFullFragment };

export type CreateOrganizationMutationVariables = Types.Exact<{
  input: Types.OrganizationInput;
}>;

export type CreateOrganizationMutation = { createOrganization: OrganizationFullFragment };

export const OrganizationFullFragmentDoc = `
    fragment OrganizationFull on Organization {
  id
  address1
  address2
  city
  zipCode
  country
  state
  name
  website
  category
  phone
  identificationNumber
  dbaName
  owner {
    id
    fullName
    email
  }
}
    `;
export const OrganizationDocument = `
    query Organization($id: String!) {
  organization(id: $id) {
    ...OrganizationFull
  }
}
    ${OrganizationFullFragmentDoc}`;
export const useOrganizationQuery = <TData = OrganizationQuery, TError = ApolloError>(
  variables: OrganizationQueryVariables,
  options?: UseQueryOptions<OrganizationQuery, TError, TData>,
) =>
  useQuery<OrganizationQuery, TError, TData>(
    ['Organization', variables],
    fetcher<OrganizationQuery, OrganizationQueryVariables>(OrganizationDocument, variables),
    options,
  );
export const UpdateOrganizationDocument = `
    mutation UpdateOrganization($input: UpdateOrganizationInput!) {
  updateOrganization(input: $input) {
    ...OrganizationFull
  }
}
    ${OrganizationFullFragmentDoc}`;
export const useUpdateOrganizationMutation = <TError = ApolloError, TContext = unknown>(
  options?: UseMutationOptions<UpdateOrganizationMutation, TError, UpdateOrganizationMutationVariables, TContext>,
) =>
  useMutation<UpdateOrganizationMutation, TError, UpdateOrganizationMutationVariables, TContext>(
    (variables?: UpdateOrganizationMutationVariables) =>
      fetcher<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>(UpdateOrganizationDocument, variables)(),
    options,
  );
export const CreateOrganizationDocument = `
    mutation CreateOrganization($input: OrganizationInput!) {
  createOrganization(input: $input) {
    ...OrganizationFull
  }
}
    ${OrganizationFullFragmentDoc}`;
export const useCreateOrganizationMutation = <TError = ApolloError, TContext = unknown>(
  options?: UseMutationOptions<CreateOrganizationMutation, TError, CreateOrganizationMutationVariables, TContext>,
) =>
  useMutation<CreateOrganizationMutation, TError, CreateOrganizationMutationVariables, TContext>(
    (variables?: CreateOrganizationMutationVariables) =>
      fetcher<CreateOrganizationMutation, CreateOrganizationMutationVariables>(CreateOrganizationDocument, variables)(),
    options,
  );
