import React from 'react';
import { Route, Switch } from 'react-router-dom';
import {
  LoginPage,
  ForgotPasswordPage,
  ResetPasswordPage,
  CheckEmailPage,
  RegisterPhonePage,
  TwoStepVerificationPhonePage,
  RegistrationPage,
  EmailVerifiedPage,
  PasswordChangedPage,
} from 'pages';
import { PrivateRoute } from 'components/PrivateRoute';
import { observer } from 'mobx-react-lite';
import { withWithoutAuthLayout } from 'layouts/WithoutAuthLayout';
import { withDefaultLayout } from 'layouts/DefaultLayout';
import { ShopsRoutes } from '../pages/Shops';
import { CampaignsRoutes } from '../pages/Campaigns';
import { GamesRoutes } from 'pages/Games';
import { SettingsRoutes } from 'pages/Settings';
import { DashboardRoutes } from 'pages/Dashboard';
import {
  appRoutes,
  shopsRoutes,
  productsRoutes,
  campaignRoutes,
  gamesRoutes,
  settingsRoutes,
  dashboardRoutes,
  getRoutesValues,
  organizationsRoutes,
  productListingRoutes,
} from './routes';
import { ProductsRoutes } from 'pages/Products';
import { OrdersRoutes } from 'pages/Orders';
import { ordersRoutes } from 'routing';
import { ProductListingRoutes } from 'pages/ProductListing';
import { OrganizationsRoutes } from 'pages/Organizations';
import { Page404 } from 'pages/404';
import { EntityInvite } from 'pages/Invites';
import LookerStudioPage from '../pages/Live';

export const AppRouter = observer(() => {
  return (
    <Switch>
      <Route exact path={appRoutes.LOGIN} component={withWithoutAuthLayout(LoginPage)} />
      <Route exact path={appRoutes.REGISTRATION} component={withWithoutAuthLayout(RegistrationPage)} />
      <Route exact path={appRoutes.FORGOT_PASSWORD} component={withWithoutAuthLayout(ForgotPasswordPage)} />
      <Route exact path={appRoutes.RESET_PASSWORD} component={withWithoutAuthLayout(ResetPasswordPage)} />
      <Route exact path={appRoutes.CHECK_EMAIL} component={withWithoutAuthLayout(CheckEmailPage)} />
      <Route exact path={appRoutes.REGISTER_PHONE} component={withWithoutAuthLayout(RegisterPhonePage)} />
      <Route exact path={appRoutes.VERIFY_PHONE} component={withWithoutAuthLayout(TwoStepVerificationPhonePage)} />
      <Route exact path={appRoutes.EMAIL_VERIFIED} component={withWithoutAuthLayout(EmailVerifiedPage)} />
      <Route exact path={appRoutes.PASSWORD_CHANGED} component={withWithoutAuthLayout(PasswordChangedPage)} />
      <PrivateRoute exact path={appRoutes.LOOKER_STUDIO} component={withDefaultLayout(LookerStudioPage)} />
      <PrivateRoute exact path={appRoutes.HOME} component={withDefaultLayout(DashboardRoutes)} />
      <PrivateRoute exact path={getRoutesValues(dashboardRoutes)} component={withDefaultLayout(DashboardRoutes)} />
      <PrivateRoute path={getRoutesValues(organizationsRoutes)} component={withDefaultLayout(OrganizationsRoutes)} />
      <PrivateRoute path={getRoutesValues(shopsRoutes)} component={withDefaultLayout(ShopsRoutes)} />
      <PrivateRoute path={getRoutesValues(ordersRoutes)} component={withDefaultLayout(OrdersRoutes)} />
      <PrivateRoute path={getRoutesValues(productsRoutes)} component={withDefaultLayout(ProductsRoutes)} />
      <PrivateRoute path={getRoutesValues(productListingRoutes)} component={withDefaultLayout(ProductListingRoutes)} />
      <PrivateRoute path={getRoutesValues(campaignRoutes)} component={withDefaultLayout(CampaignsRoutes)} />
      <PrivateRoute path={getRoutesValues(gamesRoutes)} component={withDefaultLayout(GamesRoutes)} />
      <PrivateRoute exact path={getRoutesValues(settingsRoutes)} component={withDefaultLayout(SettingsRoutes)} />
      <Route exact path={'/entity-invite'} component={withWithoutAuthLayout(EntityInvite)} />
      <Route path="*" component={withWithoutAuthLayout(Page404)} />
    </Switch>
  );
});
