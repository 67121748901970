import React from 'react';
import { Box, Card, CardContent, CardHeader } from '@material-ui/core';
import { ScutiInput } from 'components/material/ScutiInput';
import { FormikProps } from 'formik';
import { ShopForm } from './ShopEditForm.utils';

export const ShopShipping: React.FC<FormikProps<ShopForm>> = ({
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  setFieldValue,
  setFieldTouched,
}) => {
  return (
    <Card>
      <CardHeader title="Shipping" />
      <CardContent>
        <Box>
          <ScutiInput
            title="Flat Rate"
            type="decimal"
            name="flatShippingRate"
            placeholder="Flat shipping rate"
            onChange={e => {
              const { value } = e.target;
              if (Number(value) === 0) {
                setFieldValue('freeShippingThreshold', undefined, true);
                setFieldTouched('freeShippingThreshold', true);
              }
              handleChange(e);
            }}
            onBlur={handleBlur}
            InputProps={{ inputProps: { min: 0, max: 100, step: 0.01 } }}
            value={values.flatShippingRate}
            error={touched.flatShippingRate && errors['flatShippingRate']}
          />
        </Box>
        <Box mt={2}>
          <ScutiInput
            fullWidth
            size="small"
            variant="outlined"
            type="number"
            title="Free shipping threshold"
            name="freeShippingThreshold"
            placeholder="Free Shipping Threshold"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.freeShippingThreshold || ''}
            error={touched.freeShippingThreshold && errors['freeShippingThreshold']}
            disabled={values.flatShippingRate === 0}
          />
        </Box>
        <Box mt={2}>
          <ScutiInput
            fullWidth
            size="small"
            variant="outlined"
            title="Return Policy"
            name="returnPolicy"
            placeholder="Return Policy"
            multiline
            rows={8}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.returnPolicy}
            error={touched.returnPolicy && errors['returnPolicy']}
          />
        </Box>
      </CardContent>
    </Card>
  );
};
