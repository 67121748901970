import React from 'react';
import { Box, Card, CardContent, CardHeader } from '@material-ui/core';
import { ScutiInput } from 'components/material/ScutiInput';
import { FormikProps } from 'formik';
import { OrganizationForm } from './OrganizationEditForm.utils';

export const OrganizationOwnerDetails: React.FC<FormikProps<OrganizationForm>> = props => {
  const { values, touched, errors, handleChange, handleBlur } = props;
  return (
    <Card>
      <CardHeader title="Owner Details" />
      <CardContent>
        <ScutiInput
          title="Full Name *"
          name="owner.fullName"
          placeholder="Full Name"
          onChange={handleChange}
          onBlur={handleBlur}
          value={values?.owner?.fullName || ''}
          error={(values.id || touched?.owner?.fullName) && (errors as any)['owner.fullName']}
        />
        <Box mt={2}>
          <ScutiInput
            title="Email *"
            name="owner.email"
            placeholder="Email"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values?.owner?.email || ''}
            error={(values.id || touched?.owner?.email) && (errors as any)['owner.email']}
          />
        </Box>
      </CardContent>
    </Card>
  );
};
