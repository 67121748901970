import React from 'react';
import { Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';
import { CampaignType } from 'types/__generated__/types';

interface Props {
  linkBack: string;
  onSelect: (params: CampaignType | null) => void;
}

export const CreateCampaign: React.FC<Props> = ({ linkBack, onSelect }) => {
  const selectCampaignType = React.useCallback((type: CampaignType | null) => () => onSelect(type), [onSelect]);
  return (
    <div className="animated fadeIn popup-holder d-flex justify-content-center align-items-center">
      <div className="campaign-creation">
        <Link className="btn btn-back" to={linkBack}></Link>
        <div className="heading">
          <h2>New Campaign</h2>
          <p>Please select the campaign type you’d like to create below.</p>
        </div>
        <div className="campaign-types">
          <Row className="gap-md">
            <Col sm={6}>
              <div className="campaign-types-item d-flex wh-100">
                <div className="campaign-types-link d-flex wh-100" onClick={selectCampaignType(CampaignType.Product)}>
                  <i className="campaign-types-icon align-self-center">
                    <span className="icon-productcampaign"></span>
                  </i>
                  <div className="campaign-types-title">
                    <strong>Product Campaign</strong>
                    <em>Promote your product, while engaging with players</em>
                  </div>
                </div>
              </div>
            </Col>
            <Col sm={6}>
              <div className="campaign-types-item d-flex wh-100">
                <div className="campaign-types-link d-flex wh-100" onClick={selectCampaignType(CampaignType.Video)}>
                  <i className="campaign-types-icon align-self-center">
                    <span className="icon-videopromo"></span>
                  </i>
                  <div className="campaign-types-title">
                    <strong>Video Promo</strong>
                    <em>Promote your promotional video with a targeted campaign</em>
                  </div>
                </div>
              </div>
            </Col>
            <Col sm={6}>
              <div className="campaign-types-item d-flex wh-100">
                <div className="campaign-types-link d-flex wh-100" onClick={selectCampaignType(CampaignType.Banner)}>
                  <i className="campaign-types-icon align-self-center">
                    <span className="icon-bannerad"></span>
                  </i>
                  <div className="campaign-types-title">
                    <strong>Banner Ad</strong>
                    <em>Capture your audience with a branded banner ad</em>
                  </div>
                </div>
              </div>
            </Col>
            <Col sm={6}>
              <div className="campaign-types-item d-flex wh-100">
                <div
                  className="campaign-types-link d-flex wh-100"
                  onClick={selectCampaignType(CampaignType.AppDownload)}
                >
                  <i className="campaign-types-icon align-self-center">
                    <span className="icon-appdownloadcampaign"></span>
                  </i>
                  <div className="campaign-types-title">
                    <strong>App Download</strong>
                    <em>Promote your app with a targeted campaign</em>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};
