import * as Types from '../../../../types/__generated__/types';

import { ApolloError } from 'apollo-client';
import { useQuery, UseQueryOptions, useMutation, UseMutationOptions } from 'react-query';
import { fetcher } from 'api';
export type ShopItemFgFragment = {
  id: string;
  name: string;
  description?: Types.Maybe<string>;
  shopifyName?: Types.Maybe<string>;
  thumbnail?: Types.Maybe<string>;
  admins: Array<{ id: string; email: string; roles: Array<Types.Role>; fullName?: Types.Maybe<string> }>;
};

export type ShopItemsQueryVariables = Types.Exact<{
  paging?: Types.Maybe<Types.PagingInput>;
  filters?: Types.Maybe<Array<Types.FilterInput> | Types.FilterInput>;
  sorting?: Types.Maybe<Array<Types.SortFieldInput> | Types.SortFieldInput>;
}>;

export type ShopItemsQuery = {
  shops: {
    paging: { offset: number; limit: number; totalCount: number };
    sorting?: Types.Maybe<Array<{ name: string; dir: string }>>;
    nodes: Array<ShopItemFgFragment>;
  };
};

export type DeleteShopMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;

export type DeleteShopMutation = { deleteShop?: Types.Maybe<string> };

export const ShopItemFgFragmentDoc = `
    fragment ShopItemFG on Shop {
  id
  name
  description
  shopifyName
  thumbnail
  admins {
    id
    email
    roles
    fullName
  }
}
    `;
export const ShopItemsDocument = `
    query ShopItems($paging: PagingInput, $filters: [FilterInput!], $sorting: [SortFieldInput!]) {
  shops(paging: $paging, filters: $filters, sorting: $sorting) {
    paging {
      offset
      limit
      totalCount
    }
    sorting {
      name
      dir
    }
    nodes {
      ...ShopItemFG
    }
  }
}
    ${ShopItemFgFragmentDoc}`;
export const useShopItemsQuery = <TData = ShopItemsQuery, TError = ApolloError>(
  variables?: ShopItemsQueryVariables,
  options?: UseQueryOptions<ShopItemsQuery, TError, TData>,
) =>
  useQuery<ShopItemsQuery, TError, TData>(
    ['ShopItems', variables],
    fetcher<ShopItemsQuery, ShopItemsQueryVariables>(ShopItemsDocument, variables),
    options,
  );
export const DeleteShopDocument = `
    mutation deleteShop($id: String!) {
  deleteShop(id: $id)
}
    `;
export const useDeleteShopMutation = <TError = ApolloError, TContext = unknown>(
  options?: UseMutationOptions<DeleteShopMutation, TError, DeleteShopMutationVariables, TContext>,
) =>
  useMutation<DeleteShopMutation, TError, DeleteShopMutationVariables, TContext>(
    (variables?: DeleteShopMutationVariables) =>
      fetcher<DeleteShopMutation, DeleteShopMutationVariables>(DeleteShopDocument, variables)(),
    options,
  );
