import * as Types from '../../../../types/__generated__/types';

import { ApolloError } from 'apollo-client';
import { useQuery, UseQueryOptions } from 'react-query';
import { fetcher } from 'api';
export type OrganizationShopsQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;

export type OrganizationShopsQuery = {
  shops: {
    nodes: Array<{
      id: string;
      name: string;
      description?: Types.Maybe<string>;
      shopifyName?: Types.Maybe<string>;
      admins: Array<{ fullName?: Types.Maybe<string> }>;
    }>;
  };
};

export type OrganizationGamesQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;

export type OrganizationGamesQuery = {
  games: {
    nodes: Array<{
      id: string;
      name: string;
      thumbnail?: Types.Maybe<string>;
      category: string;
      owner: { email: string };
      admins: Array<{ fullName?: Types.Maybe<string> }>;
    }>;
  };
};

export const OrganizationShopsDocument = `
    query OrganizationShops($id: String!) {
  shops(paging: {offset: 0, limit: 50}, sorting: [], filters: [{name: "organizationId", operator: EQ, value: [$id]}]) {
    nodes {
      id
      name
      description
      shopifyName
      admins {
        fullName
      }
    }
  }
}
    `;
export const useOrganizationShopsQuery = <TData = OrganizationShopsQuery, TError = ApolloError>(
  variables: OrganizationShopsQueryVariables,
  options?: UseQueryOptions<OrganizationShopsQuery, TError, TData>,
) =>
  useQuery<OrganizationShopsQuery, TError, TData>(
    ['OrganizationShops', variables],
    fetcher<OrganizationShopsQuery, OrganizationShopsQueryVariables>(OrganizationShopsDocument, variables),
    options,
  );
export const OrganizationGamesDocument = `
    query OrganizationGames($id: String!) {
  games(paging: {offset: 0, limit: 50}, sorting: [], filters: [{name: "organizationId", operator: EQ, value: [$id]}]) {
    nodes {
      id
      name
      thumbnail
      category
      owner {
        email
      }
      admins {
        fullName
      }
    }
  }
}
    `;
export const useOrganizationGamesQuery = <TData = OrganizationGamesQuery, TError = ApolloError>(
  variables: OrganizationGamesQueryVariables,
  options?: UseQueryOptions<OrganizationGamesQuery, TError, TData>,
) =>
  useQuery<OrganizationGamesQuery, TError, TData>(
    ['OrganizationGames', variables],
    fetcher<OrganizationGamesQuery, OrganizationGamesQueryVariables>(OrganizationGamesDocument, variables),
    options,
  );
