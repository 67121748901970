import * as Types from '../../../../types/__generated__/types';

import { ApolloError } from 'apollo-client';
import { useQuery, UseQueryOptions } from 'react-query';
import { fetcher } from 'api';
export type OrganizationItemFragment = {
  id: string;
  name: string;
  website?: Types.Maybe<string>;
  category: string;
  owner: { fullName?: Types.Maybe<string> };
};

export type OrganizationsQueryVariables = Types.Exact<{
  paging?: Types.Maybe<Types.PagingInput>;
  sorting?: Types.Maybe<Array<Types.SortFieldInput> | Types.SortFieldInput>;
  filters?: Types.Maybe<Array<Types.FilterInput> | Types.FilterInput>;
}>;

export type OrganizationsQuery = {
  organizations: {
    sorting?: Types.Maybe<Array<{ name: string; dir: string }>>;
    paging: { offset: number; limit: number; totalCount: number };
    nodes: Array<OrganizationItemFragment>;
  };
};

export const OrganizationItemFragmentDoc = `
    fragment OrganizationItem on Organization {
  id
  name
  website
  category
  owner {
    fullName
  }
}
    `;
export const OrganizationsDocument = `
    query Organizations($paging: PagingInput, $sorting: [SortFieldInput!], $filters: [FilterInput!]) {
  organizations(paging: $paging, sorting: $sorting, filters: $filters) {
    sorting {
      name
      dir
    }
    paging {
      offset
      limit
      totalCount
    }
    nodes {
      ...OrganizationItem
    }
  }
}
    ${OrganizationItemFragmentDoc}`;
export const useOrganizationsQuery = <TData = OrganizationsQuery, TError = ApolloError>(
  variables?: OrganizationsQueryVariables,
  options?: UseQueryOptions<OrganizationsQuery, TError, TData>,
) =>
  useQuery<OrganizationsQuery, TError, TData>(
    ['Organizations', variables],
    fetcher<OrganizationsQuery, OrganizationsQueryVariables>(OrganizationsDocument, variables),
    options,
  );
