import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { App } from './App';
import { hot } from 'react-hot-loader/root';

const WithHotReload = process.env.NODE_ENV === 'production' ? App : hot(App);
ReactDOM.render(<WithHotReload />, document.getElementById('root'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
