import React from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory, useLocation, useParams } from 'react-router';
import { CreateCampaign } from './CreateCampaign';
import { Loader } from 'components/Loader';
import { campaignRoutes } from 'routing';
import { Box } from '@material-ui/core';
import { BannerCampaignEditForm, ProductCampaignEditForm, VideoCampaignEditForm } from './CampaignEditForms';
import queryString from 'query-string';
import { useToasts } from 'react-toast-notifications';
import {
  useCampaignQuery,
  useCreateCampaignMutation,
  useToggleCampaignMutation,
  useUpdateCampaignMutation,
} from './__generated__/campaign-edit-page.hooks';
import { mapProductCampaignToInput } from './CampaignEditForms/ProductCampaignEditForm.utils';
import { mapVideoCampaignToInput } from './CampaignEditForms/VideoCampaignEditForm.utils';
import { mapBannerCampaignToInput } from './CampaignEditForms/BannerCampaignEditForm.utils';
import { CampaignForm } from './CampaignEditForms/CampaignForm.utils';
import { hasId } from 'types/util-types';
import { CampaignType, ToggleCampaignInput } from 'types/__generated__/types';
import { mapAppDownloadCampaignToInput } from './CampaignEditForms/AppDownloadCampaignEditForm.utils';
import { AppDownloadCampaignEditForm } from './CampaignEditForms/AppDownloadCampaignEditForm';

const saveHandler = {
  [CampaignType.Product]: mapProductCampaignToInput,
  [CampaignType.Video]: mapVideoCampaignToInput,
  [CampaignType.Banner]: mapBannerCampaignToInput,
  [CampaignType.AppDownload]: mapAppDownloadCampaignToInput,
};

export const CampaignEditPage: React.FC = observer(() => {
  const { campaignId } = useParams<{ campaignId: string }>();
  const { search } = useLocation();
  const searchQuery = queryString.parse(search);
  const [campaignType, setCampaignType] = React.useState<CampaignType | null>(null);
  const history = useHistory();
  const { addToast } = useToasts();

  const campaignQuery = useCampaignQuery({ id: campaignId }, { enabled: !!campaignId });
  const campaign = campaignQuery.data?.campaign;
  const shopId = (searchQuery.shopId as string) || campaign?.shop?.id;

  const useUpdateCampaign = useUpdateCampaignMutation();
  const useCreateCampaign = useCreateCampaignMutation();

  const campaignToRender = campaignType || campaign?.type;
  const isNew = !(campaignType || campaignId);

  React.useEffect(() => {
    if (campaignQuery.error) addToast(campaignQuery.error.message, { appearance: 'error', autoDismiss: false });
  }, [addToast, campaignQuery.error]);

  const saveOrUpdate = React.useCallback(
    async ({ shop, status, ...campaign }: CampaignForm) => {
      const handler = saveHandler[campaign.type];
      if (hasId(campaign)) {
        try {
          await useUpdateCampaign.mutateAsync({
            // @ts-ignore
            input: { ...(await handler(campaign, shopId as string)), id: campaign.id },
          });
          await campaignQuery.refetch();
          addToast('Campaign has been updated!', { appearance: 'success', autoDismiss: true });
        } catch (e) {
          addToast(e.message, { appearance: 'error', autoDismiss: false });
        }
      } else {
        try {
          await useCreateCampaign.mutateAsync({
            // @ts-ignore
            input: await handler(campaign, shopId as string),
          });
          addToast('Campaign has been created!', { appearance: 'success', autoDismiss: true });
          history.push(campaignRoutes.CAMPAIGNS);
        } catch (e) {
          addToast(e.message, { appearance: 'error', autoDismiss: false });
        }
      }
    },
    [addToast, campaignQuery, history, shopId, useCreateCampaign, useUpdateCampaign],
  );

  const toggleCampaignMutation = useToggleCampaignMutation();

  const onToggleCampaign = React.useCallback(
    async (input: ToggleCampaignInput) => {
      try {
        await toggleCampaignMutation.mutateAsync({ input });
        await campaignQuery.refetch();
        addToast(`Campaign has been ${input.isPaused ? 'paused' : 'unpaused'}!`, {
          appearance: 'success',
          autoDismiss: true,
        });
      } catch (e) {
        addToast(e.message, { appearance: 'error', autoDismiss: false });
      }
    },
    [addToast, campaignQuery, toggleCampaignMutation],
  );

  const isLoading = campaignQuery.isLoading;

  if (isLoading) return <Loader />;
  return isNew ? (
    <CreateCampaign linkBack={campaignRoutes.CAMPAIGNS} onSelect={setCampaignType} />
  ) : (
    <Box>
      {campaignToRender === CampaignType.Product && (
        <ProductCampaignEditForm
          campaign={campaign as CampaignForm}
          linkBack={campaignRoutes.CAMPAIGNS}
          onSave={saveOrUpdate}
          onToggle={onToggleCampaign}
        />
      )}
      {campaignToRender === CampaignType.Video && (
        <VideoCampaignEditForm
          campaign={campaign as CampaignForm}
          linkBack={campaignRoutes.CAMPAIGNS}
          onSave={saveOrUpdate}
          onToggle={onToggleCampaign}
        />
      )}
      {campaignToRender === CampaignType.Banner && (
        <BannerCampaignEditForm
          campaign={campaign as CampaignForm}
          linkBack={campaignRoutes.CAMPAIGNS}
          onSave={saveOrUpdate}
          onToggle={onToggleCampaign}
        />
      )}
      {campaignToRender === CampaignType.AppDownload && (
        <AppDownloadCampaignEditForm
          campaign={campaign as CampaignForm}
          linkBack={campaignRoutes.CAMPAIGNS}
          onSave={saveOrUpdate}
          onToggle={onToggleCampaign}
        />
      )}
    </Box>
  );
});
