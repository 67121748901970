import React from 'react';
import { observer } from 'mobx-react-lite';
import { Box, Grid, Card, CardContent, CardHeader, Typography } from '@material-ui/core';
import { ShopsTable } from 'components/ShopsTable';
import { GamesTable } from 'components/GamesTable';
import { gamesRoutes, shopsRoutes } from 'routing';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router';
import { TopNavigation } from 'components/material/TopNavigation';
import { useOrganizationGamesQuery, useOrganizationShopsQuery } from './__generated__/organization-overview-page.hooks';

export const OrganizationOverviewPage: React.FC<{ onBack?: () => void }> = observer(({ onBack }) => {
  const { id } = useParams<{ id: string }>();
  const shopsQuery = useOrganizationShopsQuery({ id });
  const gamesQuery = useOrganizationGamesQuery({ id });
  const shops = shopsQuery.data?.shops.nodes || [];
  const games = gamesQuery.data?.games.nodes || [];
  return (
    <Box>
      <TopNavigation title="Organization Overview" />
      <Box mt={2}>
        <Grid container spacing={4}>
          <Grid item xs={12} lg={6}>
            <Card>
              <CardHeader
                title={
                  <Link className="btn btn-primary" to={`${shopsRoutes.SHOP_NEW}?organizationId=${id}`}>
                    Add new Shop
                  </Link>
                }
              />
              <CardContent>
                {shops.length ? (
                  <ShopsTable
                    shops={shops.map(shop => {
                      return {
                        ...shop,
                        shopifyName: shop.shopifyName || null,
                        description: shop.description || null,
                        admins: shop.admins.map(({ fullName }) => fullName || null),
                      };
                    })}
                  />
                ) : (
                  <Box pt={20} pb={20}>
                    <Typography align="center" variant="h6">
                      No created shops
                    </Typography>
                  </Box>
                )}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Card>
              <CardHeader
                title={
                  <Link className="btn btn-primary" to={`${gamesRoutes.GAME_NEW}?organizationId=${id}`}>
                    Add new Game
                  </Link>
                }
              />
              <CardContent>
                {!!games.length ? (
                  <GamesTable
                    games={games.map(game => {
                      return {
                        ...game,
                        thumbnail: game.thumbnail || null,
                        admins: game.admins.map(({ fullName }) => fullName || null),
                      };
                    })}
                  />
                ) : (
                  <Box pt={20} pb={20}>
                    <Typography align="center" variant="h6">
                      No created games
                    </Typography>
                  </Box>
                )}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
});
