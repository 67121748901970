import React from 'react';
import { COUNTRIES_SELECT_OPTIONS, USA_STATES_SELECT_OPTIONS } from 'types/select-types';
import { FormikProps } from 'formik';
import { Box, Card, CardContent, CardHeader } from '@material-ui/core';
import { ScutiInput } from 'components/material/ScutiInput';
import { ScutiSelect } from 'components/material/ScutiSelect';
import { OrganizationForm } from './OrganizationEditForm.utils';
import { hasId } from 'types/util-types';

export const OrganizationAddressDetails: React.FC<FormikProps<OrganizationForm>> = props => {
  const { values, touched, errors, handleChange, handleBlur } = props;
  const isExist = hasId(values);
  const isUS = values.country === 'US';
  return (
    <Card>
      <CardHeader title="Address Details" />
      <CardContent>
        <Box>
          <ScutiInput
            title="Address 1 *"
            name="address1"
            placeholder="Address of your organization"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.address1}
            error={(isExist || touched.address1) && errors['address1']}
          />
        </Box>
        <Box mt={2}>
          <ScutiInput
            title="Address 2 *"
            name="address2"
            placeholder="Address2 of your organization"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.address2}
            error={(isExist || touched.address2) && errors['address2']}
          />
        </Box>
        <Box mt={2}>
          <ScutiSelect
            title="Country *"
            name="country"
            placeholder="Country"
            onChange={e => {
              props.setFieldValue('country', e.target.value, true);
              props.setFieldValue('state', null, true);
            }}
            onBlur={handleBlur}
            options={COUNTRIES_SELECT_OPTIONS}
            value={values.country}
            error={(isExist || touched.country) && errors['country']}
          />
        </Box>
        <Box mt={2}>
          {isUS ? (
            <ScutiSelect
              title="State *"
              name="state"
              placeholder="State"
              onChange={handleChange}
              onBlur={handleBlur}
              options={USA_STATES_SELECT_OPTIONS}
              value={values.state || ''}
              error={(isExist || touched.state) && errors['state']}
            />
          ) : (
            <ScutiInput
              title="Province *"
              name="state"
              placeholder="Province"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.state || ''}
              error={(isExist || touched.state) && errors['state']}
            />
          )}
        </Box>
        <Box mt={2}>
          <ScutiInput
            title="City *"
            name="city"
            placeholder="City"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.city || ''}
            error={(isExist || touched.city) && errors['city']}
          />
        </Box>
        <Box mt={2}>
          <ScutiInput
            title={`${isUS ? 'Zip' : 'Postal'} code*`}
            name="zipCode"
            placeholder="Zip code"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.zipCode}
            error={(isExist || touched.zipCode) && errors['zipCode']}
          />
        </Box>
      </CardContent>
    </Card>
  );
};
