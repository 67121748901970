import { createTheme } from '@material-ui/core';
import { MuiOutlinedInput } from './MuiOutlinedInput';
import { MuiSelect } from './MuiSelect';
import { MuiMenu } from './MuiMenu';
import { MuiCard } from './MuiCard';
import { MuiCardHeader } from './MuiCardHeader';
import { MuiGrid } from './MuiGrid';
import { MuiFormHelperText } from './MuiFormHelperText';
import { MuiPaper } from './MuiPaper';
import './MuiPaging.css';
import './MuiPaper.css';

export const theme = createTheme({
  palette: {
    error: {
      main: '#f86c6b',
    },
    secondary: {
      main: 'rgba(255, 255, 255, 0.5)',
    },
    text: {
      primary: '#FFFFFF',
      secondary: 'rgba(255, 255, 255, 0.5)',
    },
  },
  typography: {
    fontFamily: ['Avenir Next, Arial, sans-serif'].join(','),
  },
  overrides: {
    MuiOutlinedInput,
    MuiSelect,
    MuiMenu,
    MuiCard,
    MuiCardHeader,
    MuiGrid,
    MuiFormHelperText,
    MuiPaper,
  },
});
