import React from 'react';
import { Form, Button } from 'reactstrap';
import { Formik } from 'formik';
import { organizationFormDefaults, OrganizationForm, validationSchema } from './OrganizationEditForm.utils';
import { validate } from 'utils/form-utils';
import { OrganizationAddressDetails } from './OrganizationAddressDetails';
import { OrganizationBusinessDetails } from './OrganizationBusinessDetails';
import { OrganizationOwnerDetails } from './OrganizationOwnerDetails';
import { Box, Grid } from '@material-ui/core';
import { Loader } from 'components/Loader';
import { TopNavigation } from 'components/material/TopNavigation';

interface Props {
  isLoading?: boolean;
  organization?: OrganizationForm;
  title: string;
  onSave: (input: OrganizationForm) => void;
  onBack?: () => void;
}

export const OrganizationEditForm: React.FC<Props> = ({ isLoading, title, organization, onSave, onBack }) => {
  return (
    <Formik
      initialValues={organization || organizationFormDefaults()}
      validate={validate<OrganizationForm>(() => validationSchema)}
      onSubmit={onSave}
      enableReinitialize
      validateOnMount
    >
      {formik => {
        return (
          <Form className="w-100" onSubmit={formik.handleSubmit}>
            <TopNavigation title={title}>
              <Grid container justifyContent="flex-end" alignItems="center" spacing={1}>
                <Grid item md={2}>
                  <Button
                    className="w-100"
                    color="outline-secondary"
                    onClick={formik.handleReset}
                    disabled={!formik.dirty}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item md={2}>
                  <Button className="w-100" color="primary" type="submit" disabled={formik.isSubmitting}>
                    Save changes
                  </Button>
                </Grid>
              </Grid>
            </TopNavigation>
            {isLoading ? (
              <Loader />
            ) : (
              <Box mt={2}>
                <Grid container spacing={2}>
                  <Grid item sm={12} md={4} lg={4}>
                    <OrganizationOwnerDetails {...formik} />
                  </Grid>
                  <Grid item sm={12} md={4} lg={4}>
                    <OrganizationBusinessDetails {...formik} />
                  </Grid>
                  <Grid item sm={12} md={4} lg={4}>
                    <OrganizationAddressDetails {...formik} />
                  </Grid>
                </Grid>
              </Box>
            )}
          </Form>
        );
      }}
    </Formik>
  );
};
