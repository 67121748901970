export const getFirstTwo = (str: string) =>
  str
    .split(' ')
    .map(s => s.charAt(0))
    .slice(0, 2)
    .join('');

export const addDecimals = (num: number) => num.toFixed(2);

export const toThreeFraction = (n: number) => n.toLocaleString('en-US', { maximumFractionDigits: 3 });
